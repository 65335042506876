import React from "react";
import { useSelector } from "react-redux";

import Divider from "@mui/material/Divider";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import Typography from "@material-ui/core/Typography";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import AutoComplete from "../../../common/AutoCompleteField";
import * as properties from "../../../../lib/properties/properties";
import { getMaxNoOfItemsToShowInBenefitHome } from "../../../../lib/util/commonUtil";

import "../../common/style.scss";

const BenefitItemSection = ({ benefitItems, benefitHomes, formOnChange, addFormFields, removeFormFields }) => {

	//Get benefit items data from state
	const stateBenefitItems = useSelector(state => state.benefitItem.benefitItems);
	//set employer data to Select components format
	var benefitItemsData = stateBenefitItems?.[0] ? stateBenefitItems.map(function (benefitItem) {
		return {
			label: benefitItem.id + " - " + benefitItem.summaryTitle,
			value: benefitItem.id
		};
	}) : [];

	return (
		<div >
			<Divider orientation="horizontal">{properties.BENEFIT_ITEMS_LABEL}</Divider>
			{benefitItems.map((element, index) => (
				<div key={index}>
					<Typography variant="h6" gutterBottom>
						{properties.BENEFIT_ITEM_SUB_LABEL} {index ? index + 1 : 1}
					</Typography>
					{index ? <RemoveCircleOutlineOutlinedIcon className="button-section-remove-form" onClick={() => removeFormFields(index)} /> : ""}
					<AutoComplete
						label={properties.BENEFIT_ITEM_ID_TITLE}
						placeholder={properties.BENEFIT_ITEM_ID_TITLE}
						name="id"
						id="id"
						options={benefitHomes?.employerId ? benefitItemsData : []}
						value={element.id || ""}
						onChange={e => formOnChange(index, e)}
						isDisabled={benefitHomes?.employerId ? false : true}
					/>
					<TextField
						label={properties.BENEFIT_SUMMARY_TITLE_SUB_TITLE}
						placeholder={properties.BENEFIT_SUMMARY_TITLE_SUB_TITLE}
						name="summaryTitle"
						id="summaryTitle"
						value={element.summaryTitle || ""}
						type="text"
						onChange={e => formOnChange(index, e)}
					/>
					<TextArea
						label={properties.BENEFIT_SUMMARY_DESC_SUB_TITLE}
						placeholder={properties.BENEFIT_SUMMARY_DESC_SUB_TITLE}
						name="summaryDescription"
						id="summaryDescription"
						value={element.summaryDescription || ""}
						type="text"
						onChange={e => formOnChange(index, e)}
						characterCount={element.summaryDescription?.length}
					/>
				</div>
			))}

			{benefitItems.length < getMaxNoOfItemsToShowInBenefitHome() ?
				<div className="button-section">
					<AddCircleOutlineOutlinedIcon onClick={() => addFormFields()} />
				</div> : ""}

		</div>
	);
};
export default BenefitItemSection;
