import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";

import SelectField from "../../common/SelectField";
import LabelButton from "../../common/Button/LabelButton";
import DataTable from "../../common/DataTable";
import * as properties from "../../../lib/properties/properties";
import { isEmpty } from "../../../lib/validator";
import { getAonEmployers } from "../../../lib/util/commonUtil";
import { DEFAULT_PAGE_LIMIT_20, DEFAULT_PAGE_NUMBER, NO_PAGE_COUNT, NO_PAGE_LIMIT } from "../../../lib/constants";
import { getResourcePlanAll, resetResourcePlanState } from "../../../state/employers/resourcePlan/getResourcePlanAll";
import { getEmployerAll } from "../../../state/employers/employer/getEmployerAll";

import useResourcePlanAction from "./useResourcePlanAction";

const ResourcePlan = () => {

	//Get resource plan data from state
	const stateResourcePlan = useSelector(state => state.resourcePlan);

	const dispatch = useDispatch();

	//Custom hook for resource plan table search
	const { stateEmployer, resourcePlanSearchedEmployer, isValidData, onClickAddForm, onClickSearch, onChange } = useResourcePlanAction();
	//Resource plan table header items
	const resourcePlanHeaderTitles = ["Id", "Name", "Employee Category", "Description", "Action"];

	const getEmployerData = async () => {
		const payload = { pagelimit: NO_PAGE_LIMIT, pagenumber: NO_PAGE_COUNT };
		dispatch(getEmployerAll(payload));
	};

	const getResourcePlanData = async () => {
		const payload = { employerId: resourcePlanSearchedEmployer.employerId, pagelimit: DEFAULT_PAGE_LIMIT_20, pagenumber: DEFAULT_PAGE_NUMBER };
		dispatch(getResourcePlanAll(payload));
	};

	useEffect(() => {
		dispatch(resetResourcePlanState());
		stateEmployer.isEmployerChanged ? getEmployerData() : "";
		!isEmpty(stateResourcePlan?.search?.employerId) && !isEmpty(document.getElementById("employerId")?.value) ? getResourcePlanData() : null;
	}, [stateEmployer.eligibleEmployers]);

	//Resource plan table body data
	const resourcePlanTableData = stateResourcePlan.resourcePlans && !isEmpty(resourcePlanSearchedEmployer.employerId)
		&& !isEmpty(stateEmployer.eligibleEmployers) && !stateEmployer.loading && !isEmpty(document.getElementById("employerId")?.value)
		? stateResourcePlan.resourcePlans.map((
			{
				id,
				employer,
				name,
				employerEmployeeCategory,
				description
			}) => ({
			value0: id,
			value1: employer,
			value2: name,
			value3: employerEmployeeCategory,
			value4: description
		})) : [];

	return (
		<div className="tabSectionRight ian-position-relative">
			<div className="ian-centeredSection">
				<div className="ian-form-container ian-position-relative">
					<Grid container spacing={0} px={0} >
						<Grid item md={6} xs={12}>
							<SelectField
								label={properties.COMMON_EMPLOYER_SEARCH_LABEL}
								name="employerId"
								id="employerId"
								options={getAonEmployers(stateEmployer.eligibleEmployers)}
								value={resourcePlanSearchedEmployer.employerId}
								onChange={onChange}
							/>
						</Grid>
						<Grid item md={3} xs={12}>
							<div className="searchBtn-wthSelect">
								<LabelButton color="inherit" label="Search" type="submit" disabled={!isValidData() || isEmpty(document.getElementById("employerId")?.value)} showProgress={false} onClick={onClickSearch} />
							</div>
						</Grid>
						<Grid item md={3} xs={12}>
							<div className="add-button">
								<LabelButton color="inherit" label="Add New" type="submit" disabled={stateResourcePlan.loading} showProgress={false} onClick={onClickAddForm} />
							</div>
						</Grid>
					</Grid>
					<DataTable
						Data={resourcePlanTableData}
						headerTitle={resourcePlanHeaderTitles}
						employerId={true}
						updateForm={"/employers?tab=updateResourcePlan"}
						dataLoad={stateResourcePlan.loading}
						noDataLabel={properties.COMMON_NO_DATA_SELECT_AND_SEARCH_LABEL}
					/>
				</div>
			</div>
		</div>
	);
};

export default ResourcePlan;
