import React from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import "./style.scss";

const CheckboxesTags = ({ label, placeholder, options, onChange, value }) => {
	return (
		<div >
			<Box className="ian-form-group">
				<label name={label} className="ian-form-group-label">
					{label}
				</label>
				<Autocomplete
					multiple
					defaultValue={value}
					key={value}
					id="tags-standard"
					options={options}
					getOptionLabel={(option) => option}
					onChange={onChange}
					className="ian-autocomplete"
					renderInput={(params) => (
						<TextField
							{...params}
							variant="outlined"
							className="ian-autocomplete-input"
							placeholder={value.length > 0 ? "" : placeholder}
						/>
					)}
				/>
			</Box>
		</div>
	);
};

export default CheckboxesTags;
