import React from "react";

import TextField from "../../common/TextField";
import PrimaryButton from "../../common/Button/PrimaryButton";
import * as properties from "../../../lib/properties/properties";
import ConfigDataAction from "./useConfigDataAction";

const ConfigData = () => {

	/**
   * Custom hook for data submitting
   */
	const { onChange, errors, onSubmit } = ConfigDataAction();

	/**
   * TODO: Disable submit button when input fields is null
   */
	//let disabled = !validations.isEmpty(!getConfigData)
	//	? false
	//	: true;
	let disabled = true;

	return (
		<div className="ian-centeredSection ian-w-40">
			<div className="ian-form-container">
				{/* <TextField
					label={properties.CONFIG_BASH_URL_LABEL}
					name="bashUrl"
					id="bashUrl"
					type="text"
					value={window.location}
					error={errors.bashUrl && true}
					onChange={onChange}
				/> */}
				<TextField
					label={properties.CONFIG_TERMS_AND_CONDITION_URL_LABEL}
					name="termsAndConditionUrl"
					id="termsAndConditionUrl"
					type="text"
					value={window.location}
					error={errors.termsAndConditionUrl && true}
					onChange={onChange}
				/>
				{Object.keys(errors).length !== 0 ? (
					<div className="ian-error-warning show" sx={{ display: "none" }}>
						<p>
							{" "}
							{errors.termsAndConditionUrl}
						</p>
					</div>
				) : (
					<PrimaryButton
						className="px-5"
						label={properties.COMMON_COMING_SOON_LABEL}
						disabled={disabled}
						showProgress={false}
						onClick={onSubmit}
					/>
				)}
			</div>
		</div>
	);
};

export default ConfigData;
