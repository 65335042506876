import { RESOURCE_TYPE } from "../enums/resourceType";

export const ResourceTypeOptions = [
	{ value: "PHYSICAL_HEALTH", label: RESOURCE_TYPE.PHYSICAL_HEALTH },
	{ value: "MENTAL_HEALTH", label: RESOURCE_TYPE.MENTAL_HEALTH },
	{ value: "DAILY_CARE_MANAGEMENT", label: RESOURCE_TYPE.DAILY_CARE_MANAGEMENT },
	{ value: "WORK_LIFE_BALANCE", label: RESOURCE_TYPE.WORK_LIFE_BALANCE },
	{ value: "FINANCE_LEGAL", label: RESOURCE_TYPE.FINANCE_LEGAL },
	{ value: "BASIC_NEEDS", label: RESOURCE_TYPE.BASIC_NEEDS },
	{ value: "AARP", label: RESOURCE_TYPE.AARP }
];
