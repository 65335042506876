import axios from "axios";
import * as properties from "../../../lib/properties/properties";
import * as constants from "../../../lib/constants";
import { HTTP_CODE } from "../../../lib/enums/httpData";
import { getUserId, getAccessToken } from "../../cognito/cognitoUtil";

/* Function to create and configure an axios instance for network requests */
const networkClient = () => {

	const axiosInstance = axios.create({
		baseURL: process.env.REACT_APP_BASE_URL
	});

	/* Request interceptor to add headers and modify config before making a request */
	axiosInstance.interceptors.request.use(
		async (config) => {
			/* Retrieve access token and add to headers for authorization */
			const accessToken = await getAccessToken();
			if (accessToken) {
				config.headers[constants.HEADER_KEY_AUTHORIZATION] = accessToken;
			}

			/* Add other required headers */
			config.headers[constants.HEADER_KEY_API_KEY] = process.env.REACT_APP_API_KEY;
			config.headers[constants.HEADER_KEY_ACCEPT] = constants.HEADER_VAL_ACCEPT;
			config.headers[constants.HEADER_KEY_PLATFORM] = constants.HEADER_VAL_PLATFORM;
			config.headers[constants.HEADER_APP_VERSION] = constants.HEADER_APP_VERSION_VAL;

			/* Replace placeholder in URL with user ID from authenticated user attributes */
			config.url = config.url.replace("{userid}", await getUserId());

			return config;
		},
		error => Promise.reject(error)
	);

	/* Response interceptor to handle error responses */
	axiosInstance.interceptors.response.use(
		response => response,
		error => {
			/* Create error object with status code, error message, and external error message */
			const icError = {
				statusCode: error.response?.status || HTTP_CODE.INTERNAL_SERVER_ERROR,
				errorMessage: error.response?.headers["ic-message"] || properties.ERROR_POPUP_MESSAGE,
				externalErrorMessage: error.response?.headers["ic-external-message"]
			};
			return Promise.reject(icError);
		}
	);

	return axiosInstance;
};

export default networkClient;
