import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import * as validations from "../../../lib/validator";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_LIMIT } from "../../../lib/constants";
import { getExpertArticleAll, cacheExpertArticleSearchedData, resetExpertArticleState } from "../../../state/expertContents/expertArticle/getExpertArticleAll";

const useExpertArticleAction = () => {

	const stateEmployer = useSelector(state => state.employer);
	const stateSearchedExpertArticle = useSelector(state => state.expertArticle?.search?.employerId);

	const [expertArticleSearchedEmployer, setExpertArticleSearchedEmployer] = useState({ employerId: stateSearchedExpertArticle });

	const history = useHistory();
	const dispatch = useDispatch();

	const getExpertArticleData = async () => {
		const payload = { employerId: expertArticleSearchedEmployer.employerId, pagelimit: DEFAULT_PAGE_LIMIT, pagenumber: DEFAULT_PAGE_NUMBER };
		dispatch(getExpertArticleAll(payload));
		//set the previous Search results
		dispatch(cacheExpertArticleSearchedData({ employerId: expertArticleSearchedEmployer.employerId, pagenumber: DEFAULT_PAGE_NUMBER }));
	};

	//This function checks required field validations.
	const isValidData = () => {
		return !validations.isEmpty(expertArticleSearchedEmployer.employerId);
	};

	//This function call search data
	const onClickSearch = () => {
		getExpertArticleData();
	};

	//This function calls when user entering data to input fields and store data in the state after validation
	const onChange = (event) => {
		event.persist();
		const name = event.target.name;
		const val = event.target.value;
		dispatch(resetExpertArticleState());
		dispatch(cacheExpertArticleSearchedData({ employerId: "", pagenumber: DEFAULT_PAGE_NUMBER }));
		setExpertArticleSearchedEmployer({ ...expertArticleSearchedEmployer, [name]: val });
	};

	const onClickAddForm = () => {
		history.push("/contents?tab=addExpertArticle");
	};

	return { stateEmployer, expertArticleSearchedEmployer, isValidData, onClickSearch, onChange, onClickAddForm };
};

export default useExpertArticleAction;
