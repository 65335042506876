import * as TYPES from "./types";
import { NO_PAGE_COUNT } from "../../../lib/constants";

const initialState = {
	employer: {},
	paginatedEmployers: [],
	error: null
};

const employerReducer = (state = initialState, action) => {
	switch (action.type) {
	case TYPES.ADD_EMPLOYER:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.ADD_EMPLOYER_SUCCESS:
		return {
			...state,
			loading: false,
			success: true,
			isEmployerChanged: true,
			employer: action.payload,
			error: null
		};
	case TYPES.ADD_EMPLOYER_ERROR:
		return {
			...state,
			loading: false,
			success: false,
			employer: "",
			error: action.payload.errorMessage
		};
	case TYPES.GET_EMPLOYER:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.GET_EMPLOYER_SUCCESS:
		return {
			...state,
			loading: false,
			employer: action.payload,
			error: null
		};
	case TYPES.GET_EMPLOYER_ERROR:
		return {
			...state,
			loading: false,
			employer: "",
			error: action.payload.errorMessage
		};
	case TYPES.GET_EMPLOYER_ALL:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.GET_EMPLOYER_ALL_SUCCESS:
		if (action.payload.pageCount === NO_PAGE_COUNT) {
			//Employer filter
			var resourceEnabledEmployers = action.payload.employers?.filter(function (employer) {
				return employer.resourceEnabled;
			});
			//Employer sort alphabetically
			resourceEnabledEmployers?.sort((a, b) => a.name.localeCompare(b.name));
			//set employer data to Select components format
			var employerData = resourceEnabledEmployers?.map(function (employer) {
				return { value: employer.id, label: employer.name, platform: employer.platform };
			});
		}
		return {
			...state,
			loading: false,
			isEmployerChanged: action.payload.isEmployerChanged || false,
			eligibleEmployers: employerData,
			error: null
		};
	case TYPES.GET_EMPLOYER_ALL_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload
		};
	case TYPES.GET_PAGINATED_EMPLOYERS:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.GET_PAGINATED_EMPLOYERS_SUCCESS:
		return {
			...state,
			loading: false,
			paginatedEmployers: action.payload.employers,
			pageData: { pageCount: action.payload.pageCount, pageLimit: action.payload.pageLimit, pageNumber: action.payload.pageNumber },
			error: null
		};
	case TYPES.GET_PAGINATED_EMPLOYERS_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload
		};
	case TYPES.UPDATE_EMPLOYER:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.UPDATE_EMPLOYER_SUCCESS:
		return {
			...state,
			loading: false,
			isEmployerChanged: true,
			pillar: action.payload,
			success: true,
			error: null
		};
	case TYPES.UPDATE_EMPLOYER_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload.errorMessage
		};
	case TYPES.CACHE_EMPLOYER_SEARCHED_DATA:
		return {
			...state,
			search: action.payload
		};
	case TYPES.CACHE_EMPLOYER_SEARCH_CLICKED:
		return {
			...state,
			employerSearchClicked: action.payload
		};
	case TYPES.SET_IS_EMPLOYER_CHANGED:
		return {
			...state,
			isEmployerChanged: action.payload
		};
	case TYPES.CLEAR_EMPLOYER:
		return {
			...state,
			loading: false,
			success: false,
			error: null
		};
	default:
		return state;
	}
};
export default employerReducer;
