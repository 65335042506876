import React from "react";
import { useSelector } from "react-redux";
import { Divider, Typography } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

import TextField from "../../../common/TextField";
import AutoComplete from "../../../common/AutoCompleteField";
import * as properties from "../../../../lib/properties/properties";
import { getMaxNoOfItemsToShow, getSupportedUrlTooltip } from "../../../../lib/util/commonUtil";

const ExpertVideoSection = ({
	expertVideos,
	pillarData,
	addExpertVideoFormFields,
	removeExpertVideoFormFields,
	expertVideoFormOnChange
}) => {

	//Get expert video state
	const stateExpertVideos = useSelector(state => state.expertVideo.expertVideos);
	//set video data to Select components format
	const formExpertVideos = stateExpertVideos[0] ? stateExpertVideos.map((expertVideo) =>
		({
			label: expertVideo.id + " - " + expertVideo.title,
			value: expertVideo.id
		})
	) : [];

	return (
		<>
			<Divider orientation="horizontal">{properties.CONTENT_EXPE_VIDEO_LABEL}</Divider>
			{expertVideos.map((element, index) => (
				<div key={index}>
					<Typography variant="h6" gutterBottom>
						{properties.CONTENT_EXPE_VIDEO_SUB_TITLE} {index ? index + 1 : 1}
					</Typography>
					{index ? <RemoveCircleOutlineOutlinedIcon className="button-section-remove-form"
						onClick={() => removeExpertVideoFormFields(index)} /> : ""}
					<AutoComplete
						label={properties.CONTENT_EXPE_VIDEO_ID_LABEL}
						placeholder={properties.PILLAR_SELECT_OPTION_EXPERT_VIDEO_ID_LABEL}
						name="expertVideoId"
						id="expertVideoId"
						value={element.expertVideoId || null}
						options={pillarData?.employerId ? formExpertVideos : []}
						onChange={e => expertVideoFormOnChange(index, e)}
						isDisabled={pillarData?.employerId ? false : true}
					/>
					<TextField
						label={properties.COMMON_TITLE_LABEL}
						placeholder={properties.COMMON_TITLE_PLACEHOLDER}
						name="expertVideoTitle"
						id="expertVideoTitle"
						type="text"
						value={element.expertVideoTitle || ""}
						onChange={e => expertVideoFormOnChange(index, e)}
					/>
					<TextField
						label={properties.CONTENT_EXPE_VIDEO_LENGTH_LABEL}
						placeholder={properties.COMMON_VIDEO_LENGTH_PLACEHOLDER}
						name="videoLength"
						id="videoLength"
						type="text"
						value={element.videoLength || ""}
						onChange={e => expertVideoFormOnChange(index, e)}
					/>
					<TextField
						label={properties.COMMON_PREVIEW_IMAGE_LABEL}
						placeholder={properties.COMMON_PREVIEW_IMAGE_PLACEHOLDER}
						name="videoPreviewImage"
						id="videoPreviewImage"
						type="text"
						value={element.videoPreviewImage || ""}
						onChange={e => expertVideoFormOnChange(index, e)}
						toolTipText={getSupportedUrlTooltip()}
					/>
				</div>
			))}
			{expertVideos.length < getMaxNoOfItemsToShow() ?
				<div className="button-section">
					<AddCircleOutlineOutlinedIcon onClick={() => addExpertVideoFormFields()} />
				</div> : ""}
		</>
	);

};

export default ExpertVideoSection;
