import React from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import * as properties from "../../../lib/properties/properties";

export default function LeftNavigator({ selectedIndex }) {
	let history = useHistory();

	return (
		<Box sx={{ width: "100%" }} justifyContent="center">
			<h5 className="ian-title-h5">{properties.PILLAR_TITLE}</h5>
			<List component="nav" className="accntPrefMenuList" sx={{ paddingTop: "0" }} >
				<ListItemButton selected={selectedIndex === 0 || selectedIndex === 1 || selectedIndex === 2}  onClick={() => history.push("/pillars?tab=pillarHome")} >
					<ListItemText primary="Pillar Home" />
				</ListItemButton>
			</List>
		</Box>
	);
}
