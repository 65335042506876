import * as React from "react";

import { Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";


import parse from "html-react-parser";

const ContentPreview = ({ contentPreviewClose, htmlCont = null }) => {
	const contentPreviewCommClose = () => {
		contentPreviewClose();
	};
	const html = htmlCont;

	const options = {
		replace: (domNode) => {
			if (domNode.attribs && domNode.attribs.class === "remove") {
				return;
			}
		}
	};

	return (
		<div>
			<AppBar sx={{ position: "relative" }} className="contentPrvwHeader">
				<Toolbar>
					<IconButton
						className="ian-preview-close-btn ian-modal-close"
						edge="start"
						color="inherit"
						onClick={contentPreviewCommClose}
						aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Grid>
				{parse(html, options)}
			</Grid>
		</div>
	);
};

export default ContentPreview;
