import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@mui/material";

import DataTable from "../../common/DataTable";
import LabelButton from "../../common/Button/LabelButton";
import SelectField from "../../common/SelectField";
import * as properties from "../../../lib/properties/properties";
import { isEmpty } from "../../../lib/validator";
import { DEFAULT_PAGE_LIMIT } from "../../../lib/constants";
import { RESOURCE_TYPE } from "../../../lib/enums/resourceType";
import { getExpertVideoAll, cacheExpertVideoSearchedData, resetExpertVideoState } from "../../../state/expertContents/expertVideo/getExpertVideoAll";

import useExpertVideoAction from "./useExpertVideoAction";

const ExpertVideo = () => {

	const stateExpertVideo = useSelector(state => state.expertVideo);

	const dispatch = useDispatch();
	//Expert Video Table Head Items
	const expertVideoHeaderTitles = ["Id", "Resource Plan", "Resource Type", "Title", "Action"];

	//Custom hook for this form action
	const { stateEmployers, expertVideoSearchedEmployer, isValidData, onClickSearch, onChange, onClickAddForm } = useExpertVideoAction();

	const callback = useCallback((page) => {
		if (!isEmpty(page)) {
			getExpertVideoData(page, expertVideoSearchedEmployer.employerId);
		}
	}, [expertVideoSearchedEmployer]);

	const getExpertVideoData = async (page, employerId) => {
		const payload = { employerId, pagelimit: DEFAULT_PAGE_LIMIT, pagenumber: page };
		dispatch(getExpertVideoAll(payload));
		//set the previous Search results
		dispatch(cacheExpertVideoSearchedData({ employerId: employerId, pagenumber: page }));
	};

	useEffect(() => {
		dispatch(resetExpertVideoState());
		!isEmpty(stateExpertVideo?.search?.employerId) && !isEmpty(document.getElementById("employerId")?.value) ?
			getExpertVideoData(stateExpertVideo?.search?.pagenumber, stateExpertVideo?.search?.employerId) : null;
	}, []);

	//Expert Video Item Table Body Items
	const expertVideoTableData = !isEmpty(stateExpertVideo.expertVideos) && !isEmpty(expertVideoSearchedEmployer.employerId) && !isEmpty(document.getElementById("employerId")?.value) ?
		stateExpertVideo.expertVideos.map((
			{
				id,
				employer,
				resourcePlan,
				resourceType,
				title
			}) => ({
			value0: id,
			value1: employer,
			value2: resourcePlan?.employerResourcePlanName,
			value3: RESOURCE_TYPE[resourceType],
			value4: title
		})) : [];

	return (
		<div className="tabSectionRight ian-position-relative">
			<div className="ian-centeredSection">
				<div className="ian-form-container ian-position-relative">
					<Grid container spacing={0} px={0} >
						<Grid item md={6} xs={12}>
							<SelectField
								label={properties.COMMON_EMPLOYER_SEARCH_LABEL}
								name="employerId"
								id="employerId"
								value={expertVideoSearchedEmployer.employerId}
								options={stateEmployers}
								onChange={onChange}
								selectOption={properties.COMMON_SELECT_OPTION_EMPLOYER_LABEL}
							/>
						</Grid>
						<Grid item md={3} xs={12}>
							<div className="searchBtn-wthSelect">
								<LabelButton color="inherit" label="Search" type="submit" disabled={!isValidData() || isEmpty(document.getElementById("employerId")?.value)} showProgress={false} onClick={onClickSearch} />
							</div>
						</Grid>
						<Grid item md={3} xs={12}>
							<div className="add-button">
								<LabelButton color="inherit" label="Add New" type="submit" disabled={stateExpertVideo.loading} showProgress={false} onClick={onClickAddForm} />
							</div>
						</Grid>
					</Grid>
					<DataTable
						Data={expertVideoTableData}
						headerTitle={expertVideoHeaderTitles}
						updateForm={"/contents?tab=updateExpertVideo"}
						dataLoad={stateExpertVideo.loading}
						parentCallback={callback}
						pageData={stateExpertVideo.pageData}
						paginationActive={true}
						noDataLabel={properties.COMMON_NO_DATA_SELECT_AND_SEARCH_LABEL} />
				</div>
			</div>
		</div>
	);
};

export default ExpertVideo;
