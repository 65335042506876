import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import * as validations from "../../../lib/validator";
import { DEFAULT_PAGE_LIMIT_50, DEFAULT_PAGE_NUMBER } from "../../../lib/constants";
import { getPillarAll, cachePillarSearchedData, resetPillarState } from "../../../state/pillars/pillar/getPillarAll";
import { resetExpertArticleState } from "../../../state/expertContents/expertArticle/getExpertArticleAll";
import { resetExpertVideoState } from "../../../state/expertContents/expertVideo/getExpertVideoAll";

const usePillarsAction = () => {

	const stateEmployer = useSelector(state => state.employer);
	const stateSearchedPillar = useSelector(state => state.pillar?.search?.employerId);
	const [pillarSearchedEmployer, setPillarSearchedEmployer] = useState({ employerId: stateSearchedPillar });

	const dispatch = useDispatch();
	const history = useHistory();

	const getPillarData = async () => {
		const payload = { employerId: pillarSearchedEmployer.employerId, pagelimit: DEFAULT_PAGE_LIMIT_50, pagenumber: DEFAULT_PAGE_NUMBER };
		dispatch(getPillarAll(payload));
		//set the previous Search results
		dispatch(cachePillarSearchedData({ employerId: pillarSearchedEmployer.employerId }));
	};

	//This function checks required field validations.
	const isValidData = () => {
		return !validations.isEmpty(pillarSearchedEmployer.employerId);
	};

	//Get clear pillar state
	useEffect(() => { dispatch(resetPillarState()); dispatch(resetExpertArticleState()); dispatch(resetExpertVideoState()); }, []);

	//This function call search data
	const onClickSearch = () => {
		getPillarData();
	};

	//This function call create new benefit item form
	const onClickAddForm = () => {
		history.push("/pillars?tab=addPillar");
	};

	//This function calls when user entering data to input fields and store data in the state after validation
	const onChange = (event) => {
		event.persist();
		let name = event.target.name;
		let val = event.target.value;
		dispatch(resetPillarState());
		dispatch(cachePillarSearchedData({ employerId: "" }));
		setPillarSearchedEmployer({ ...pillarSearchedEmployer, [name]: val });
	};

	//Return object and function
	return { stateEmployer, pillarSearchedEmployer, isValidData, onClickAddForm, onClickSearch, onChange };
};

export default usePillarsAction;
