import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, LinearProgress } from "@mui/material";

import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import SelectField from "../../../common/SelectField";
import PrimaryButton from "../../../common/Button/PrimaryButton";
import BackButton from "../../../common/Button/BackButton";
import ErrorPopup from "../../../common/ErrorPopup";
import SuccessPopup from "../../../common/SuccessPopup";
import * as properties from "../../../../lib/properties/properties";
import { getAonEmployers, getRowIdAndEmployerId, getSupportedResourcePlanNameTooltip } from "../../../../lib/util/commonUtil";
import { EmployerEmployeeCategory } from "../../../../lib/constants/EmployerEmployeeCategory";

import { getResourcePlan } from "../../../../state/employers/resourcePlan/getResourcePlan";
import { resetResourcePlanState } from "../../../../state/employers/resourcePlan/getResourcePlanAll";

import useUpdateResourcePlanAction from "./useUpdateResourcePlanAction";

const UpdateResourcePlan = () => {

	//Get resourcePlan data from state
	const stateResourcePlan = useSelector(state => state.resourcePlan);

	let history = useHistory();
	const dispatch = useDispatch();
	const urlResourcePlanEmployerPathVariables = getRowIdAndEmployerId();

	useEffect(() => { getResourcePlanData(); }, []);

	//Get Resource Plan Data
	const getResourcePlanData = async () => {
		const payload = {
			resourcePlanId: urlResourcePlanEmployerPathVariables[0], employerid: urlResourcePlanEmployerPathVariables[1]
		};
		dispatch(getResourcePlan(payload));
	};

	//Customer hook for data submitting
	const { stateEmployers, resourcePlanFormData, validateResourcePlanFormData, onChange, onSubmit } = useUpdateResourcePlanAction();

	//This function executes when error popup ok button clicked.
	const onErrorPopupButtonClicked = () => {
		dispatch(resetResourcePlanState());
	};

	//This function executes when go back action gets triggered
	const onClickBackButton = () => {
		history.push("/employers?tab=resourcePlan");
	};

	//This function executes when success popup ok button clicked.
	const onSuccessPopupButtonClicked = () => {
		dispatch(resetResourcePlanState());
		history.push("/employers?tab=resourcePlan");
	};

	return (
		<div>
			<BackButton className="px-5" onClick={onClickBackButton} />
			<div className="tabSectionRight ian-position-relative">
				<div className="ian-centeredSection ian-w-40">
					<h5 className="ian-modal-title">
						{properties.RESOURCE_PLAN_UPDATE_TITLE}
					</h5>
					{stateResourcePlan.loading && <Box sx={{ width: "100%" }}> <LinearProgress className="ian-linear-progress" /> </Box>}
					<div className="ian-form-container">
						<SelectField
							label={properties.COMMON_EMPLOYER_LABEL}
							name="employerId"
							id="employerId"
							options={getAonEmployers(stateEmployers)}
							selectOption={properties.COMMON_SELECT_OPTION_EMPLOYER_LABEL}
							onChange={onChange}
							isDisabled={true}
							value={resourcePlanFormData.employerId}
						/>
						<SelectField
							label={properties.RESOURCE_PLAN_EMP_CATEGORY_LABEL}
							name="employerEmployeeCategory"
							id="employerEmployeeCategory"
							options={EmployerEmployeeCategory}
							value={resourcePlanFormData.employerEmployeeCategory}
							onChange={onChange}
						/>
						<TextField
							label={properties.RESOURCE_PLAN_NAME_LABEL}
							placeholder={properties.RESOURCE_PLAN_NAME_PLACEHOLDER}
							name="name"
							id="name"
							type="text"
							onChange={onChange}
							value={resourcePlanFormData.name || ""}
							toolTipText={getSupportedResourcePlanNameTooltip()}
						/>
						<TextArea
							label={properties.COMMON_DESCRIPTION_PLACEHOLDER}
							placeholder={properties.COMMON_DESCRIPTION_PLACEHOLDER}
							name="description"
							id="description"
							type="text"
							onChange={onChange}
							value={resourcePlanFormData.description}
						/>
						{validateResourcePlanFormData().validationsEnabled ? (
							<div className="ian-error-warning show px-5 mt-5" sx={{ display: "none" }}>
								<p>
									{validateResourcePlanFormData().fieldErrorObject.errorMessage || stateResourcePlan.loading}
								</p>
							</div>
						) : (
							<PrimaryButton
								className="px-5"
								label={properties.COMMON_UPDATE_LABEL}
								disabled={validateResourcePlanFormData().fieldErrorObject.errorMessage || stateResourcePlan.loading}
								showProgress={false}
								onClick={onSubmit}
							/>
						)}
					</div>
					{stateResourcePlan.success ? <SuccessPopup
						message={stateResourcePlan.resourcePlan ? properties.SUCCESS_POPUP_UPDATE_MESSAGE : null}
						okButtonAction={onSuccessPopupButtonClicked}
					/> : null}
					{stateResourcePlan.error ? <ErrorPopup
						error={stateResourcePlan.error}
						okButtonAction={onErrorPopupButtonClicked}
					/> : null}
				</div>
			</div>
		</div>
	);
};

export default UpdateResourcePlan;
