import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import * as properties from "../../../../lib/properties/properties";
import * as validations from "../../../../lib/validator";

import { addEmployer } from "../../../../state/employers/employer/addEmployer";
import { resetEmployerState } from "../../../../state/employers/employer/getEmployerAll";
import { getValidationResultObject } from "../../../../lib/validator/util";
import { AON_EMPLOYER_PLATFORM } from "../../../../lib/constants/employerConst";

const useAddEmployerAction = () => {

	const [employerFormData, setEmployerFormData] = useState({ resourceEnabled: true });
	const [employerNameValidationsEnabled, setEmployerNameValidationsEnabled] = useState(false);
	const [employerFormResourceEnabled, setEmployerFormResourceEnabled] = useState(true);
	const [employerFormAuthOptions, setEmployerFormAuthOptions] = useState([]);

	const dispatch = useDispatch();
	const fieldErrorObject = { fieldName: "", errorMessage: "" };

	//Get clear benefit home state
	useEffect(() => { dispatch(resetEmployerState()); }, []);

	//Get create employer
	const createEmployer = async (employers) => {
		const payload = { data: employers };
		dispatch(addEmployer(payload));
	};

	//This function checks required field validations.
	const validateEmployerFormData = () => {
		if (validations.isEmpty(employerFormData.name))
			return getValidationResultObject("name", properties.EMPLOYER_NAME_INVALID, employerNameValidationsEnabled);

		if (validations.isTitle(employerFormData.name))
			return getValidationResultObject("name", properties.EMPLOYER_NAME_MAX_CHARACTERS_REACHED_INVALID, true);

		if (!validations.isEmpty(employerFormData.description) && validations.isDescription(employerFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_MAX_CHARACTERS_REACHED_INVALID, true);

		if (!validations.isEmpty(employerFormData.link) && validations.isUrl(employerFormData.link))
			return getValidationResultObject("link", properties.EMPLOYER_LINK_INVALID, true);

		if (!validations.isEmpty(employerFormData.welcomeNote) && validations.isDescription(employerFormData.welcomeNote))
			return getValidationResultObject("welcomeNote", properties.EMPLOYER_WELCOME_NOTE_MAX_CHARACTERS_REACHED_INVALID, true);

		if (employerFormData.platform === AON_EMPLOYER_PLATFORM && validations.isEmpty(employerFormAuthOptions))
			return getValidationResultObject("platform", properties.EMPLOYER_AUTH_OPTIONS_REQUIERED, true);

		return { fieldErrorObject, validationsEnabled: false };
	};

	const changeResourceEnabled = () => {
		setEmployerFormResourceEnabled(!employerFormResourceEnabled);
		setEmployerFormData({ ...employerFormData, "resourceEnabled": !employerFormResourceEnabled });
		setEmployerNameValidationsEnabled(true);
	};

	const onChangeAuthOptions = (event, value) => {
		event.persist();
		if (validations.isEmpty(employerFormData.name)) {
			setEmployerNameValidationsEnabled(true);
		}
		setEmployerFormAuthOptions(value);
	};

	//This function calls when user entering data to input fields and store data in the state after validation
	const onChange = (event) => {
		event.persist();
		let name = event.target.name;
		let val = event.target.value;
		//set validationsEnabled to true when name field clear 
		if (validations.isEmpty(employerFormData.name)) {
			setEmployerNameValidationsEnabled(true);
		}
		setEmployerFormData({ ...employerFormData, [name]: val });
	};

	//This function calls when user submit data
	const onSubmit = (event) => {
		if (event) event.preventDefault();
		if (validations.isEmpty(fieldErrorObject.errorMessage) && Object.keys(employerFormData).length !== 0) {
			employerFormData.authOptions = employerFormAuthOptions;
			createEmployer(employerFormData);
		} else {
			return;
		}
	};

	//Return object and function
	return { employerFormAuthOptions, employerFormResourceEnabled, validateEmployerFormData, changeResourceEnabled, onChangeAuthOptions, onChange, onSubmit };
};

export default useAddEmployerAction;
