import React from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

export default function LeftNavigator({ selectedIndex }) {
	let history = useHistory();


	return (
		<Box sx={{ width: "100%" }} justifyContent="center">
			<h5 className="ian-title-h5">Contents</h5>
			<List component="nav" className="accntPrefMenuList" sx={{ paddingTop: "0" }} >
				<ListItemButton selected={selectedIndex === 0 || selectedIndex === 2 || selectedIndex === 4} onClick={() => history.push("/contents?tab=expertArticle")} >
					<ListItemText primary="Expert Article" />
				</ListItemButton>
				<ListItemButton selected={selectedIndex === 1 || selectedIndex === 3 || selectedIndex === 5} onClick={() => history.push("/contents?tab=expertVideo")} >
					<ListItemText primary="Expert Video" />
				</ListItemButton>
			</List>
		</Box>
	);
}
