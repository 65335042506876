import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../..//lib/enums/httpData";
import * as TYPES from "./types";
import { addConfigDataApi } from "../../../services/api/configDataService";

//Action(s)
export const addConfigData = (payload, callback) => ({
	type: TYPES.ADD_CONFIG_DATA,
	payload: { payload },
	callback: callback
});
const addConfigDataSuccess = (payload, callback) => ({
	type: TYPES.ADD_CONFIG_DATA_SUCCESS,
	payload: { payload },
	callback: callback
});
const addConfigDataError = (error) => ({
	type: TYPES.ADD_CONFIG_DATA_ERROR,
	payload: { error }
});

//Saga(s)
export function* addConfigDataSaga({ payload, callback }) {
	try {
		const response = yield call(addConfigDataApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(addConfigDataSuccess(response.payload));
			callback(false);
		}
	} catch (error) {

		yield put(addConfigDataError(error.response.payload));
		callback(false);
	}
}
