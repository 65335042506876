import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { getBenefitItemAllApi } from "../../../services/api/benefitItemService";

//Action(s)
export const getBenefitItemAll = (payload) => ({
	type: TYPES.GET_BENEFIT_ITEM_ALL,
	payload: payload
});
const getBenefitItemAllSuccess = (payload) => ({
	type: TYPES.GET_BENEFIT_ITEM_ALL_SUCCESS,
	payload: payload
});
const getBenefitItemAllError = (error) => ({
	type: TYPES.GET_BENEFIT_ITEM_ALL_ERROR,
	payload: { error }
});
export const cacheBenefitItemSearchedData = (payload) => ({
	type: TYPES.CACHE_BENEFIT_ITEM_SEARCHED_DATA,
	payload: payload
});
export const resetBenefitItemState = () => ({
	payload: null,
	type: TYPES.CLEAR_BENEFIT_ITEM
});

//Saga(s)
export function* getBenefitItemAllSaga({ payload }) {
	try {
		const response = yield call(getBenefitItemAllApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(getBenefitItemAllSuccess(response.data));
		}
	} catch (error) {
		yield put(getBenefitItemAllError(error));
	}
}
