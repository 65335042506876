import { takeLatest } from "redux-saga/effects";

import { SIGNOUT } from "./user/types";
import { signoutSaga } from "./user/signout";

import * as pillarType from "./pillars/pillar/types";
import * as benefitHomeType from "./benefits/benefitHome/types";
import * as benefitItemType from "./benefits/benefitItem/types";
import * as videoType from "./expertContents/expertVideo/types";
import * as articleType from "./expertContents/expertArticle/types";
import * as employerType from "./employers/employer/types";
import * as resourcePlans from "./employers/resourcePlan/types";

import * as configType from "./general/configData/types";
import * as notificationType from "./general/notification/types";

import { addPillarSaga } from "./pillars/pillar/addPillar";
import { getPillarSaga } from "./pillars/pillar/getPillar";
import { getPillarAllSaga } from "./pillars/pillar/getPillarAll";
import { updatePillarSaga } from "./pillars/pillar/updatePillar";

import { addBenefitHomeSaga } from "./benefits/benefitHome/addBenefitHome";
import { getBenefitHomeSaga } from "./benefits/benefitHome/getBenefitHome";
import { getBenefitHomeAllSaga } from "./benefits/benefitHome/getBenefitHomeAll";
import { updateBenefitHomeSaga } from "./benefits/benefitHome/updateBenefitHome";

import { addBenefitItemSaga } from "./benefits/benefitItem/addBenefitItem";
import { getBenefitItemSaga } from "./benefits/benefitItem/getBenefitItem";
import { getBenefitItemAllSaga } from "./benefits/benefitItem/getBenefitItemAll";
import { updateBenefitItemSaga } from "./benefits/benefitItem/updateBenefitItem";

import { addExpertVideoSaga } from "./expertContents/expertVideo/addExpertVideo";
import { getExpertVideoSaga } from "./expertContents/expertVideo/getExpertVideo";
import { getExpertVideoAllSaga } from "./expertContents/expertVideo/getExpertVideoAll";
import { updateExpertVideoSaga } from "./expertContents/expertVideo/updateExpertVideo";

import { addExpertArticleSaga } from "./expertContents/expertArticle/addExpertArticle";
import { getExpertArticleSaga } from "./expertContents/expertArticle/getExpertArticle";
import { getExpertArticleAllSaga } from "./expertContents/expertArticle/getExpertArticleAll";
import { updateExpertArticleSaga } from "./expertContents/expertArticle/updateExpertArticle";

import { addEmployerSaga } from "./employers/employer/addEmployer";
import { getEmployerSaga } from "./employers/employer/getEmployer";
import { getPaginatedEmployersSaga } from "./employers/employer/getPaginatedEmployers";
import { getEmployerAllSaga } from "./employers/employer/getEmployerAll";
import { updateEmployerSaga } from "./employers/employer/updateEmployer";

import { addResourcePlanSaga } from "./employers/resourcePlan/addResourcePlan";
import { getResourcePlanSaga } from "./employers/resourcePlan/getResourcePlan";
import { getResourcePlanAllSaga } from "./employers/resourcePlan/getResourcePlanAll";
import { updateResourcePlanSaga } from "./employers/resourcePlan/updateResourcePlan";

import { addConfigDataSaga } from "./general/configData/addConfigData";
import { addNotificationSaga } from "./general/notification/addNotification";

export function* watcherSaga() {
	yield takeLatest(pillarType.ADD_PILLAR, addPillarSaga);
	yield takeLatest(pillarType.GET_PILLAR, getPillarSaga);
	yield takeLatest(pillarType.GET_PILLAR_ALL, getPillarAllSaga);
	yield takeLatest(pillarType.UPDATE_PILLAR, updatePillarSaga);

	yield takeLatest(benefitHomeType.ADD_BENEFIT_HOME, addBenefitHomeSaga);
	yield takeLatest(benefitHomeType.GET_BENEFIT_HOME, getBenefitHomeSaga);
	yield takeLatest(benefitHomeType.GET_BENEFIT_HOME_ALL, getBenefitHomeAllSaga);
	yield takeLatest(benefitHomeType.UPDATE_BENEFIT_HOME, updateBenefitHomeSaga);

	yield takeLatest(benefitItemType.ADD_BENEFIT_ITEM, addBenefitItemSaga);
	yield takeLatest(benefitItemType.GET_BENEFIT_ITEM, getBenefitItemSaga);
	yield takeLatest(benefitItemType.GET_BENEFIT_ITEM_ALL, getBenefitItemAllSaga);
	yield takeLatest(benefitItemType.UPDATE_BENEFIT_ITEM, updateBenefitItemSaga);

	yield takeLatest(videoType.ADD_EXPERT_VIDEO, addExpertVideoSaga);
	yield takeLatest(videoType.GET_EXPERT_VIDEO, getExpertVideoSaga);
	yield takeLatest(videoType.GET_EXPERT_VIDEO_ALL, getExpertVideoAllSaga);
	yield takeLatest(videoType.UPDATE_EXPERT_VIDEO, updateExpertVideoSaga);

	yield takeLatest(articleType.ADD_EXPERT_ARTICLE, addExpertArticleSaga);
	yield takeLatest(articleType.GET_EXPERT_ARTICLE, getExpertArticleSaga);
	yield takeLatest(articleType.GET_EXPERT_ARTICLE_ALL, getExpertArticleAllSaga);
	yield takeLatest(articleType.UPDATE_EXPERT_ARTICLE, updateExpertArticleSaga);

	yield takeLatest(employerType.ADD_EMPLOYER, addEmployerSaga);
	yield takeLatest(employerType.GET_EMPLOYER, getEmployerSaga);
	yield takeLatest(employerType.GET_PAGINATED_EMPLOYERS, getPaginatedEmployersSaga);
	yield takeLatest(employerType.GET_EMPLOYER_ALL, getEmployerAllSaga);
	yield takeLatest(employerType.UPDATE_EMPLOYER, updateEmployerSaga);

	yield takeLatest(resourcePlans.ADD_RESOURCE_PLAN, addResourcePlanSaga);
	yield takeLatest(resourcePlans.GET_RESOURCE_PLAN, getResourcePlanSaga);
	yield takeLatest(resourcePlans.GET_RESOURCE_PLAN_ALL, getResourcePlanAllSaga);
	yield takeLatest(resourcePlans.UPDATE_RESOURCE_PLAN, updateResourcePlanSaga);
	
	yield takeLatest(configType.ADD_CONFIG_DATA, addConfigDataSaga);
	yield takeLatest(notificationType.ADD_NOTIFICATION, addNotificationSaga);

	yield takeLatest(SIGNOUT, signoutSaga);
}
