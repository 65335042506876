import React from "react";
import { Box } from "@mui/material";

import "../style.scss";

const PrimaryButton = ({ label, onClick = () => null, disabled = false }) => {
	return (
		<Box sx={{ display: "block", width: "100%" }} className="ian-form-group">
			<button
				className="ian-btn-primary ian-w-100"
				onClick={onClick}
				disabled={disabled}
			>
				{label}
			</button>
		</Box>
	);
};

export default PrimaryButton;
