import React from "react";
import Grid from "@mui/material/Grid";

import SignIn from "../signIn";
import NonAuthenticatedLayout from "../common/Layout/NonAuthenticatedLayout";
import { PAGE } from "../../lib/enums/enum";

import "../../style/style.scss";
import "./style.scss";

const LandingPage = () => {

	return (
		<NonAuthenticatedLayout selectedPage={PAGE.HOME}>
			<Grid container spacing={0} px={0}>
				<Grid item xs={12} sm={12} className="ian-position-relative bg-white landingPadding ian-h-100 pt15">
					<SignIn />
				</Grid>
			</Grid>
		</NonAuthenticatedLayout>
	);
};

export default LandingPage;
