import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";

import * as properties from "../../../lib/properties/properties";
import PrimaryButton from "../../common/Button/PrimaryButton";
import Popup from "../../common/Popup";

const ErrorPopup = ({ error, okButtonAction }) => {
	const [openPopup, setOpenPopup] = useState(false);

	useEffect(() => {
		setOpenPopup(error !== null);
	}, [error]);

	/**
   * This function executes when user clicked on ok button.
   */
	const onClickOKButton = () => {
		okButtonAction();
		setOpenPopup(false);
	};

	return (
		<Grid container spacing={2}>
			<Grid item md={12}>
				<div className=" ian-vw-100 ian-position-relative notModal">
					<Grid item md={4}>
						<Popup
							title={properties.ERROR_POPUP_TITLE}
							openPopup={openPopup}
							setOpenPopup={setOpenPopup}
							isDisplyaCancelBtn={false}
						>
							<Grid>
								<Grid item md={12} xs={12}>
									<Typography
										variant="body2"
										className="ian-modal-typo modalPara-mb"
									>
										{error}
									</Typography>
									<PrimaryButton
										className="submit-btn"
										label={properties.ERROR_POPUP_BUTTON_TITLE}
										type="submit"
										onClick={onClickOKButton}
										showProgress={false}
									/>
								</Grid>
							</Grid>
						</Popup>
					</Grid>
				</div>
			</Grid>
			<Grid item md={6}></Grid>
		</Grid>
	);
};

export default ErrorPopup;
