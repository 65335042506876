import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as properties from "../../../../lib/properties/properties";
import * as validations from "../../../../lib/validator";
import { getValidationResultObject } from "../../../../lib/validator/util";
import { DEFAULT_PAGE_LIMIT_20, DEFAULT_PAGE_NUMBER } from "../../../../lib/constants";

import { addExpertArticle } from "../../../../state/expertContents/expertArticle/addExpertArticle";
import { resetExpertArticleState } from "../../../../state/expertContents/expertArticle/getExpertArticleAll";
import { getResourcePlanAll } from "../../../../state/employers/resourcePlan/getResourcePlanAll";

const useAddExpertArticleAction = () => {
	
	const stateEmployers = useSelector(state => state.employer.eligibleEmployers);

	const [expertArticleFormData, setExpertArticleFormData] = useState({});
	const [expertArticleFormValidationsEnabled, setExpertArticleFormValidationsEnabled] = useState(false);

	const dispatch = useDispatch();
	const fieldErrorObject = { fieldName: "", errorMessage: "" };

	//Get clear benefit home state
	useEffect(() => { dispatch(resetExpertArticleState()); }, []);

	//Get Resource plan
	const getResourcePlanData = async (value) => {
		const payload = { employerId: value, pagelimit: DEFAULT_PAGE_LIMIT_20, pagenumber: DEFAULT_PAGE_NUMBER };
		dispatch(getResourcePlanAll(payload));
	};

	//create expert article
	const createExpertArticle = async (expertArticleAPIRequest) => {
		const payload = { data: expertArticleAPIRequest };
		dispatch(addExpertArticle(payload));
	};

	//This function checks form field validations.
	const validateExpertArticleFormData = () => {
		if (validations.isEmpty(expertArticleFormData.employerId))
			return getValidationResultObject("employerId", properties.COMMON_EMPLOYER_INVALID, expertArticleFormValidationsEnabled);

		if (validations.isEmpty(expertArticleFormData.employerResourcePlanId))
			return getValidationResultObject("employerResourcePlanId", properties.COMMON_RESOURCE_PLAN_INVALID, true);

		if (validations.isEmpty(expertArticleFormData.resourceType))
			return getValidationResultObject("resourceType", properties.COMMON_RESOURCE_TYPE_INVALID, true);

		if (validations.isEmpty(expertArticleFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_INVALID, true);

		if (validations.isTitle(expertArticleFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(expertArticleFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_INVALID, true);

		if (validations.isDescription(expertArticleFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(expertArticleFormData.content))
			return getValidationResultObject("content", properties.CONTENT_EXPE_CONTENT_INVALID, true);

		if (validations.isEmpty(expertArticleFormData.previewImage))
			return getValidationResultObject("previewImage", properties.CONTENT_EXPE_PREVIEW_IMAGE_URL_INVALID, true);

		if (validations.isIanacareUrl(expertArticleFormData.previewImage))
			return getValidationResultObject("previewImage", properties.CONTENT_EXPE_PREVIEW_IMAGE_URL_INVALID, true);

		if (validations.isEmpty(expertArticleFormData.previewTitle))
			return getValidationResultObject("previewTitle", properties.CONTENT_EXPE_PREVIEW_TITLE_INVALID, true);

		if (validations.isTitle(expertArticleFormData.previewTitle))
			return getValidationResultObject("previewTitle", properties.COMMON_PREVIEW_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

		return { fieldErrorObject, expertArticleFormValidationsEnabled: false };
	};

	//This function calls when user entering data to input fields and store data in the state after validation.
	const onChange = (event) => {
		event.persist();
		const name = event.target.name;
		const val = event.target.value;
		if (name !== "employerId") {
			//set expertArticleFormValidationsEnabled to true when employer id field not change 
			setExpertArticleFormValidationsEnabled(true);
		} else {
			if (validations.isEmpty(val)) {
				//set expertArticleFormValidationsEnabled to true when employer id change with invalid val 
				setExpertArticleFormValidationsEnabled(true);
			} else {
				getResourcePlanData(val);
				expertArticleFormData.employerResourcePlanId = "";
			}
		}
		setExpertArticleFormData({ ...expertArticleFormData, [name]: val });
	};

	//This function calls when user submit data.
	const onSubmit = (event) => {
		if (event) event.preventDefault();
		if (validations.isEmpty(fieldErrorObject.errorMessage) && Object.keys(expertArticleFormData).length !== 0) {
			createExpertArticle(expertArticleFormData);
		} else {
			return;
		}
	};

	return { stateEmployers, expertArticleFormData, validateExpertArticleFormData, onChange, onSubmit };
};

export default useAddExpertArticleAction;
