import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { addExpertArticleApi } from "../../../services/api/expertArticleService";

//Action(s)
export const addExpertArticle = (payload) => ({
	type: TYPES.ADD_EXPERT_ARTICLE,
	payload: payload
});
const addExpertArticleSuccess = (payload) => ({
	type: TYPES.ADD_EXPERT_ARTICLE_SUCCESS,
	payload: payload
});
const addExpertArticleError = (error) => ({
	type: TYPES.ADD_EXPERT_ARTICLE_ERROR,
	payload: error
});

//Saga(s)
export function* addExpertArticleSaga({ payload }) {
	try {
		const response = yield call(addExpertArticleApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(addExpertArticleSuccess(response.data));
		}
	} catch (error) {
		yield put(addExpertArticleError(error));
	}
}
