import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import * as validations from "../../../lib/validator";
import { getBenefitItemAll, cacheBenefitItemSearchedData, resetBenefitItemState } from "../../../state/benefits/benefitItem/getBenefitItemAll";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_LIMIT } from "../../../lib/constants";

const useBenefitItemAction = () => {

	const stateEmployer = useSelector(state => state.employer);
	const stateSearchedBenefitItem = useSelector(state => state.benefitItem?.search);

	const dispatch = useDispatch();
	const history = useHistory();
	const [benefitItemSearchedEmployer, setBenefitItemSearchedEmployer] = useState({ employerId: stateSearchedBenefitItem?.employerId });

	const getBenefitItemData = async () => {
		const payload = { employerId: benefitItemSearchedEmployer.employerId, pagelimit: DEFAULT_PAGE_LIMIT, pagenumber: DEFAULT_PAGE_NUMBER };
		dispatch(getBenefitItemAll(payload));
		//set the previous Search results
		dispatch(cacheBenefitItemSearchedData({ employerId: benefitItemSearchedEmployer.employerId, pagenumber: DEFAULT_PAGE_NUMBER }));
	};

	//This function checks required field validations.
	const isValidData = () => {
		return !validations.isEmpty(benefitItemSearchedEmployer.employerId);
	};

	//This function call create new benefit item form
	const onClickAddForm = () => {
		history.push("/benefits?tab=addBenefitItem");
	};

	//This function call search data
	const onClickSearch = () => {
		getBenefitItemData();
	};

	//This function calls when user entering data to input fields and store data in the state after validation
	const onChange = (event) => {
		event.persist();
		let name = event.target.name;
		let val = event.target.value;
		dispatch(resetBenefitItemState());
		dispatch(cacheBenefitItemSearchedData({ employerId: "", pagenumber: DEFAULT_PAGE_NUMBER }));
		setBenefitItemSearchedEmployer({ ...benefitItemSearchedEmployer, [name]: val });
	};

	//Return object and function
	return { stateEmployer, benefitItemSearchedEmployer, isValidData, onClickAddForm, onClickSearch, onChange };
};

export default useBenefitItemAction;
