import * as TYPES from "./types";

const initialState = {
	benefitItem: {},
	benefitItems: [],
	pageData: {}
};

const benefitItemReducer = (state = initialState, action) => {
	switch (action.type) {
	case TYPES.ADD_BENEFIT_ITEM:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.ADD_BENEFIT_ITEM_SUCCESS:
		return {
			...state,
			loading: false,
			success: true,
			benefitItem: action.payload,
			error: null
		};
	case TYPES.ADD_BENEFIT_ITEM_ERROR:
		return {
			...state,
			loading: false,
			success: false,
			benefitItem: "",
			error: action.payload
		};
	case TYPES.GET_BENEFIT_ITEM:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.GET_BENEFIT_ITEM_SUCCESS:
		return {
			...state,
			loading: false,
			benefitItem: action.payload,
			error: null
		};
	case TYPES.GET_BENEFIT_ITEM_ERROR:
		return {
			...state,
			loading: false,
			benefitItem: "",
			error: action.payload.errorMessage
		};
	case TYPES.GET_BENEFIT_ITEM_ALL:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.GET_BENEFIT_ITEM_ALL_SUCCESS:
		return {
			...state,
			loading: false,
			benefitItems: action.payload.benefitItemRSList,
			pageData: { pageCount: action.payload.pageCount, pageLimit: action.payload.pageLimit, pageNumber: action.payload.pageNumber },
			error: null
		};
	case TYPES.GET_BENEFIT_ITEM_ALL_ERROR:
		return {
			...state,
			loading: false,
			benefitItems: [],
			error: null
		};
	case TYPES.UPDATE_BENEFIT_ITEM:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.UPDATE_BENEFIT_ITEM_SUCCESS:
		return {
			...state,
			loading: false,
			benefitItem: action.payload,
			success: true,
			error: null
		};
	case TYPES.UPDATE_BENEFIT_ITEM_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload
		};
	case TYPES.CACHE_BENEFIT_ITEM_SEARCHED_DATA:
		return {
			...state,
			search: action.payload
		};
	case TYPES.CLEAR_BENEFIT_ITEM:
		return {
			...state,
			loading: false,
			success: false,
			benefitItems: [],
			error: null
		};
	default:
		return state;
	}
};
export default benefitItemReducer;
