import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../..//lib/enums/httpData";
import * as TYPES from "./types";
import { addExpertVideoApi } from "../../../services/api/expertVideoService";

//Action(s)
export const addExpertVideo = (expertVideoData) => ({
	type: TYPES.ADD_EXPERT_VIDEO,
	expertVideoData
});
const addExpertVideoSuccess = (payload, callback) => ({
	type: TYPES.ADD_EXPERT_VIDEO_SUCCESS,
	payload: { payload },
	callback: callback
});
const addExpertVideoError = (error) => ({
	type: TYPES.ADD_EXPERT_VIDEO_ERROR,
	payload: { error }
});

//Saga(s)
export function* addExpertVideoSaga({ expertVideoData }) {
	try {
		const response = yield call(addExpertVideoApi, expertVideoData);
		if (response.status === HTTP_CODE.OK) {
			yield put(addExpertVideoSuccess(response.data));
		}
	} catch (error) {
		yield put(addExpertVideoError(error));
	}
}
