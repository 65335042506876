//Auth : Signin|Logout
export const SIGNIN_LABEL = "SIGN IN";
export const SIGNIN_DESCRIPTION_LABEL = "The platform for the admin users.";
export const SIGNIN_UNAUTHORIZED_MESSAGE = "You don't have permission to access this section.";
export const LOGOUT_CONFIRMATION_POPUP_TITLE = "Are you sure you want to log out?";
export const LOGOUT_CONFIRMATION_POPUP_BUTTON_TITLE = "LOGOUT";

//Config : Label 
export const CONFIG_BASH_URL_LABEL = "Bash URL Config";
export const CONFIG_TERMS_AND_CONDITION_URL_LABEL = "Terms and Condition url";
export const CONFIG_UPDATE_BUTTON_TEXT = "UPDATE CONFIG DATA";
export const CONFIG_UPDATE_ALERT_MESSAGE = "UPDATE ALERT MESSAGE";
export const CONFIG_LABEL_ALERT1 = "Alert 1";
export const CONFIG_LABEL_ALERT2 = "Alert 2";
export const CONFIG_LABEL_ALERT3 = "Alert 3";

//Employer
export const EMPLOYER_TITLE = "Employers";
export const EMPLOYER_CREATE_TITLE = "Create Employer";
export const EMPLOYER_UPDATE_TITLE = "Update Employer";
export const EMPLOYER_SEARCH_LABEL = "Employer Name";
export const EMPLOYER_SEARCH_PLASHEHOLDER = "Employer Name";
export const EMPLOYER_NAME_LABEL = "Name *";
export const EMPLOYER_NAME_PLACEHOLDER = "Name";
export const EMPLOYER_DESCRIPTION_LABEL = "Description";
export const EMPLOYER_DESCRIPTION_PLACEHOLDER = "Description";
export const EMPLOYER_LINK_LABEL = "Link";
export const EMPLOYER_LINK_PLACEHOLDER = "Link";
export const EMPLOYER_WELCOME_NOTE_LABEL = "Welcome Note";
export const EMPLOYER_WELCOME_NOTE_PLACEHOLDER = "Welcome Note";
export const EMPLOYER_PLAT_FORM_LABEL = "Platform";
export const EMPLOYER_PLAT_FORM_PLACEHOLDER = "Select a Platform";
export const EMPLOYER_AUTH_OPTIONS_LABEL = "Auth Options";
export const EMPLOYER_RESOURCE_ENABLED_LABEL = "Resource Enabled";
export const EMPLOYER_NAME_INVALID = "Enter a valid Name";
export const EMPLOYER_NAME_MAX_CHARACTERS_REACHED_INVALID = "Name should be maximum 150 characters";
export const EMPLOYER_LINK_INVALID = "Enter a valid Link";
export const EMPLOYER_WELCOME_NOTE_MAX_CHARACTERS_REACHED_INVALID = "Welcome Note should be maximum 200 characters";
export const EMPLOYER_PLAT_FORM_INVALID = "Enter a valid Platform";
export const EMPLOYER_AUTH_OPTIONS_REQUIERED = "Employer auth option required";
export const EMPLOYER_NO_DATA_ENTER_AND_SEARCH_LABEL = "No Data. Please Enter valid Employer Name and click search";

//Common : Title|Label|Placeholder|Valitation
export const COMMON_CREATE_LABEL = "CREATE";
export const COMMON_UPDATE_LABEL = "UPDATE";
export const CANCEL_LABEL = "Cancel";
export const COMMON_SEARCH_LABEL = "Search";
export const COMMON_ADD_NEW_LABEL = "Add New";
export const COMMON_EMPLOYER_LABEL = "Employer *";
export const COMMON_EMPLOYER_SEARCH_LABEL = "Employer";
export const COMMON_SELECT_OPTION_EMPLOYER_LABEL = "Select an Employer";
export const COMMON_SELECT_OPTION_RESOURCE_PLAN_LABEL = "Select an Employer Resource Plan";
export const COMMON_SELECT_OPTION_RESOURCE_TYPE_LABEL = "Select a Resource Type";
export const COMMON_EMPLOYER_RESOURCE_PLAN_LABEL = "Employer Resource Plan *";
export const COMMON_RESOURCE_TYPE_LABEL = "Resource Type *";
export const COMMON_DESCRIPTION_LABEL = "Description *";
export const COMMON_DESCRIPTION_PLACEHOLDER = "Description";
export const COMMON_CONTENT_LABEL = "Content *";
export const COMMON_CONTENT_PLACEHOLDER = "Content (html content)";
export const COMMON_PREVIEW_IMAGE_LABEL = "Preview Image *";
export const COMMON_PREVIEW_IMAGE_PLACEHOLDER = "Preview Image";
export const COMMON_PREVIEW_TITLE_LABEL = "Preview Title *";
export const COMMON_PREVIEW_TITLE_PLACEHOLDER = "Preview Title";
export const COMMON_TITLE_LABEL = "Title *";
export const COMMON_TITLE_PLACEHOLDER = "Title";
export const COMMON_EMPLOYER_LOGO_URL_LABEL = "Employer Logo URL";
export const COMMON_EMPLOYER_LOGO_URL_PLACEHOLDER = "Employer Logo URL";
export const COMMON_EMPLOYER_LOGO_MANDATORY_URL_LABEL = "Employer Logo URL *";
export const COMMON_NO_DATA_SELECT_AND_SEARCH_LABEL = "No Data. Please select/change employer and click search.";
export const COMMON_VIDEO_LENGTH_PLACEHOLDER = "MM:SS";
export const COMMON_EMPLOYER_INVALID = "Select a valid Employer";
export const COMMON_RESOURCE_PLAN_INVALID = "Select a valid Employer Resource Plan";
export const COMMON_RESOURCE_TYPE_INVALID = "Select a valid Resource Type";
export const COMMON_TITLE_INVALID = "Enter a valid Title";
export const COMMON_VIDEO_TITLE_INVALID = "Enter a valid Video Title";
export const COMMON_TITLE_MAX_CHARACTERS_REACHED_INVALID = "Title should be maximum 150 characters";
export const COMMON_PREVIEW_TITLE_MAX_CHARACTERS_REACHED_INVALID = "Preview Title should be maximum 150 characters";
export const COMMON_VIDEO_TITLE_MAX_CHARACTERS_REACHED_INVALID = "Video Title should be maximum 150 characters";
export const COMMON_DESCRIPTION_INVALID = "Enter a valid Description";
export const COMMON_DESCRIPTION_MAX_CHARACTERS_REACHED_INVALID = "Description should be maximum 200 characters";
export const COMMON_EMP_LOGO_URL_INVALID = "Enter a valid Employer Logo URL";
export const COMMON_COMING_SOON_LABEL = "Coming soon";

//Benefit : Title|Sub Title|Label|Validation
export const BENEFIT_TITLE = "Benefits";
export const BENEFIT_ITEM_CREATE_TITLE = "Create Benefit Item";
export const BENEFIT_ITEM_UPDATE_TITLE = "Update Benefit Item";
export const BENEFIT_ITEM_ID_TITLE = "Benefit Item ID";
export const BENEFIT_ITEM_SUB_LABEL = "Benefit Item";
export const BENEFIT_ITEM_ID_INVALID = "Select a valid Benefit Item ID";
export const BENEFIT_ITEMS_LABEL = "Benefit Items";
export const BENEFIT_HOME_CREATE_TITLE = "Create Benefit Home";
export const BENEFIT_HOME_UPDATE_TITLE = "Update Benefit Home";
export const BENEFIT_HOME_TITLE = "Benefit Home";
export const BENEFIT_SUMMARY_TITLE_SUB_TITLE = "Summary Title";
export const BENEFIT_SUMMARY_DESC_SUB_TITLE = "Summary Description";
export const BENEFIT_SUMMARY_TITLE_INVALID = "Enter a valid Summary Title";
export const BENEFIT_SUMMARY_TITLE_INVALID_MAX_CHARACTERS_REACHED = "Summary Title should be maximum 150 characters";
export const BENEFIT_SUMMARY_DESCRIPTION_INVALID = "Enter a valid Summary Description";
export const BENEFIT_SUMMARY_DESCRIPTION_INVALID_MAX_CHARACTERS_REACHED = "Summary Description should be maximum 200 characters";
export const BENEFIT_SELECT_OPTION_ID_LABEL = "Select a Benefit Item Id";
export const BENEFIT_SUMMARY_TITLE_LABEL = "Summary Title *";
export const BENEFIT_SUMMARY_TITLE_PLACEHOLDER = "Summary Title";
export const BENEFIT_SUMMARY_DESCRIPTION_LABEL = "Summary Description *";
export const BENEFIT_SUMMARY_DESCRIPTION_PLACEHOLDER = "Summary Description";
export const BENEFIT_BODY_TITLE_LABEL = "Body Title *";
export const BENEFIT_BODY_TITLE_PLACEHOLDER = "Body Title";
export const BENEFIT_BODY_CHECK_LIST_LABEL = "Body Check List";
export const BENEFIT_BODY_ITEM_LIST_LABEL = "Body Item List";
export const BENEFIT_BODY_ITEM_LIST_PLACEHOLDER = "Body Item List";
export const BENEFIT_BODY_TITLE_INVALID = "Enter a valid Body Title";
export const BENEFIT_BODY_TITLE_MAX_CHARACTERS_REACHED_INVALID = "Body Title should be maximum 150 characters";
export const BENEFIT_BODY_CHECK_LIST_INVALID = "Enter a valid Body Check List";
export const BENEFIT_BODY_ITEM_LIST_INVALID = "Enter a valid Body Item List";
export const BENEFIT_FOOTER_TITLE_LABEL = "Footer Title *";
export const BENEFIT_FOOTER_TITLE_PLACEHOLDER = "Footer Title";
export const BENEFIT_FOOTER_BUTTON_TEXT_LABEL = "Footer Button Text";
export const BENEFIT_FOOTER_PHONE_LABEL = "Footer Phone";
export const BENEFIT_FOOTER_PHONE_PLACEHOLDER = "Footer Phone : +15081234567/800-555-1234";
export const BENEFIT_FOOTER_DESCRIPTION_LABEL = "Footer Description *";
export const BENEFIT_FOOTER_DESCRIPTION_PLACEHOLDER = "Footer Description";
export const BENEFIT_FOOTER_URL_LABEL = "Footer URL";
export const BENEFIT_FOOTER_URL_PLACEHOLDER = "Footer URL";
export const BENEFIT_FOOTER_TITLE_INVALID = "Enter a valid Footer Title";
export const BENEFIT_FOOTER_TITLE_MAX_CHARACTERS_REACHED_INVALID = "Footer Title should be maximum 150 characters";
export const BENEFIT_FOOTER_BUTTON_TEXT_INVALID = "Enter a valid Footer Button Text";
export const BENEFIT_FOOTER_DESCRIPTION_INVALID = "Enter a valid Footer Description";
export const BENEFIT_FOOTER_DESCRIPTION_MAX_CHARACTERS_REACHED_INVALID = "Footer Description should be maximum 200 characters";
export const BENEFIT_FOOTER_PHONE_INVALID = "Enter a valid Footer Phone";
export const BENEFIT_FOOTER_URL_INVALID = "Enter a valid Footer URL";

//Content: Title|Sub Title|Label|Validation
export const CONTENT_EXPE_ARTICLE_PREVIEW_TITLE = "Preview";
export const CONTENT_EXPE_ARTICLE_VIDEO_TITLE = "Video";
export const CONTENT_EXPE_ARTICLE_CREATE_TITLE = "Create Expert Article";
export const CONTENT_EXPE_ARTICLE_UPDATE_TITLE = "Update Expert Article";
export const CONTENT_EXPE_ARTICLE_SUB_TITLE = "Expert Article";
export const CONTENT_EXPE_ARTICLE_LABEL = "Expert Articles";
export const CONTENT_EXPE_ARTICLE_ID_LABEL = "Expert Article ID *";
export const CONTENT_EXPE_ARTICLE_ID_INVALID = "Select a valid Expert Article ID";
export const CONTENT_EXPE_VIDEO_CREATE_TITLE = "Create Expert Video";
export const CONTENT_EXPE_VIDEO_UPDATE_TITLE = "Update Expert Video";
export const CONTENT_EXPE_VIDEO_SUB_TITLE = "Expert Video";
export const CONTENT_EXPE_VIDEO_LABEL = "Expert Videos";
export const CONTENT_EXPE_VIDEO_ID_LABEL = "Expert Video ID *";
export const CONTENT_EXPE_VIDEO_URL_LABEL = "Video URL *";
export const CONTENT_EXPE_VIDEO_URL_PLACEHOLDER = "Video URL";
export const CONTENT_EXPE_VIDEO_LENGTH_LABEL = "Video Length *";
export const CONTENT_EXPE_VIDEO_URL_INVALID = "Enter a valid video URL";
export const CONTENT_EXPE_VIDEO_LENGTH_INVALID = "Enter a valid Video Length";
export const CONTENT_EXPE_VIDEO_ID_INVALID = "Select a valid Expert Video ID";
export const CONTENT_EXPE_CONTENT_LENGTH_INVALID = "Enter maximum of 200 characters";
export const CONTENT_EXPE_CONTENT_INVALID = "Enter a valid Content";
export const CONTENT_EXPE_PREVIEW_IMAGE_URL_INVALID = "Enter a valid Preview Image URL";
export const CONTENT_EXPE_ARTICLE_PREVIEW_IMAGE_URL_INVALID = "Enter a valid Article Preview Image URL";
export const CONTENT_EXPE_VIDEO_PREVIEW_IMAGE_URL_INVALID = "Enter a valid Video Preview Image URL";
export const CONTENT_EXPE_PREVIEW_TITLE_INVALID = "Enter a valid Preview Title";

//Pillar Title|Label
export const PILLAR_TITLE = "Pillars";
export const PILLAR_CREATE_TITLE = "Create Pillar";
export const PILLAR_UPDATE_TITLE = "Update Pillar";
export const PILLAR_BENEFITS_LABEL = "Benefits : ";
export const PILLAR_SELECT_OPTION_EXPERT_ARTICLE_ID_LABEL = "Select an Expert Article ID";
export const PILLAR_SELECT_OPTION_EXPERT_VIDEO_ID_LABEL = "Select an Expert Video ID";

//Resource plan: Title|Label|Placeholder|Validation
export const RESOURCE_PLAN_TITLE = "Resource Plans";
export const RESOURCE_PLAN_CREATE_TITLE = "Create Resource Plan";
export const RESOURCE_PLAN_UPDATE_TITLE = "Update Resource Plan";
export const RESOURCE_PLAN_NAME_LABEL = "Name *";
export const RESOURCE_PLAN_NAME_PLACEHOLDER = "Name";
export const RESOURCE_PLAN_EMP_CATEGORY_LABEL = "Employee Category *";
export const RESOURCE_PLAN_DESCRIPTION_LABEL = "Description";
export const RESOURCE_PLAN_LABEL = "Active : ";
export const RESOURCE_PLAN_EMP_CATEGORY_INVALID = "Select a valid Employee Category";
export const RESOURCE_PLAN_NAME_INVALID = "Enter a valid Name";
export const RESOURCE_PLAN_NAME_MAX_CHARACTERS_REACHED_INVALID = "Name should be maximum 150 characters";

//Popup: Succes|Error
export const SUCCESS_POPUP_TITLE = "Success!";
export const SUCCESS_POPUP_BUTTON_TITLE = "OK";
export const SUCCESS_POPUP_CREATE_MESSAGE = "Data successfully inserted!";
export const SUCCESS_POPUP_UPDATE_MESSAGE = "Data successfully updated!";
export const ERROR_POPUP_TITLE = "Error!";
export const ERROR_POPUP_MESSAGE = "Something went wrong. Please try again later.";
export const ERROR_POPUP_BUTTON_TITLE = "OK";



