import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import BackButton from "../../../common/Button/BackButton";
import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import SelectField from "../../../common/SelectField";
import PrimaryButton from "../../../common/Button/PrimaryButton";
import ErrorPopup from "../../../common/ErrorPopup";
import SuccessPopup from "../../../common/SuccessPopup";
import ToggleSwitch from "../../../common/ToggleSwitch";
import CheckboxesTags from "../../../common/CheckboxesTags";
import * as properties from "../../../../lib/properties/properties";
import { resetEmployerState } from "../../../../state/employers/employer/getEmployerAll";
import { EmployerAuthOptions, EmployerPlatform } from "../../../../lib/constants/employerConst";

import useAddEmployerAction from "./useAddEmployerAction";

import "../../common/style.scss";

const AddEmployer = () => {

	const stateEmployers = useSelector(state => state.employer);

	let history = useHistory();
	const dispatch = useDispatch();

	//Custom hook for this form action
	const { employerFormAuthOptions, employerFormResourceEnabled, validateEmployerFormData, changeResourceEnabled, onChangeAuthOptions, onChange, onSubmit } = useAddEmployerAction();

	//This function executes when error popup ok button clicked.
	const onErrorPopupButtonClicked = () => {
		dispatch(resetEmployerState());
	};

	//This function executes when success popup ok button clicked.
	const onSuccessPopupButtonClicked = () => {
		dispatch(resetEmployerState());
		history.push("/employers");
	};

	const onClickBackButton = () => {
		history.push("/employers");
	};

	return (
		<div>
			<BackButton className="px-5" onClick={onClickBackButton} />
			<div className="tabSectionRight ian-position-relative">
				<div className="ian-centeredSection ian-w-40 scrollTop">
					<h5 className="ian-modal-title">
						{properties.EMPLOYER_CREATE_TITLE}
					</h5>
					<div className="ian-form-container">
						<TextField
							label={properties.EMPLOYER_NAME_LABEL}
							placeholder={properties.EMPLOYER_NAME_PLACEHOLDER}
							name="name"
							id="name"
							type="text"
							onChange={onChange}
						/>
						<TextArea
							label={properties.EMPLOYER_DESCRIPTION_LABEL}
							placeholder={properties.EMPLOYER_DESCRIPTION_PLACEHOLDER}
							name="description"
							id="description"
							type="text"
							onChange={onChange}
						/>
						<TextField
							label={properties.EMPLOYER_LINK_LABEL}
							placeholder={properties.EMPLOYER_LINK_PLACEHOLDER}
							name="link"
							id="link"
							type="text"
							onChange={onChange}
						/>
						<TextArea
							label={properties.EMPLOYER_WELCOME_NOTE_LABEL}
							placeholder={properties.EMPLOYER_WELCOME_NOTE_PLACEHOLDER}
							name="welcomeNote"
							id="welcomeNote"
							type="text"
							onChange={onChange}
						/>
						<SelectField
							label={properties.EMPLOYER_PLAT_FORM_LABEL}
							name="platform"
							id="platform"
							options={EmployerPlatform}
							onChange={onChange}
							txtCounterEnabled={false}
							selectOption={properties.EMPLOYER_PLAT_FORM_PLACEHOLDER}
						/>
						<CheckboxesTags
							label={properties.EMPLOYER_AUTH_OPTIONS_LABEL}
							placeholder={properties.EMPLOYER_AUTH_OPTIONS_LABEL}
							name="authOptions"
							id="authOptions"
							type="text"
							options={EmployerAuthOptions}
							value={employerFormAuthOptions}
							onChange={onChangeAuthOptions}
						/>
						<div className="ian-toggle-custom-switch">
							<ToggleSwitch label={properties.EMPLOYER_RESOURCE_ENABLED_LABEL} name="resourceEnabled" checked={employerFormResourceEnabled} onChange={changeResourceEnabled} />
						</div>

						{validateEmployerFormData().validationsEnabled ? (
							<div className="ian-error-warning show px-5 mt-5" sx={{ display: "none" }}>
								<p>
									{validateEmployerFormData().fieldErrorObject.errorMessage}
								</p>
							</div>
						) : (
							<PrimaryButton
								className="px-5"
								label={properties.COMMON_CREATE_LABEL}
								disabled={validateEmployerFormData().fieldErrorObject.errorMessage || stateEmployers.loading}
								showProgress={false}
								onClick={onSubmit}
							/>
						)}
					</div>
					{stateEmployers.success ? <SuccessPopup
						message={stateEmployers.employer ? properties.SUCCESS_POPUP_CREATE_MESSAGE : null} okButtonAction={onSuccessPopupButtonClicked}
					/> : null}
					{stateEmployers.error ? <ErrorPopup error={stateEmployers.error} okButtonAction={onErrorPopupButtonClicked} /> : null}
				</div>
			</div>
		</div>
	);
};

export default AddEmployer;
