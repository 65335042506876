export const ADD_EXPERT_ARTICLE = "ADD_EXPERT_ARTICLE";
export const ADD_EXPERT_ARTICLE_SUCCESS = "ADD_EXPERT_ARTICLE_SUCCESS";
export const ADD_EXPERT_ARTICLE_ERROR = "ADD_EXPERT_ARTICLE_ERROR";

export const  GET_EXPERT_ARTICLE = "GET_EXPERT_ARTICLE";
export const  GET_EXPERT_ARTICLE_SUCCESS = "GET_EXPERT_ARTICLE_SUCCESS";
export const  GET_EXPERT_ARTICLE_ERROR = "GET_EXPERT_ARTICLE_ERROR";

export const  GET_EXPERT_ARTICLE_ALL = "GET_EXPERT_ARTICLE_ALL";
export const  GET_EXPERT_ARTICLE_ALL_SUCCESS = "GET_EXPERT_ARTICLE_ALL_SUCCESS";
export const  GET_EXPERT_ARTICLE_ALL_ERROR = "GET_EXPERT_ARTICLE_ALL_ERROR";

export const UPDATE_EXPERT_ARTICLE = "UPDATE_EXPERT_ARTICLE";
export const UPDATE_EXPERT_ARTICLE_SUCCESS = "UPDATE_EXPERT_ARTICLE_SUCCESS";
export const UPDATE_EXPERT_ARTICLE_ERROR = "UPDATE_EXPERT_ARTICLE_ERROR";

export const CACHE_EXPERT_ARTICLE_SEARCHED_DATA = "CACHE_EXPERT_ARTICLE_SEARCHED_DATA";

export const CLEAR_EXPERT_ARTICLE = "CLEAR_EXPERT_ARTICLE";

