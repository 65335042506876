import * as TYPES from "./types";

const initialState = {
	expertArticle: {},
	expertArticles: []
};

const expertArticleReducer = (state = initialState, action) => {
	switch (action.type) {
	case TYPES.ADD_EXPERT_ARTICLE:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.ADD_EXPERT_ARTICLE_SUCCESS:
		return {
			...state,
			success: true,
			expertArticle: action.payload,
			error: null
		};
	case TYPES.ADD_EXPERT_ARTICLE_ERROR:
		return {
			...state,
			success: false,
			loading: false,
			error: action.payload.errorMessage
		};
	case TYPES.GET_EXPERT_ARTICLE:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.GET_EXPERT_ARTICLE_SUCCESS:
		return {
			...state,
			expertArticle: action.payload,
			loading: false,
			error: null
		};
	case TYPES.GET_EXPERT_ARTICLE_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload.message
		};
	case TYPES.GET_EXPERT_ARTICLE_ALL:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.GET_EXPERT_ARTICLE_ALL_SUCCESS:
		return {
			...state,
			loading: false,
			expertArticles: action.payload.expertArticlesRs,
			pageData: { pageCount: action.payload.pageCount, pageLimit: action.payload.pageLimit, pageNumber: action.payload.pageNumber },
			error: null
		};
	case TYPES.GET_EXPERT_ARTICLE_ALL_ERROR:
		return {
			...state,
			loading: false,
			expertArticles: [],
			error: null
		};
	case TYPES.UPDATE_EXPERT_ARTICLE:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.UPDATE_EXPERT_ARTICLE_SUCCESS:
		return {
			...state,
			loading: false,
			success: true,
			expertArticle: action.payload,
			error: null
		};
	case TYPES.UPDATE_EXPERT_ARTICLE_ERROR:
		return {
			...state,
			loading: false,
			success: false,
			error: action.payload.error.errorMessage
		};
	case TYPES.CACHE_EXPERT_ARTICLE_SEARCHED_DATA:
		return {
			...state,
			search: action.payload
		};
	case TYPES.CLEAR_EXPERT_ARTICLE:
		return {
			...state,
			loading: false,
			success: false,
			expertArticles: [],
			error: null
		};
	default:
		return state;
	}
};
export default expertArticleReducer;
