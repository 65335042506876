import { ADD_BENEFIT_ITEM_URL, GET_BENEFIT_ITEM_ALL_URL, GET_BENEFIT_ITEM_URL, UPDATE_BENEFIT_ITEM_URL } from "./endPoints";
import networkClient from "./networkClient";
import { HTTP_METHOD } from "../../lib/enums/httpData";

export function addBenefitItemApi(benefitItemData) {
	return networkClient().request({
		method: HTTP_METHOD.POST,
		url: ADD_BENEFIT_ITEM_URL,
		data: benefitItemData
	});
}

export function getBenefitItemApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.GET,
		url: GET_BENEFIT_ITEM_URL.replace("{benefitItemId}", payload.benefititemid).replace("{employerid}", payload.employerid)
	});
}

export function getBenefitItemAllApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.GET,
		url: GET_BENEFIT_ITEM_ALL_URL.replace("{employerid}", payload.employerId).replace("{pagelimit}", payload.pagelimit).replace("{pagenumber}", payload.pagenumber)
	});
}

export function updateBenefitItemApi(benefitItemData, benefitItemId) {
	return networkClient().request({
		method: HTTP_METHOD.PUT,
		url: UPDATE_BENEFIT_ITEM_URL.replace("{benefitItemId}", benefitItemId),
		data: benefitItemData
	});
}


