import { combineReducers } from "redux";
import pillarReducer from "./pillars/pillar";
import benefitHomeReducer from "./benefits/benefitHome";
import benefitItemReducer from "./benefits/benefitItem";
import expertVideoReducer from "./expertContents/expertVideo";
import expertArticleReducer from "./expertContents/expertArticle";
import employerReducer from "./employers/employer";
import resourcePlansReducer from "./employers/resourcePlan";

export default combineReducers({
	pillar: pillarReducer,
	benefitHome: benefitHomeReducer,
	benefitItem: benefitItemReducer,
	expertVideo: expertVideoReducer,
	expertArticle: expertArticleReducer,
	employer: employerReducer,
	resourcePlan: resourcePlansReducer
	
});
