import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../..//lib/enums/httpData";
import * as TYPES from "./types";
import { getExpertVideoApi } from "../../../services/api/expertVideoService";


//Action(s)
export const getExpertVideo = (payload) => ({
	type: TYPES.GET_EXPERT_VIDEO,
	payload: payload
});
const getExpertVideoSuccess = (payload) => ({
	type: TYPES.GET_EXPERT_VIDEO_SUCCESS,
	payload: payload
});
const getExpertVideoError = (error) => ({
	type: TYPES.GET_EXPERT_VIDEO_ERROR,
	payload: { error }
});

//Saga(s)
export function* getExpertVideoSaga({ payload }) {
	try {
		const response = yield call(getExpertVideoApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(getExpertVideoSuccess(response.data));
		}
	} catch (error) {
		yield put(getExpertVideoError(error));
	}
}
