export const ADD_PILLAR = "ADD_PILLAR";
export const ADD_PILLAR_SUCCESS = "ADD_PILLAR_SUCCESS";
export const ADD_PILLAR_ERROR = "ADD_PILLAR_ERROR";

export const GET_PILLAR = "GET_PILLAR";
export const GET_PILLAR_SUCCESS = "GET_PILLAR_SUCCESS";
export const GET_PILLAR_ERROR = "GET_PILLAR_ERROR";

export const GET_PILLAR_ALL = "GET_PILLAR_ALL";
export const GET_PILLAR_ALL_SUCCESS = "GET_PILLAR_ALL_SUCCESS";
export const GET_PILLAR_ALL_ERROR = "GET_PILLAR_ALL_ERROR";

export const UPDATE_PILLAR = "UPDATE_PILLAR";
export const UPDATE_PILLAR_SUCCESS = "UPDATE_PILLAR_SUCCESS";
export const UPDATE_PILLAR_ERROR = "UPDATE_PILLAR_ERROR";

export const CACHE_PILLAR_SEARCHED_DATA = "CACHE_PILLAR_SEARCHED_DATA";

export const CLEAR_PILLAR = "CLEAR_PILLAR";
