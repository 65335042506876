import { ADD_EMPLOYER_URL, GET_EMPLOYER_URL, GET_EMPLOYER_ALL_URL, UPDATE_EMPLOYER_URL, GET_RESOURCE_PLAN_ALL_URL, GET_RESOURCE_PLAN_URL, ADD_RESOURCE_PLAN_URL, UPDATE_RESOURCE_PLAN_URL } from "./endPoints";
import networkClient from "./networkClient";
import { HTTP_METHOD } from "../../lib/enums/httpData";
import { isEmpty } from "../../lib/validator";

export function addEmployerApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.POST,
		url: ADD_EMPLOYER_URL,
		data: payload.data
	});
}

export function getEmployerApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.GET,
		url: GET_EMPLOYER_URL.replace("{employerid}", payload.employerId),
		data: payload.data
	});
}

export function getEmployerAllApi(payload) {
	const searchedEmployer = !isEmpty(payload.employer) ? payload.employer : "";
	return networkClient().request({
		method: HTTP_METHOD.GET,
		url: GET_EMPLOYER_ALL_URL.replace("{searchedEmployer}", searchedEmployer.replace("&", "%26"))
			.replace("{pagelimit}", payload.pagelimit).replace("{pagenumber}", payload.pagenumber)
	});
}

export function updateEmployerApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.PUT,
		url: UPDATE_EMPLOYER_URL.replace("{employerid}", payload.data.id),
		data: payload.data
	});
}

export function getResourcePlanAllApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.GET,
		url: GET_RESOURCE_PLAN_ALL_URL.replace("{employerid}", payload.employerId)
	});
}

export function getResourcePlanApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.GET,
		url: GET_RESOURCE_PLAN_URL.replace("{employerid}", payload.employerid).
			replace("{resourcePlanId}", payload.resourcePlanId)
	});
}

export const addResourcePlanApi = (resourcePlanData) => {
	return networkClient().request({
		method: HTTP_METHOD.POST,
		url: ADD_RESOURCE_PLAN_URL.replace("{employerid}", resourcePlanData.employerId),
		data: resourcePlanData
	});
};

export const updateResourcePlanApi = (payload) => {
	return networkClient().request({
		method: HTTP_METHOD.PUT,
		url: UPDATE_RESOURCE_PLAN_URL.replace("{employerid}", payload.data.employerId)
			.replace("{resourcePlanId}", payload.data.id),
		data: payload.data
	});
};




