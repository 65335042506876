import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, LinearProgress } from "@mui/material";

import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import SelectField from "../../../common/SelectField";
import PrimaryButton from "../../../common/Button/PrimaryButton";
import BackButton from "../../../common/Button/BackButton";
import ErrorPopup from "../../../common/ErrorPopup";
import SuccessPopup from "../../../common/SuccessPopup";
import BenefitItemSection from "../common/benefitItemSection";
import * as properties from "../../../../lib/properties/properties";
import { getRowIdAndEmployerId, getSupportedUrlTooltip } from "../../../../lib/util/commonUtil";
import { NO_PAGE_LIMIT, NO_PAGE_COUNT } from "../../../../lib/constants";
import { ResourceTypeOptions } from "../../../../lib/constants/ResourceTypeOptions";

import { resetBenefitHomeState } from "../../../../state/benefits/benefitHome/getBenefitHomeAll";
import { getResourcePlanAll } from "../../../../state/employers/resourcePlan/getResourcePlanAll";
import { getBenefitItemAll } from "../../../../state/benefits/benefitItem/getBenefitItemAll";
import { getBenefitHome } from "../../../../state/benefits/benefitHome/getBenefitHome";

import useUpdateBenefitHomeAction from "./useUpdateBenefitHomeAction";

const UpdateBenefitHome = () => {

	const stateResourcePlanOptions = useSelector(state => state.resourcePlan?.resourcePlanOptions);
	const stateBenefitHome = useSelector(state => state.benefitHome);

	let history = useHistory();
	const dispatch = useDispatch();
	const urlBenefitHomeEmployerPathVariables = getRowIdAndEmployerId();

	//Get Benefit Home Data
	const benefitHomeApiCall = async () => {
		const payload = { benefitHomeId: urlBenefitHomeEmployerPathVariables[0], employerid: urlBenefitHomeEmployerPathVariables[1] };
		dispatch(getBenefitHome(payload));
	};

	//Get Resource plane
	const loadApiData = async (value) => {
		const payload = { employerId: value, pagelimit: NO_PAGE_LIMIT, pagenumber: NO_PAGE_COUNT };
		dispatch(getResourcePlanAll(payload));
		dispatch(getBenefitItemAll(payload));
	};

	useEffect(() => { benefitHomeApiCall(); loadApiData(urlBenefitHomeEmployerPathVariables[1]); }, []);

	//Custom hook for this form action
	const { stateEmployers, benefitHomeFormItems, benefitHomeFormData, validateBenefitHomeFormData, addBenefitItemFormFields, changeBenefitItemFormField, removeBenefitItemFormFields, onChange, onSubmit } = useUpdateBenefitHomeAction();

	//This function executes when error popup ok button clicked.
	const onErrorPopupButtonClicked = () => {
		dispatch(resetBenefitHomeState());
	};

	const onClickBackButton = () => {
		history.push("/benefits?tab=benefitHome");
	};

	//This function executes when success popup ok button clicked.
	const onSuccessPopupButtonClicked = () => {
		dispatch(resetBenefitHomeState());
		history.push("/benefits?tab=benefitHome");
	};

	return (
		<div>
			<BackButton className="px-5" onClick={onClickBackButton} />
			<div className="tabSectionRight ian-position-relative">
				<div className="ian-centeredSection ian-w-40 scrollTop">
					<div className="ian-form-container">
						<h3 className="ian-modal-title">
							{properties.BENEFIT_HOME_UPDATE_TITLE}
						</h3>
						{stateBenefitHome.loading && <Box sx={{ width: "100%" }}> <LinearProgress className="ian-linear-progress" /> </Box>}
						<div className="ian-form-container">
							<SelectField
								label={properties.COMMON_EMPLOYER_LABEL}
								name="employerId"
								id="employerId"
								options={stateEmployers}
								value={benefitHomeFormData.employerId}
								isDisabled={true}
							/>

							<SelectField
								label={properties.COMMON_EMPLOYER_RESOURCE_PLAN_LABEL}
								name="employerResourcePlanId"
								id="employerResourcePlanId"
								options={benefitHomeFormData.employerId ? stateResourcePlanOptions : []}
								selectOption={properties.COMMON_SELECT_OPTION_RESOURCE_PLAN_LABEL}
								value={benefitHomeFormData.employerResourcePlanId}
								isDisabled={true}
							/>
							<SelectField
								label={properties.COMMON_RESOURCE_TYPE_LABEL}
								name="resourceType"
								id="resourceType"
								options={ResourceTypeOptions}
								value={benefitHomeFormData.resourceType}
								isDisabled={true}
							/>
							<TextField
								label={properties.COMMON_TITLE_LABEL}
								placeholder={properties.COMMON_TITLE_PLACEHOLDER}
								name="title"
								id="title"
								type="text"
								onChange={onChange}
								value={benefitHomeFormData.title || ""}
							/>
							<TextArea
								label={properties.COMMON_DESCRIPTION_LABEL}
								placeholder={properties.COMMON_DESCRIPTION_PLACEHOLDER}
								name="description"
								value={benefitHomeFormData.description || ""}
								id="description"
								type="text"
								onChange={onChange}
								characterCount={benefitHomeFormData.description?.length}
							/>
							<TextField
								label={properties.COMMON_EMPLOYER_LOGO_MANDATORY_URL_LABEL}
								placeholder={properties.COMMON_EMPLOYER_LOGO_URL_PLACEHOLDER}
								name="employerLogo"
								value={benefitHomeFormData.employerLogo || ""}
								id="employerLogo"
								type="text"
								onChange={onChange}
								toolTipText={getSupportedUrlTooltip()}
							/>
							<BenefitItemSection benefitItems={benefitHomeFormItems} benefitHomes={benefitHomeFormData} formOnChange={changeBenefitItemFormField} addFormFields={addBenefitItemFormFields} removeFormFields={removeBenefitItemFormFields} />

							{validateBenefitHomeFormData().validationsEnabled ? (
								<div className="ian-error-warning show px-5 mt-5" sx={{ display: "none" }}>
									<p>
										{validateBenefitHomeFormData().fieldErrorObject.errorMessage}
									</p>
								</div>
							) : (
								<PrimaryButton
									className="px-5"
									label={properties.COMMON_UPDATE_LABEL}
									disabled={validateBenefitHomeFormData().fieldErrorObject.errorMessage || stateBenefitHome.loading}
									showProgress={false}
									onClick={onSubmit}
								/>
							)}
						</div>
					</div>
					{stateBenefitHome.error ? <ErrorPopup error={stateBenefitHome.error} okButtonAction={onErrorPopupButtonClicked} /> : ""}
					{stateBenefitHome.success ? <SuccessPopup
						message={stateBenefitHome.benefitHome ? properties.SUCCESS_POPUP_UPDATE_MESSAGE : null} okButtonAction={onSuccessPopupButtonClicked}
					/> : null}
				</div>
			</div>
		</div>
	);
};

export default UpdateBenefitHome;
