import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Container, Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

import { NO_PAGE_LIMIT, NO_PAGE_COUNT } from "../../lib/constants";
import LeftNavigator from "./common/LeftNavigator";
import ConfigData from "./configData";
import Notification from "./notification";
import AuthenticatedLayout from "../common/Layout/AuthenticatedLayout";
import { getEmployerAll } from "../../state/employers/employer/getEmployerAll";
import { isEmpty } from "../../lib/validator";

import "./style.scss";
import { PAGE } from "../../lib/enums/enum";

const General = () => {

	const stateEmployer = useSelector(state => state.employer);

	const dispatch = useDispatch();
	const [selectedIndex, setSelectedIndex] = useState(0);

	const getEmployerData = async () => {
		const payload = { pagelimit: NO_PAGE_LIMIT, pagenumber: NO_PAGE_COUNT };
		dispatch(getEmployerAll(payload));
	};

	useEffect(() => {
		localStorage.setItem("isLoggedIn", true);
		getEmployerData();
		const tab = new URLSearchParams(window.location.search).get("tab");
		switch (tab) {
		case "configData":
			setSelectedIndex(0);
			break;
		case "notification":
			setSelectedIndex(1);
			break;
		default:
			break;
		}
	}, []);

	return (
		<>
			{(isEmpty(stateEmployer.eligibleEmployers) && stateEmployer.loading) || stateEmployer.isEmployerChanged ?
				<Box sx={{ width: "20%" }} className="progress-loader-custom progress-loader-page">
					<LinearProgress className="ian-linear-progress" />
				</Box> :
				<AuthenticatedLayout selectedPage={selectedIndex === 0 || selectedIndex === 1 ? PAGE.GENERAL : ""}>
					<Container maxWidth="lg" className="el1260W">
						<Grid container spacing={0} px={0} className="generalContentSection">
							<Grid item xs={12} sm={5} lg={3} className="ian-position-relative borderRight el20pW">
								<LeftNavigator selectedIndex={selectedIndex}></LeftNavigator>
							</Grid>
							<Grid item xs={12} sm={7} lg={9} className="tabSectionRight ian-position-relative el80pW">

								{selectedIndex === 0 ? (<ConfigData />) : selectedIndex === 1 ? (<Notification />) : (<ConfigData />)}
							</Grid>
						</Grid>
					</Container>
				</AuthenticatedLayout>}
		</>

	);
};

export default General;
