import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as properties from "../../../../lib/properties/properties";
import * as validations from "../../../../lib/validator";
import { getValidationResultObject } from "../../../../lib/validator/util";
import { splitByHyphenAndTrim } from "../../../../lib/util/commonUtil";
import { updateBenefitHome } from "../../../../state/benefits/benefitHome/updateBenefitHome";

const useUpdateBenefitHomeAction = () => {

	const stateUpdateBenefitHome = useSelector(state => state.benefitHome.benefitHome);
	const stateBenefitItems = useSelector(state => state.benefitItem.benefitItems);
	const stateEmployers = useSelector(state => state.employer?.eligibleEmployers);

	const [benefitHomeFormData, setBenefitHomeFormData] = useState(stateUpdateBenefitHome);
	const [benefitHomeFormItems, setBenefitHomeFormItems] = useState(stateUpdateBenefitHome.benefitSummaries ? stateUpdateBenefitHome.benefitSummaries : []);
	const [isBenefitItemFormChangeWithoutId, setIsBenefitItemFormChangeWithoutId] = useState(false);

	const dispatch = useDispatch();
	const fieldErrorObject = { fieldName: "", errorMessage: "" };

	const generateBenefitItemIdWithSummaryTitle = (benefitSummaryId) => {
		const searchedTitle = stateBenefitItems.find((stateBenefitItem) => stateBenefitItem.id === benefitSummaryId)?.summaryTitle;
		const title = validations.isEmpty(searchedTitle) ? "Loading.." : searchedTitle;
		return (benefitSummaryId + " - " + title);
	};
	
	useEffect(() => {
		setBenefitHomeFormData(stateUpdateBenefitHome);
		setBenefitHomeFormItems(stateUpdateBenefitHome.benefitSummaries?.length > 0 ? stateUpdateBenefitHome.benefitSummaries.map((benefitSummary) => ({
			id: generateBenefitItemIdWithSummaryTitle(benefitSummary.id),
			summaryTitle: benefitSummary.summaryTitle,
			summaryDescription: benefitSummary.summaryDescription
		})) : [{ id: "", summaryTitle: "", summaryDescription: "" }]);
	}, [stateUpdateBenefitHome, stateBenefitItems]);

	//Update Benefit Home
	const updateBenefitHomeFunc = async (getBenefitHomeDataObj) => {
		const payload = { data: getBenefitHomeDataObj };
		dispatch(updateBenefitHome(payload));
	};

	//This function checks required field validations.
	const validateBenefitHomeFormData = () => {
		if (validations.isEmpty(benefitHomeFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_INVALID, true);

		if (validations.isTitle(benefitHomeFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(benefitHomeFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_INVALID, true);

		if (validations.isDescription(benefitHomeFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(benefitHomeFormData.employerLogo) || validations.isIanacareUrl(benefitHomeFormData.employerLogo))
			return getValidationResultObject("employerLogo", properties.COMMON_EMP_LOGO_URL_INVALID, true);

		for (let benefitFormItem of benefitHomeFormItems) {
			if (benefitHomeFormItems.length > 1 || !validations.isEmpty(benefitHomeFormItems[0]?.id)) {
				if (validations.isEmpty(benefitFormItem?.id)) {
					return getValidationResultObject("id", properties.BENEFIT_ITEM_ID_INVALID, true);
				}
				if (validations.isEmpty(benefitFormItem?.summaryTitle)) {
					return getValidationResultObject("summaryTitle", properties.BENEFIT_SUMMARY_TITLE_INVALID, true);
				}
				if (validations.isTitle(benefitFormItem?.summaryTitle)) {
					return getValidationResultObject("summaryTitle", properties.BENEFIT_SUMMARY_TITLE_INVALID_MAX_CHARACTERS_REACHED, true);
				}
				if (validations.isEmpty(benefitFormItem?.summaryDescription)) {
					return getValidationResultObject("summaryDescription", properties.BENEFIT_SUMMARY_DESCRIPTION_INVALID, true);
				}
				if (validations.isDescription(benefitFormItem?.summaryDescription)) {
					return getValidationResultObject("summaryDescription", properties.BENEFIT_SUMMARY_DESCRIPTION_INVALID_MAX_CHARACTERS_REACHED, true);
				}
			}
		}

		if (isBenefitItemFormChangeWithoutId)
			return getValidationResultObject("id", properties.BENEFIT_ITEM_ID_INVALID, true);

		return { fieldErrorObject, validationsEnabled: false };
	};

	//This function calls benefit item form add new file
	let addBenefitItemFormFields = () => {
		setBenefitHomeFormItems([...benefitHomeFormItems, { id: "", summaryTitle: "", summaryDescription: "" }]);
		setIsBenefitItemFormChangeWithoutId(true);
	};

	//This function calls benefit item form remove file
	let removeBenefitItemFormFields = (i) => {
		let newBenefitItems = [...benefitHomeFormItems];
		newBenefitItems.splice(i, 1);
		setBenefitHomeFormItems(newBenefitItems);
		setIsBenefitItemFormChangeWithoutId(false);
	};

	//This function calls benefit item form onchange
	let changeBenefitItemFormField = (i, e) => {
		let newFormValues = [...benefitHomeFormItems];
		if (validations.isEmpty(e.target.name)) {
			let selectedBenefitItem = stateBenefitItems?.find(benefitItem => benefitItem.id === splitByHyphenAndTrim(e.target.innerHTML));
			if (validations.isEmpty(selectedBenefitItem)) {
				newFormValues[i]["id"] = null,
				newFormValues[i]["summaryTitle"] = null;
				newFormValues[i]["summaryDescription"] = null;
			} else {
				newFormValues[i]["id"] = selectedBenefitItem?.id + "-" + selectedBenefitItem?.summaryTitle;
				newFormValues[i]["summaryTitle"] = selectedBenefitItem?.summaryTitle;
				newFormValues[i]["summaryDescription"] = selectedBenefitItem?.summaryDescription;
				setIsBenefitItemFormChangeWithoutId(false);
			}
		} else {
			newFormValues[i][e.target.name] = e.target.value;
			//Check benefit item id fields null or not
			const benefitItemIdsNonEmptyArr = benefitHomeFormItems.filter(benefitItem => !validations.isEmpty(benefitItem.id));
			if (benefitItemIdsNonEmptyArr?.length !== benefitHomeFormItems.length) {
				validations.isEmpty(newFormValues[i][e.target.name]) ? setIsBenefitItemFormChangeWithoutId(false)
					: setIsBenefitItemFormChangeWithoutId(true);
				benefitHomeFormItems.length > 1 && setIsBenefitItemFormChangeWithoutId(true);
			}
		}
		setBenefitHomeFormItems(newFormValues);
	};

	//This function calls when user entering data to input fields and store data in the state after validation
	const onChange = (event) => {
		event.persist();
		let name = event.target.name;
		let val = event.target.value;
		setBenefitHomeFormData({ ...benefitHomeFormData, [name]: val });
	};

	//This function calls when user submit data
	const onSubmit = (event) => {
		if (event) event.preventDefault();
		if (validations.isEmpty(fieldErrorObject.errorMessage) && Object.keys(benefitHomeFormData).length !== 0) {
			//Remove hyphen from id before submission
			benefitHomeFormData.benefitSummaries = validations.isEmpty(benefitHomeFormItems[0].id) ? [] : benefitHomeFormItems.map((benefitHomeFormItem) => ({
				"id": splitByHyphenAndTrim(benefitHomeFormItem.id),
				"summaryDescription": benefitHomeFormItem.summaryDescription,
				"summaryTitle": benefitHomeFormItem.summaryTitle
			}));
			updateBenefitHomeFunc(benefitHomeFormData);
		} else {
			return;
		}
	};

	//Return the object and function
	return { stateEmployers, benefitHomeFormItems, benefitHomeFormData, validateBenefitHomeFormData, addBenefitItemFormFields, changeBenefitItemFormField, removeBenefitItemFormFields, onChange, onSubmit };
};

export default useUpdateBenefitHomeAction;
