export const ADD_BENEFIT_ITEM = "ADD_BENEFIT_ITEM";
export const ADD_BENEFIT_ITEM_SUCCESS = "ADD_BENEFIT_ITEM_SUCCESS";
export const ADD_BENEFIT_ITEM_ERROR = "ADD_BENEFIT_ITEM_ERROR";

export const GET_BENEFIT_ITEM = "GET_BENEFIT_ITEM";
export const GET_BENEFIT_ITEM_SUCCESS = "GET_BENEFIT_ITEM_SUCCESS";
export const GET_BENEFIT_ITEM_ERROR = "GET_BENEFIT_ITEM_ERROR";

export const GET_BENEFIT_ITEM_ALL = "GET_BENEFIT_ITEM_ALL";
export const GET_BENEFIT_ITEM_ALL_SUCCESS = "GET_BENEFIT_ITEM_ALL_SUCCESS";
export const GET_BENEFIT_ITEM_ALL_ERROR = "GET_BENEFIT_ITEM_ALL_ERROR";

export const UPDATE_BENEFIT_ITEM = "UPDATE_BENEFIT_ITEM";
export const UPDATE_BENEFIT_ITEM_SUCCESS = "UPDATE_BENEFIT_ITEM_SUCCESS";
export const UPDATE_BENEFIT_ITEM_ERROR = "UPDATE_BENEFIT_ITEM_ERROR";

export const CACHE_BENEFIT_ITEM_SEARCHED_DATA = "CACHE_BENEFIT_ITEM_SEARCHED_DATA";

export const CLEAR_BENEFIT_ITEM = "CLEAR_BENEFIT_ITEM";
