import React from "react";
import ReactDOM from "react-dom";
import { Amplify } from "aws-amplify";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { CognitoAuthProvider } from "./CognitoAuthProvider";

import App from "./App";
import store, { persistor } from "./store";

const authScopes = process.env.REACT_APP_AWS_AMPLIFY_AUTH_SCOPES;

Amplify.configure({
	Auth: {
		userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
		region: process.env.REACT_APP_AWS_REGION,
		userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
		oauth: {
			domain: process.env.REACT_APP_AWS_AMPLIFY_AUTH_DOMAIN,
			scope: authScopes ? authScopes.split(",") : [],
			redirectSignIn: window.location.origin + "/",
			redirectSignOut: window.location.origin + "/",
			responseType: process.env.REACT_APP_AWS_AMPLIFY_AUTH_RESPONSE_TYPE
		}
	}
});

ReactDOM.render(
	<React.StrictMode>
		<CognitoAuthProvider>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<App />
				</PersistGate>
			</Provider>
		</CognitoAuthProvider>
	</React.StrictMode>,
	document.getElementById("root")
);
