import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../..//lib/enums/httpData";
import * as TYPES from "./types";
import { addNotificationApi } from "../../../services/api/notificationService";

//Action(s)
export const addNotification = (payload, callback) => ({
	type: TYPES.ADD_NOTIFICATION,
	payload: { payload },
	callback: callback
});
const addNotificationSuccess = (payload, callback) => ({
	type: TYPES.ADD_NOTIFICATION_SUCCESS,
	payload: { payload },
	callback: callback
});
const addNotificationError = (error) => ({
	type: TYPES.ADD_NOTIFICATION_ERROR,
	payload: { error }
});

//Saga(s)
export function* addNotificationSaga({ payload, callback }) {
	try {
		const response = yield call(addNotificationApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(addNotificationSuccess(response.payload));
			callback(false);
		}
	} catch (error) {

		yield put(addNotificationError(error.response.payload));
		callback(false);
	}
}
