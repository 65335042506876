import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

import LeftNavigator from "./common/LeftNavigator";
import ExpertArticle from "./expertArticle";
import ExpertVideo from "./expertVideo";
import AddExpertArticle from "./expertArticle/addExpertArticle";
import AddExpertVideo from "./expertVideo/addExpertVideo";
import AuthenticatedLayout from "../common/Layout/AuthenticatedLayout";
import { PAGE } from "../../lib/enums/enum";
import UpdateExpertArticle from "./expertArticle/updateExpertArticle";
import UpdateExpertVideo from "./expertVideo/updateExpertVideo";
import { resetResourcePlanState } from "../../state/employers/resourcePlan/getResourcePlanAll";
import { containsTab } from "../../lib/util/commonUtil";

import "./style.scss";

const Contents = () => {
	const [selectedIndex, setSelectedIndex] = useState();
	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		switch (containsTab()) {
		case "expertArticle":
			dispatch(resetResourcePlanState());
			setSelectedIndex(0);
			break;
		case "expertVideo":
			dispatch(resetResourcePlanState());
			setSelectedIndex(1);
			break;
		case "addExpertArticle":
			setSelectedIndex(2);
			break;
		case "addExpertVideo":
			setSelectedIndex(3);
			break;
		case "updateExpertArticle":
			setSelectedIndex(4);
			break;
		case "updateExpertVideo":
			setSelectedIndex(5);
			break;
		default:
			dispatch(resetResourcePlanState());
			setSelectedIndex(0);
			break;
		}
	}, [location, dispatch]);

	return (
		<AuthenticatedLayout selectedPage={selectedIndex === 0 || selectedIndex === 1 || selectedIndex === 2 || selectedIndex === 3 || selectedIndex === 4 || selectedIndex === 5 ? PAGE.CONTENTS : ""}>
			<Container maxWidth="lg" className="el1260W">
				<Grid container spacing={0} px={0} className="contentSection ian-table-content">
					<Grid item xs={12} sm={5} lg={3} className="ian-position-relative borderRight el20pW">
						<LeftNavigator selectedIndex={selectedIndex}></LeftNavigator>
					</Grid>
					<Grid item xs={12} sm={7} lg={9} className="el80pW">
						{selectedIndex === 0 ? (
							<ExpertArticle />
						) : selectedIndex === 1 ? (
							<ExpertVideo />
						) : selectedIndex === 2 ? (
							<AddExpertArticle />
						) : selectedIndex === 3 ? (
							<AddExpertVideo />
						) : selectedIndex === 4 ? (
							<UpdateExpertArticle />
						) : selectedIndex === 5 ? (
							<UpdateExpertVideo />
						) : null
						}
					</Grid>
				</Grid>
			</Container>
		</AuthenticatedLayout>
	);
};

export default Contents;
