import { call, put } from "redux-saga/effects";

import { SIGNOUT, SIGNOUT_SUCCESS, SIGNOUT_ERROR, RESET_STATE } from "./types";
import { signoutApi } from "../../services/api/userService";
import { HTTP_CODE } from "../../lib/enums/httpData";

//Action(s)
export const signOut = (userId) => ({
	type: SIGNOUT,
	payload: { userId }
});

const signOutSuccess = () => ({
	payload: null,
	type: SIGNOUT_SUCCESS
});

const signOutError = (error) => ({
	type: SIGNOUT_ERROR,
	payload: { error }
});

export const resetState = () => ({
	payload: null,
	type: RESET_STATE
});

//Saga(s)
export function* signoutSaga({ payload }) {
	try {
		const response = yield call(signoutApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(signOutSuccess());
		}
	} catch (error) {
		yield put(signOutError(error));
	}
}
