import React from "react";
import Box from "@mui/material/Box";
import { IconButton, Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

import ErrorIcon from "../../../assets/images/icn-24-alert.svg";
import VerifiedIcon from "../../../assets/images/icn-verified.svg";
import { isEmpty } from "../../../lib/validator";

import "./style.scss";

const TextField = ({
	id,
	type = "text",
	label = "label",
	error = false,
	verified = false,
	autoComplete,
	value,
	name,
	onChange,
	onBlur,
	placeholder,
	sx,
	shouldShowVerified = false,
	verifiedMessage,
	verifyLinkText,
	helperText,
	toolTipText
}) => {

	const toolTipHtml = <div style={{ whiteSpace: "pre-line" }}>{toolTipText?.[0] + " \n " + toolTipText?.[1]}</div>;

	return (
		<Box sx={sx} className="ian-form-group">
			<div className="ian-label-set">
				<label name={name} className={`ian-form-group-label ${!isEmpty(toolTipText) ? "ian-form-group-label-inline-block" : ""}`}>
					{label}
				</label>
				{!isEmpty(toolTipText) && <Tooltip arrow placement="top" className="ian-txt-tooltip" title={toolTipHtml}>
					<IconButton>
						<HelpOutlineIcon size="large" />
					</IconButton>
				</Tooltip>}
			</div>
			<div className="ian-form-group-inputGroup">
				<input
					type={type}
					name={name}
					autoComplete={autoComplete}
					className="ian-inputField"
					placeholder={placeholder}
					value={value}
					id={id}
					onChange={onChange}
					onBlur={onBlur}
				/>
				{error === true ? (
					<img src={ErrorIcon} className="ian-inputField-error" alt="Error icon" />
				) : null}

				{shouldShowVerified === true && error === false ? (
					verified === true ? (
						<img src={VerifiedIcon} className="ian-inputField-error" alt="Verified icon" />
					) : (
						<img src={ErrorIcon} className="ian-inputField-error" alt="Error icon" />
					)

				) : null}

				{shouldShowVerified === true && error === false ? (
					verified === true ? (
						<label className="helperText">{verifiedMessage}</label>
					) : (
						<p className="sendVerificationLink">
							{verifiedMessage} <a href="www.ianacare.com">{verifyLinkText}</a>
						</p>
					)

				) : null}
				<label className="helperText">{helperText}</label>

			</div>
		</Box>
	);
};
export default TextField;
