import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { updateBenefitItemApi } from "../../../services/api/benefitItemService";

//Action(s)
export const updateBenefitItem = (benefitItemData, benefitItemId) => ({
	type: TYPES.UPDATE_BENEFIT_ITEM,
	benefitItemData,
	benefitItemId
});
const updateBenefitItemSuccess = (payload) => ({
	type: TYPES.UPDATE_BENEFIT_ITEM_SUCCESS,
	payload
});
const updateBenefitItemError = (error) => ({
	type: TYPES.UPDATE_BENEFIT_ITEM_ERROR,
	payload: error
});

//Saga(s)
export function* updateBenefitItemSaga({ benefitItemData, benefitItemId }) {
	try {
		const response = yield call(updateBenefitItemApi, benefitItemData, benefitItemId);
		if (response.status === HTTP_CODE.OK) {
			yield put(updateBenefitItemSuccess(response.data));
		}
	} catch (error) {
		yield put(updateBenefitItemError(error.errorMessage));
	}
}
