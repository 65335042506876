import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { addBenefitHomeApi } from "../../../services/api/benefitHomeService";

//Action(s)
export const addBenefitHome = (payload, callback) => ({
	type: TYPES.ADD_BENEFIT_HOME,
	payload: payload,
	callback: callback
});
const addBenefitHomeSuccess = (payload, callback) => ({
	type: TYPES.ADD_BENEFIT_HOME_SUCCESS,
	payload: payload,
	callback: callback
});
const addBenefitHomeError = (error) => ({
	type: TYPES.ADD_BENEFIT_HOME_ERROR,
	payload: { error }
});

//Saga(s)
export function* addBenefitHomeSaga({ payload }) {
	try {
		const response = yield call(addBenefitHomeApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(addBenefitHomeSuccess(response.data));
		}
	} catch (error) {
		yield put(addBenefitHomeError(error));
	}
}
