import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Container } from "@mui/material";

import LeftNavigator from "./common/LeftNavigator";
import Employer from "./employer";
import AddEmployer from "./employer/addEmployer";
import UpdateEmployer from "./employer/updateEmployer";
import ResourcePlan from "./resourcePlan";
import AddResourcePlan from "./resourcePlan/addResourcePlan";
import UpdateResourcePlan from "./resourcePlan/updateResourcePlan";
import AuthenticatedLayout from "../common/Layout/AuthenticatedLayout";
import { containsTab } from "../../lib/util/commonUtil";
import { PAGE } from "../../lib/enums/enum";

import "./style.scss";

const Employers = () => {
	const [selectedIndex, setSelectedIndex] = useState();
	const location = useLocation();

	useEffect(() => {
		switch (containsTab()) {
		case "employers":
			setSelectedIndex(0);
			break;
		case "addEmployer":
			setSelectedIndex(1);
			break;
		case "updateEmployer":
			setSelectedIndex(2);
			break;
		case "resourcePlan":
			setSelectedIndex(3);
			break;
		case "addResourcePlan":
			setSelectedIndex(4);
			break;
		case "updateResourcePlan":
			setSelectedIndex(5);
			break;
		default:
			setSelectedIndex(0);
			break;
		}
	}, [location]);

	return (
		<AuthenticatedLayout selectedPage={selectedIndex === 0 || selectedIndex === 1 || selectedIndex === 2 || selectedIndex === 3 || selectedIndex === 4 || selectedIndex === 5 ? PAGE.EMPLOYERS : ""}>
			<Container maxWidth="lg" className="el1260W">
				<Grid container spacing={0} px={0} className="contentSection ian-table-content">
					<Grid item xs={12} sm={5} lg={3} className="ian-position-relative  borderRight el20pW">
						<LeftNavigator selectedIndex={selectedIndex}></LeftNavigator>
					</Grid>
					<Grid item xs={12} sm={7} lg={9} className="el80pW">
						{selectedIndex === 0 ? (
							<Employer />
						) : selectedIndex === 1 ? (
							<AddEmployer />
						) : selectedIndex === 2 ? (
							<UpdateEmployer />
						) : selectedIndex === 3 ? (
							<ResourcePlan />
						) : selectedIndex === 4 ? (
							<AddResourcePlan />
						) : selectedIndex === 5 ? (
							<UpdateResourcePlan />
						) : null}
					</Grid>
				</Grid>
			</Container>
		</AuthenticatedLayout>
	);
};

export default Employers;
