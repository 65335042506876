export const ADD_RESOURCE_PLAN = "ADD_RESOURCE_PLAN";
export const ADD_RESOURCE_PLAN_SUCCESS = "ADD_RESOURCE_PLAN_SUCCESS";
export const ADD_RESOURCE_PLAN_ERROR = "ADD_RESOURCE_PLAN_ERROR";

export const GET_RESOURCE_PLAN = "GET_RESOURCE_PLAN";
export const GET_RESOURCE_PLAN_SUCCESS = "GET_RESOURCE_PLAN_SUCCESS";
export const GET_RESOURCE_PLAN_ERROR = "GET_RESOURCE_PLAN_ERROR";

export const GET_RESOURCE_PLAN_ALL = "GET_RESOURCE_PLAN_ALL";
export const GET_RESOURCE_PLAN_ALL_SUCCESS = "GET_RESOURCE_PLAN_ALL_SUCCESS";
export const GET_RESOURCE_PLAN_ALL_ERROR = "GET_RESOURCE_PLAN_ALL_ERROR";

export const UPDATE_RESOURCE_PLAN = "UPDATE_RESOURCE_PLAN";
export const UPDATE_RESOURCE_PLAN_SUCCESS = "UPDATE_RESOURCE_PLAN_SUCCESS";
export const UPDATE_RESOURCE_PLAN_ERROR = "UPDATE_RESOURCE_PLAN_ERROR";

export const CACHE_RESOURCE_PLAN_SEARCHED_DATA = "CACHE_RESOURCE_PLAN_SEARCHED_DATA";

export const CLEAR_RESOURCE_PLANS = "CLEAR_RESOURCE_PLANS";
