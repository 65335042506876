import { ADD_CONFIG_DATA_URL } from "./endPoints";
import networkClient from "./networkClient";
import { HTTP_METHOD } from "../../lib/enums/httpData";

export function addConfigDataApi(data) {
	return networkClient().request({
		method: HTTP_METHOD.POST,
		url: ADD_CONFIG_DATA_URL,
		data: data
	});
}

export function updateBenefitItemApi(data) {
	return networkClient().request({
		method: HTTP_METHOD.PUT,
		url: ADD_CONFIG_DATA_URL,
		data: data
	});
}

