import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as validations from "../../../../lib/validator";
import * as properties from "../../../../lib/properties/properties";
import { getValidationResultObject } from "../../../../lib/validator/util";

import { updateExpertArticle } from "../../../../state/expertContents/expertArticle/updateExpertArticle";

const useUpdateExpertArticleAction = () => {

	const stateUpdateExpertArticleItem = useSelector(state => state.expertArticle.expertArticle);
	const stateEmployers = useSelector(state => state.employer.eligibleEmployers);

	const [expertArticleFormData, setExpertArticleFormData] = useState(!validations.isEmpty(stateUpdateExpertArticleItem) ? stateUpdateExpertArticleItem : []);

	const dispatch = useDispatch();
	const fieldErrorObject = { fieldName: "", errorMessage: "" };

	//Update expert article
	const updateExpertArticleAPI = async (expertArticleAPIRequest) => {
		const payload = { data: expertArticleAPIRequest };
		dispatch(updateExpertArticle(payload));
	};

	useEffect(() => { setExpertArticleFormData(!validations.isEmpty(stateUpdateExpertArticleItem) ? stateUpdateExpertArticleItem : []); }, [stateUpdateExpertArticleItem]);

	//This function checks form field validations.
	const validateExpertArticleFormData = () => {
		if (validations.isEmpty(expertArticleFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_INVALID, true);

		if (validations.isTitle(expertArticleFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(expertArticleFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_INVALID, true);

		if (validations.isDescription(expertArticleFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(expertArticleFormData.content))
			return getValidationResultObject("content", properties.CONTENT_EXPE_CONTENT_INVALID, true);

		if (validations.isEmpty(expertArticleFormData.previewImage))
			return getValidationResultObject("previewImage", properties.CONTENT_EXPE_PREVIEW_IMAGE_URL_INVALID, true);

		if (validations.isIanacareUrl(expertArticleFormData.previewImage))
			return getValidationResultObject("previewImage", properties.CONTENT_EXPE_PREVIEW_IMAGE_URL_INVALID, true);

		if (validations.isEmpty(expertArticleFormData.previewTitle))
			return getValidationResultObject("previewTitle", properties.CONTENT_EXPE_PREVIEW_TITLE_INVALID, true);

		if (validations.isTitle(expertArticleFormData.previewTitle))
			return getValidationResultObject("previewTitle", properties.COMMON_PREVIEW_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

		return { fieldErrorObject, validationsEnabled: false };
	};

	//This function calls when user entering data to input fields and store data in the state after validation.
	const onChange = (event) => {
		event.persist();
		const name = event.target.name;
		const val = event.target.value;
		setExpertArticleFormData({ ...expertArticleFormData, [name]: val });
	};

	//This function calls when user submit data.
	const onSubmit = (event) => {
		if (event) event.preventDefault();
		if (validations.isEmpty(fieldErrorObject.errorMessage) && Object.keys(expertArticleFormData).length !== 0) {
			updateExpertArticleAPI(expertArticleFormData);
		} else {
			return;
		}
	};

	return { stateEmployers, expertArticleFormData, validateExpertArticleFormData, onChange, onSubmit };

};

export default useUpdateExpertArticleAction;
