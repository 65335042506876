import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { updatePillarApi } from "../../../services/api/pillarService";

//Action(s)
export const updatePillar = (payload) => ({
	type: TYPES.UPDATE_PILLAR,
	payload: payload
});
const updatePillarSuccess = (payload) => ({
	type: TYPES.UPDATE_PILLAR_SUCCESS,
	payload: payload
});
const updatePillarError = (error) => ({
	type: TYPES.UPDATE_PILLAR_ERROR,
	payload: { error }
});

//Saga(s)
export function* updatePillarSaga({ payload }) {
	try {
		const response = yield call(updatePillarApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(updatePillarSuccess(response.data));
		}
	} catch (error) {
		yield put(updatePillarError(error));
	}
}
