import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@mui/material";

import SelectField from "../../common/SelectField";
import DataTable from "../../common/DataTable";
import LabelButton from "../../common/Button/LabelButton";
import * as properties from "../../../lib/properties/properties";
import { isEmpty } from "../../../lib/validator";
import { NO_PAGE_LIMIT, NO_PAGE_COUNT, DEFAULT_PAGE_LIMIT } from "../../../lib/constants";
import { RESOURCE_TYPE } from "../../../lib/enums/resourceType";
import { getExpertArticleAll, cacheExpertArticleSearchedData, resetExpertArticleState } from "../../../state/expertContents/expertArticle/getExpertArticleAll";
import { getEmployerAll } from "../../../state/employers/employer/getEmployerAll";
import useExpertArticleAction from "./useExpertArticleAction";

const ExpertArticle = () => {

	const stateExpertArticle = useSelector(state => state.expertArticle);

	const dispatch = useDispatch();
	//Expert article table header items
	const expertArticleHeaderTitles = ["Id", "Resource Plan", "Resource Type", "Title", "Action"];

	//Custom hook for this form action
	const { stateEmployer, expertArticleSearchedEmployer, isValidData, onClickSearch, onChange, onClickAddForm } = useExpertArticleAction();

	const callback = useCallback((page) => {
		if (!isEmpty(page)) {
			getExpertArticleData(page, expertArticleSearchedEmployer.employerId);
		}
	}, [expertArticleSearchedEmployer]);

	const getEmployerData = async () => {
		const payload = { pagelimit: NO_PAGE_LIMIT, pagenumber: NO_PAGE_COUNT };
		dispatch(getEmployerAll(payload));
	};

	const getExpertArticleData = async (page, employerId) => {
		const payload = { employerId, pagelimit: DEFAULT_PAGE_LIMIT, pagenumber: page };
		dispatch(getExpertArticleAll(payload));
		//set the previous Search results
		dispatch(cacheExpertArticleSearchedData({ employerId: employerId, pagenumber: page }));
	};

	useEffect(() => {
		dispatch(resetExpertArticleState());
		stateEmployer.isEmployerChanged ? getEmployerData() : "";
		!isEmpty(stateExpertArticle?.search?.employerId) && !isEmpty(document.getElementById("employerId")?.value) ?
			getExpertArticleData(stateExpertArticle.search?.pagenumber, stateExpertArticle.search?.employerId) : null;
	}, [stateEmployer.eligibleEmployers]);

	//Expert article Table Body Items
	const expertArticleTableData = !isEmpty(stateExpertArticle.expertArticles) && !isEmpty(expertArticleSearchedEmployer.employerId)
		&& !isEmpty(stateEmployer.eligibleEmployers) && !stateEmployer.loading && !isEmpty(document.getElementById("employerId")?.value) ?
		stateExpertArticle.expertArticles.map((
			{
				id,
				employer,
				resourcePlan,
				resourceType,
				title
			}) => ({
			value0: id,
			value1: employer,
			value2: resourcePlan?.employerResourcePlanName,
			value3: RESOURCE_TYPE[resourceType],
			value4: title
		})) : [];

	return (
		<div className="tabSectionRight ian-position-relative">
			<div className="ian-centeredSection">
				<div className="ian-form-container ian-position-relative">
					<Grid container spacing={0} px={0} >
						<Grid item md={6} xs={12}>
							<SelectField
								label={properties.COMMON_EMPLOYER_SEARCH_LABEL}
								name="employerId"
								id="employerId"
								options={stateEmployer.eligibleEmployers}
								value={expertArticleSearchedEmployer.employerId}
								onChange={onChange}
								selectOption={properties.COMMON_SELECT_OPTION_EMPLOYER_LABEL}
							/>
						</Grid>
						<Grid item md={3} xs={12}>
							<div className="searchBtn-wthSelect">
								<LabelButton color="inherit" label={properties.COMMON_SEARCH_LABEL} type="submit" disabled={!isValidData() || isEmpty(document.getElementById("employerId")?.value)} showProgress={false} onClick={onClickSearch} />
							</div>
						</Grid>
						<Grid item md={3} xs={12}>
							<div className="add-button">
								<LabelButton color="inherit" label={properties.COMMON_ADD_NEW_LABEL} type="submit" disabled={stateExpertArticle.loading} showProgress={false} onClick={onClickAddForm} />
							</div>
						</Grid>
					</Grid>
					<DataTable
						Data={expertArticleTableData}
						headerTitle={expertArticleHeaderTitles}
						updateForm={"/contents?tab=updateExpertArticle"}
						dataLoad={stateExpertArticle.loading}
						parentCallback={callback}
						pageData={stateExpertArticle.pageData}
						paginationActive={true}
						noDataLabel={properties.COMMON_NO_DATA_SELECT_AND_SEARCH_LABEL} />
				</div>
			</div>
		</div>
	);
};

export default ExpertArticle;
