export const ADD_BENEFIT_HOME = "ADD_BENEFIT_HOME";
export const ADD_BENEFIT_HOME_SUCCESS = "ADD_BENEFIT_HOME_SUCCESS";
export const ADD_BENEFIT_HOME_ERROR = "ADD_BENEFIT_HOME_ERROR";

export const  GET_BENEFIT_HOME = "GET_BENEFIT_HOME";
export const  GET_BENEFIT_HOME_SUCCESS = "GET_BENEFIT_HOME_SUCCESS";
export const  GET_BENEFIT_HOME_ERROR = "GET_BENEFIT_HOME_ERROR";

export const  GET_BENEFIT_HOME_ALL = "GET_BENEFIT_HOME_ALL";
export const  GET_BENEFIT_HOME_ALL_SUCCESS = "GET_BENEFIT_HOME_ALL_SUCCESS";
export const  GET_BENEFIT_HOME_ALL_ERROR = "GET_BENEFIT_HOME_ALL_ERROR";

export const UPDATE_BENEFIT_HOME = "UPDATE_BENEFIT_HOME";
export const UPDATE_BENEFIT_HOME_SUCCESS = "UPDATE_BENEFIT_HOME_SUCCESS";
export const UPDATE_BENEFIT_HOME_ERROR = "UPDATE_BENEFIT_HOME_ERROR";

export const CACHE_BENEFIT_HOME_SEARCHED_DATA = "CACHE_BENEFIT_HOME_SEARCHED_DATA";

export const CLEAR_BENEFIT_HOME = "CLEAR_BENEFIT_HOME";
