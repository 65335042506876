import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { getPillarApi } from "../../../services/api/pillarService";

//Action(s)
export const getPillar = (payload) => ({
	type: TYPES.GET_PILLAR,
	payload: payload
});
const getPillarSuccess = (payload) => ({
	type: TYPES.GET_PILLAR_SUCCESS,
	payload: payload
});
const getPillarError = (error) => ({
	type: TYPES.GET_PILLAR_ERROR,
	payload: { error }
});

//Saga(s)
export function* getPillarSaga({ payload }) {
	try {
		const response = yield call(getPillarApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(getPillarSuccess(response.data));
		}
	} catch (error) {
		yield put(getPillarError(error));
	}
}
