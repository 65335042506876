import { call, put } from "redux-saga/effects";
import * as TYPES from "./types";

import { updateEmployerApi } from "../../../services/api/employerService";
import { HTTP_CODE } from "../../../lib/enums/httpData";

//Action(s)
export const updateEmployers = (payload) => ({
	type: TYPES.UPDATE_EMPLOYER,
	payload: payload
});
const updateEmployersSuccess = (payload) => ({
	type: TYPES.UPDATE_EMPLOYER_SUCCESS,
	payload: payload
});
const updateEmployersError = (error) => ({
	type: TYPES.UPDATE_EMPLOYER_ERROR,
	payload: error
});

//Saga(s)
export function* updateEmployerSaga({ payload }) {
	try {
		const response = yield call(updateEmployerApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(updateEmployersSuccess(response.data));
		}
	} catch (error) {
		yield put(updateEmployersError(error));
	}
}

