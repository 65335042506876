import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import { textAreaTextCounter } from "../../../lib/util/commonUtil";

import "./style.scss";

const TextArea = ({
	id,
	type = "text",
	label = "label",
	error = false,
	value,
	name,
	onChange,
	onBlur,
	placeholder,
	sx,
	txtCounterEnabled = true,
	helperText,
	characterCount = 0
}) => {
	const [typedCount, setCount] = React.useState(0);
	
	useEffect(() => {
		txtCounterEnabled && setCount(characterCount);
	}, [characterCount, txtCounterEnabled]);

	return (
		<Box sx={sx} className="ian-form-group">
			<Grid container direction="row">
				<label name={name} className="form-group-label-text-area">
					{label}
				</label>
				<label name={name} className={error ? "form-group-count-label_error" : "form-group-count-label"}>
					{textAreaTextCounter(txtCounterEnabled, typedCount)}
				</label>
			</Grid>
			<div className="ian-form-group-inputGroup">
				<div className="pull-tab"></div>
				<textarea
					type={type}
					name={name}
					rows={2}
					className="ian-inputField ian-textarea"
					placeholder={placeholder}
					value={value}
					id={id}
					onBlur={onBlur}
					onChange={(e) => {
						onChange(e);
						setCount(e.target.value.length);
					}}
				/>
				<label className="helperText">{helperText}</label>
			</div>
		</Box>
	);
};
export default TextArea;
