import React from "react";

import TextField from "../../common/TextField";
import PrimaryButton from "../../common/Button/PrimaryButton";
import * as properties from "../../../lib/properties/properties";
import NotificationAction from "./useNotificationAction";

const Notification = () => {

	/**
   * Custom hook for data submitting
   */
	const { onChange, errors, onSubmit } = NotificationAction();

	/**
   * TODO: Disable submit button when input fields is null
   */
	//let disabled = !validations.isEmpty(!notificationData) ? false : true;
	let disabled = true;

	return (
		<div className="ian-centeredSection ian-w-40">
			<div className="ian-form-container">
				<TextField
					label={properties.CONFIG_LABEL_ALERT1}
					placeholder="Alert 1"
					name="alert1"
					value={"Alert 1"}
					id="alert1"
					type="text"
					error={errors.alert1 && true}
					onChange={onChange}
				/>
				<TextField
					label={properties.CONFIG_LABEL_ALERT2}
					placeholder="Alert 2"
					name="alert2"
					value={"Alert 2"}
					id="alert2"
					type="text"
					error={errors.alert2 && true}
					onChange={onChange}
				/>
				<TextField
					label={properties.CONFIG_LABEL_ALERT3}
					placeholder="Alert 3"
					name="alert3"
					value={"Alert 3"}
					id="alert3"
					type="text"
					error={errors.alert3 && true}
					onChange={onChange}
				/>
				{Object.keys(errors).length !== 0 ? (
					<div className="ian-error-warning show" sx={{ display: "none" }}>
						<p>
							{" "}
							{errors.alert2}
						</p>
					</div>
				) : (
					<PrimaryButton
						className="px-5"
						label={properties.COMMON_COMING_SOON_LABEL}
						disabled={disabled}
						showProgress={false}
						onClick={onSubmit}
					/>
				)}
			</div>
		</div>
	);
};

export default Notification;
