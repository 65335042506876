import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as properties from "../../../../lib/properties/properties";
import * as validations from "../../../../lib/validator";
import { getValidationResultObject } from "../../../../lib/validator/util";

import { addResourcePlan } from "../../../../state/employers/resourcePlan/addResourcePlan";
import { resetResourcePlanState } from "../../../../state/employers/resourcePlan/getResourcePlanAll";

const useAddResourcePlanAction = () => {

	const stateEmployers = useSelector(state => state.employer.eligibleEmployers);

	const [resourcePlanFormData, setResourcePlanFormData] = useState({});
	const [resourcePlanFormValidationsEnabled, setResourcePlanFormValidationsEnabled] = useState(false);

	const dispatch = useDispatch();
	const fieldErrorObject = { fieldName: "", errorMessage: "" };

	//Get clear resource plan state
	useEffect(() => { dispatch(resetResourcePlanState()); }, []);

	//create Resource plan
	const createResourcePlan = async (resourcePlanFormAPIRequest) => {
		dispatch(addResourcePlan(resourcePlanFormAPIRequest));
	};

	//This function checks field validations.
	const validateResourcePlanFormData = () => {
		if (validations.isEmpty(resourcePlanFormData.employerId))
			return getValidationResultObject("employerId", properties.COMMON_EMPLOYER_INVALID, resourcePlanFormValidationsEnabled);

		if (validations.isEmpty(resourcePlanFormData.employerEmployeeCategory))
			return getValidationResultObject("employerEmployeeCategory", properties.RESOURCE_PLAN_EMP_CATEGORY_INVALID, true);

		if (validations.isEmpty(resourcePlanFormData.name))
			return getValidationResultObject("name", properties.RESOURCE_PLAN_NAME_INVALID, true);

		if (validations.isTitle(resourcePlanFormData.name))
			return getValidationResultObject("name", properties.RESOURCE_PLAN_NAME_MAX_CHARACTERS_REACHED_INVALID, true);

		if (!validations.isEmpty(resourcePlanFormData.description) && validations.isDescription(resourcePlanFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_MAX_CHARACTERS_REACHED_INVALID, true);

		return { fieldErrorObject, validationsEnabled: false };
	};


	//This function calls when user entering data to input fields and store data in the state after validation.
	const onChange = (event) => {
		event.persist();
		const name = event.target.name;
		const val = event.target.value;
		if (name !== "employerId" || validations.isEmpty(val)) {
			//set resourcePlanFormValidationsEnabled to true when employer id field not change or select invalid value
			setResourcePlanFormValidationsEnabled(true);
		}
		setResourcePlanFormData({ ...resourcePlanFormData, [name]: val });
	};

	//This function calls when user submit data.
	const onSubmit = (event) => {
		if (event) event.preventDefault();
		if (validations.isEmpty(fieldErrorObject.errorMessage) && Object.keys(resourcePlanFormData).length !== 0) {
			createResourcePlan(resourcePlanFormData);
		} else {
			return;
		}
	};

	return { stateEmployers, resourcePlanFormData, validateResourcePlanFormData, onChange, onSubmit };
};

export default useAddResourcePlanAction;
