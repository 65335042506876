import { getDomainUrlArray } from "../util/commonUtil";
import isUrl from "./is-url";

const isIanacareUrl = value => {
	if (!isUrl(value)) {
		return getDomainUrlArray().findIndex((domain) => value.startsWith(domain)) >= 0 ? false : true;
	} else {
		return true;
	}
};
export default isIanacareUrl;
