import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import LandingPage from "./components/home";
import { AuthRoute, PrivateRoute } from "./routes";
import General from "./components/general";
import Benefits from "./components/benefits";
import Contents from "./components/expertContents";
import Pillars from "./components/pillars";
import Employers from "./components/employers";
import { PAGE } from "./lib/enums/enum";

function App() {
	const theme = createTheme({
		typography: {
			allVariants: {
				fontFamily: "Trueno Regular"
			}
		}
	});
	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Switch>
					<AuthRoute exact path="/" component={LandingPage} />
					<PrivateRoute exact path={"/" + PAGE.GENERAL} component={General} />
					<PrivateRoute exact path={"/" + PAGE.BENEFITS} component={Benefits} />
					<PrivateRoute exact path={"/" + PAGE.CONTENTS} component={Contents} />
					<PrivateRoute exact path={"/" + PAGE.PILLARS} component={Pillars} />
					<PrivateRoute exact path={"/" + PAGE.EMPLOYER} component={Employers} />
				</Switch>
			</Router>
		</ThemeProvider>
	);
}

export default App;
