import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as properties from "../../../../lib/properties/properties";
import * as validations from "../../../../lib/validator";
import { getValidationResultObject } from "../../../../lib/validator/util";
import { addBenefitItem, resetBenefitItemState } from "../../../../state/benefits/benefitItem/addBenefitItem";
import { getResourcePlanAll } from "../../../../state/employers/resourcePlan/getResourcePlanAll";

const useAddBenefitItemAction = () => {

	const stateEmployers = useSelector(state => state.employer?.eligibleEmployers);

	const [benefitItemFormData, setBenefitItemFormData] = useState({});
	const [benefitItemListFormField, setBenefitItemListFormField] = useState([{ bodyItemList: "" }]);
	const [benefitItemCheckListFormField, setBenefitItemCheckListFormField] = useState([{ bodyCheckList: "" }]);
	const [benefitItemValidationsEnabled, setBenefitItemValidationsEnabled] = useState(false);

	const dispatch = useDispatch();
	const fieldErrorObject = { fieldName: "", errorMessage: "" };

	//Get cleared benefit item state
	useEffect(() => { dispatch(resetBenefitItemState()); }, []);

	//Get Resource plane
	const loadApiData = async (value) => {
		const payload = { employerId: value };
		dispatch(getResourcePlanAll(payload));
	};

	//This function checks required field validations.
	const validateBenefitItemFormData = () => {
		if (validations.isEmpty(benefitItemFormData.employerId))
			return getValidationResultObject("employerId", properties.COMMON_EMPLOYER_INVALID, benefitItemValidationsEnabled);

		if (validations.isEmpty(benefitItemFormData.employerResourcePlanId))
			return getValidationResultObject("employerResourcePlanId", properties.COMMON_RESOURCE_PLAN_INVALID, true);

		if (validations.isEmpty(benefitItemFormData.resourceType))
			return getValidationResultObject("resourceType", properties.COMMON_RESOURCE_TYPE_INVALID, true);

		if (validations.isEmpty(benefitItemFormData.summaryTitle))
			return getValidationResultObject("summaryTitle", properties.BENEFIT_SUMMARY_TITLE_INVALID, true);

		if (validations.isTitle(benefitItemFormData.summaryTitle))
			return getValidationResultObject("summaryTitle", properties.BENEFIT_SUMMARY_TITLE_INVALID_MAX_CHARACTERS_REACHED, true);

		if (validations.isEmpty(benefitItemFormData.summaryDescription))
			return getValidationResultObject("summaryDescription", properties.BENEFIT_SUMMARY_DESCRIPTION_INVALID, true);

		if (validations.isDescription(benefitItemFormData.summaryDescription))
			return getValidationResultObject("summaryDescription", properties.BENEFIT_SUMMARY_DESCRIPTION_INVALID_MAX_CHARACTERS_REACHED, true);

		if (validations.isEmpty(benefitItemFormData.employerLogo) || validations.isIanacareUrl(benefitItemFormData.employerLogo))
			return getValidationResultObject("employerLogo", properties.COMMON_EMP_LOGO_URL_INVALID, true);

		if (validations.isEmpty(benefitItemFormData.bodyTitle))
			return getValidationResultObject("bodyTitle", properties.BENEFIT_BODY_TITLE_INVALID, true);

		if (validations.isTitle(benefitItemFormData.bodyTitle))
			return getValidationResultObject("bodyTitle", properties.BENEFIT_BODY_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

		if (benefitItemCheckListFormField.length > 1) {
			if (benefitItemCheckListFormField.filter((bodyCheckListItem) => !validations.isEmpty(bodyCheckListItem.bodyCheckList)).length !== benefitItemCheckListFormField.length) {
				return getValidationResultObject("bodyCheckList", properties.BENEFIT_BODY_CHECK_LIST_INVALID, true);
			}
		}

		if (benefitItemCheckListFormField.length > 0) {
			if (benefitItemListFormField.filter((bodyItem) => !validations.isEmpty(bodyItem.bodyItemList)).length !== benefitItemListFormField.length) {
				return getValidationResultObject("bodyCheckList", properties.BENEFIT_BODY_ITEM_LIST_INVALID, true);
			}
		}

		if (validations.isEmpty(benefitItemFormData.footerTitle))
			return getValidationResultObject("footerTitle", properties.BENEFIT_FOOTER_TITLE_INVALID, true);

		if (validations.isTitle(benefitItemFormData.footerTitle))
			return getValidationResultObject("footerTitle", properties.BENEFIT_FOOTER_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(benefitItemFormData.footerDescription))
			return getValidationResultObject("footerDescription", properties.BENEFIT_FOOTER_DESCRIPTION_INVALID, true);

		if (validations.isDescription(benefitItemFormData.footerDescription))
			return getValidationResultObject("footerDescription", properties.BENEFIT_FOOTER_DESCRIPTION_MAX_CHARACTERS_REACHED_INVALID, true);

		if (!validations.isEmpty(benefitItemFormData.footerPhone) && validations.isPhoneNumber(benefitItemFormData.footerPhone))
			return getValidationResultObject("footerPhone", properties.BENEFIT_FOOTER_PHONE_INVALID, true);

		if (!validations.isEmpty(benefitItemFormData.footerURL) && validations.isUrl(benefitItemFormData.footerURL))
			return getValidationResultObject("footerURL", properties.BENEFIT_FOOTER_URL_INVALID, true);

		return { fieldErrorObject, validationsEnabled: false };
	};

	//This function calls body check list add new file
	let addCheckListFormField = () => {
		setBenefitItemCheckListFormField([...benefitItemCheckListFormField, { bodyCheckList: "" }]);
	};

	//This function calls body check list remove file
	let removeCheckListFormField = (i) => {
		let newBodyCheckList = [...benefitItemCheckListFormField];
		newBodyCheckList.splice(i, 1);
		setBenefitItemCheckListFormField(newBodyCheckList);
	};

	//This function calls body check list onchange
	let changeCheckListFormFileld = (i, e) => {
		let newBodyCheckList = [...benefitItemCheckListFormField];
		newBodyCheckList[i][e.target.name] = e.target.value;
		setBenefitItemCheckListFormField(newBodyCheckList);
		setBenefitItemFormData({ ...benefitItemFormData, "descriptionCheckList": newBodyCheckList });
	};

	//This function calls body item list add new file
	let addItemListFormField = () => {
		setBenefitItemListFormField([...benefitItemListFormField, { bodyItemList: "" }]);
	};

	//This function calls body item list remove file
	let removeItemListFormField = (i) => {
		let newBodyItemList = [...benefitItemListFormField];
		newBodyItemList.splice(i, 1);
		setBenefitItemListFormField(newBodyItemList);
	};

	//This function calls body item list onchange
	let changItemListFormField = (i, e) => {
		let newBodyItemList = [...benefitItemListFormField];
		newBodyItemList[i][e.target.name] = e.target.value;
		setBenefitItemListFormField(newBodyItemList);
		setBenefitItemFormData({ ...benefitItemFormData, "descriptionItemList": newBodyItemList });
	};

	//This function calls when user entering data to input fields and store data in the state after validation
	const onChange = (event) => {
		event.persist();
		let name = event.target.name;
		let val = event.target.value;
		if (name !== "employerId") {
			setBenefitItemValidationsEnabled(true); //set validationsEnabled to true when employer id field not change 
		} else {
			if (validations.isEmpty(val)) {
				setBenefitItemValidationsEnabled(true); //set validationsEnabled to true when employer id change with invalid val 
			} else {
				loadApiData(val);
				benefitItemFormData.employerResourcePlanId = "";
			}
		}
		setBenefitItemFormData({ ...benefitItemFormData, [name]: val });
	};

	//This function calls when user submit data
	const onSubmit = async (event) => {
		let benefitItemObj = {};
		if (event) event.preventDefault();

		//check list function update
		let createDescriptionCheckList = [];
		if (validations.isEmpty(benefitItemFormData.descriptionCheckList?.[0].bodyCheckList)) {
			createDescriptionCheckList = [];
		} else if (!validations.isEmpty(benefitItemFormData.descriptionCheckList)) {
			createDescriptionCheckList = benefitItemFormData.descriptionCheckList.map((item) => item.bodyCheckList);
		}
		if (validations.isEmpty(fieldErrorObject.errorMessage) && Object.keys(benefitItemFormData).length !== 0) {
			benefitItemObj.employerId = benefitItemFormData.employerId,
			benefitItemObj.employerResourcePlanId = benefitItemFormData.employerResourcePlanId,
			benefitItemObj.resourceType = benefitItemFormData.resourceType,
			benefitItemObj.employerLogo = benefitItemFormData.employerLogo,
			benefitItemObj.summaryTitle = benefitItemFormData.summaryTitle,
			benefitItemObj.summaryDescription = benefitItemFormData.summaryDescription,
			benefitItemObj.body = {
				title: benefitItemFormData.bodyTitle,
				descriptionItemList: benefitItemFormData.descriptionItemList.map((item) => item.bodyItemList),
				descriptionCheckList: createDescriptionCheckList
			},
			benefitItemObj.footer = {
				title: benefitItemFormData.footerTitle,
				description: benefitItemFormData.footerDescription,
				phone: benefitItemFormData.footerPhone,
				url: benefitItemFormData.footerURL,
				buttonText: benefitItemFormData.footerButtonText
			};
			dispatch(addBenefitItem(benefitItemObj));
		} else {
			return;
		}
	};

	//Return object and function
	return {
		stateEmployers, benefitItemListFormField, benefitItemCheckListFormField, benefitItemFormData, validateBenefitItemFormData, addItemListFormField, removeItemListFormField,
		changItemListFormField, addCheckListFormField, removeCheckListFormField, changeCheckListFormFileld, onChange, onSubmit
	};
};

export default useAddBenefitItemAction;
