import { call, put } from "redux-saga/effects";
import * as TYPES from "./types";

import { getEmployerAllApi } from "../../../services/api/employerService";
import { HTTP_CODE } from "../../../lib/enums/httpData";

//Action(s)
export const getPaginatedEmployers = (payload) => ({
	type: TYPES.GET_PAGINATED_EMPLOYERS,
	payload: payload
});
const getPaginatedEmployersSuccess = (payload) => ({
	payload: payload,
	type: TYPES.GET_PAGINATED_EMPLOYERS_SUCCESS
});
const getPaginatedEmployersError = (error) => ({
	type: TYPES.GET_PAGINATED_EMPLOYERS_ERROR,
	payload: error
});

//Saga(s)
export function* getPaginatedEmployersSaga({ payload }) {
	try {
		const response = yield call(getEmployerAllApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(getPaginatedEmployersSuccess(response.data));
		}
	} catch (error) {
		yield put(getPaginatedEmployersError(error));
	}
}

