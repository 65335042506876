import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@mui/material";

import TextField from "../../common/TextField";
import LabelButton from "../../common/Button/LabelButton";
import DataTable from "../../common/DataTable";
import * as properties from "../../../lib/properties/properties";
import { cacheEmployerSearchedData } from "../../../state/employers/employer/getEmployerAll";
import { getPaginatedEmployers } from "../../../state/employers/employer/getPaginatedEmployers";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_LIMIT } from "../../../lib/constants";
import { isEmpty } from "../../../lib/validator";
import useEmployerAction from "./useEmployerAction";

const Employer = () => {

	const stateEmployer = useSelector(state => state.employer);
	const stateEmployerSearchClicked = stateEmployer.employerSearchClicked;

	const dispatch = useDispatch();

	useEffect(() => { getEmployerData(stateEmployer.search?.pagenumber || DEFAULT_PAGE_NUMBER); }, []);

	const getEmployerData = async (pagenumber) => {
		const employer = stateEmployerSearchClicked ? employerSearchedEmployer?.employer : "";
		const payload = {
			employer: employer?.trim(),
			pagelimit: DEFAULT_PAGE_LIMIT, pagenumber: pagenumber
		};
		dispatch(getPaginatedEmployers(payload));
		dispatch(cacheEmployerSearchedData({ employer, pagenumber }));
	};

	//Custom hook for this form action
	const { employerSearchedEmployer, isValidData, onClickAddForm, onClickSearch, onChange } = useEmployerAction();

	//Employers table titles
	const employerTableTitle = ["Id", "Name", "Platform", "Resource Enabled", "Action"];

	//Employers table body data
	const employerTableData = stateEmployer.paginatedEmployers && !stateEmployer.loading ? stateEmployer.paginatedEmployers.map((
		{
			id,
			name,
			platform,
			resourceEnabled
		}) => ({
		value0: id,
		value1: name,
		value2: name,
		value3: platform,
		value4: resourceEnabled ? "Yes" : "No"
	})) : [];

	const employerTableCallback = (page) => {
		if (!isEmpty(page)) {
			getEmployerData(page);
		}
	};

	return (
		<div className="tabSectionRight ian-position-relative">
			<div className="ian-centeredSection">
				<div className="ian-form-container ian-position-relative">
					<Grid container spacing={0} px={0} >
						<Grid item md={6} xs={12}>
							<TextField
								label={properties.EMPLOYER_SEARCH_LABEL}
								placeholder={properties.EMPLOYER_SEARCH_PLASHEHOLDER}
								name="employer"
								id="employer"
								value={employerSearchedEmployer.employer}
								onChange={onChange}
							/>
						</Grid>
						<Grid item md={3} xs={12}>
							<div className="searchBtn-wthSelect">
								<LabelButton color="inherit" label="Search" type="submit" disabled={!isValidData()} showProgress={false} onClick={onClickSearch} />
							</div>
						</Grid>
						<Grid item md={3} xs={12}>
							<div className="add-button">
								<LabelButton color="inherit" label="Add New" type="submit" showProgress={false} disabled={stateEmployer.loading} onClick={onClickAddForm} />
							</div>
						</Grid>
					</Grid>
					<DataTable
						Data={employerTableData}
						headerTitle={employerTableTitle}
						updateForm={"/employers?tab=updateEmployer"}
						isEmployerTableView={true}
						parentCallback={employerTableCallback}
						pageData={stateEmployer.pageData}
						paginationActive={true}
						dataLoad={stateEmployer.loading}
						noDataLabel={properties.EMPLOYER_NO_DATA_ENTER_AND_SEARCH_LABEL} />
				</div>
			</div>
		</div>
	);
};

export default Employer;
