import * as TYPES from "./types";

const initialState = {
	resourcePlan: {},
	resourcePlans: [],
	resourcePlanOptions: [],
	error: null
};

const resourcePlansReducer = (state = initialState, action) => {
	switch (action.type) {
	case TYPES.GET_RESOURCE_PLAN_ALL:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.GET_RESOURCE_PLAN_ALL_SUCCESS:
		//resource Plan sort alphabetically
		action.payload.resourcePlan.sort((a, b) => a.name.localeCompare(b.name));
		return {
			...state,
			loading: false,
			resourcePlans: action.payload.resourcePlan,
			//set resource plans to Select components format
			resourcePlanOptions: action.payload.resourcePlan.map((item) => ({ value: item.id, label: item.name })),
			error: null
		};
	case TYPES.GET_RESOURCE_PLAN_ALL_ERROR:
		return {
			...state,
			error: null
		};
	case TYPES.GET_RESOURCE_PLAN:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.GET_RESOURCE_PLAN_SUCCESS:
		return {
			...state,
			loading: false,
			resourcePlan: action.payload,
			error: null
		};
	case TYPES.GET_RESOURCE_PLAN_ERROR:
		return {
			...state,
			loading: false,
			resourcePlan: "",
			error: action.payload.errorMessage
		};
	case TYPES.ADD_RESOURCE_PLAN:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.ADD_RESOURCE_PLAN_SUCCESS:
		return {
			...state,
			loading: false,
			success: true,
			resourcePlan: action.payload,
			error: null
		};
	case TYPES.ADD_RESOURCE_PLAN_ERROR:
		return {
			...state,
			loading: false,
			success: false,
			resourcePlan: "",
			error: action.payload.errorMessage
		};
	case TYPES.UPDATE_RESOURCE_PLAN:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.UPDATE_RESOURCE_PLAN_SUCCESS:
		return {
			...state,
			loading: false,
			resourcePlan: action.payload,
			success: true,
			error: null
		};
	case TYPES.UPDATE_RESOURCE_PLAN_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload.errorMessage
		};
	case TYPES.CLEAR_RESOURCE_PLANS:
		return {
			...state,
			loading: false,
			success: false,
			resourcePlans: null,
			resourcePlanOptions: null,
			error: null
		};
	case TYPES.CACHE_RESOURCE_PLAN_SEARCHED_DATA:
		return {
			...state,
			search: action.payload
		};
	default:
		return state;
	}
};
export default resourcePlansReducer;
