import { SIGN_OUT_URL } from "./endPoints";
import networkClient from "./networkClient";
import { HTTP_METHOD } from "../../lib/enums/httpData";

export function signoutApi(userId) {
	return networkClient().request({
		method: HTTP_METHOD.POST,
		url: SIGN_OUT_URL,
		data: userId
	});
}






