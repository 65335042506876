import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { getValidationResultObject } from "../../../../lib/validator/util";
import * as properties from "../../../../lib/properties/properties";
import * as validations from "../../../../lib/validator";
import { updateBenefitItem } from "../../../../state/benefits/benefitItem/updateBenefitItem";

const useUpdateBenefitItemAction = () => {

	const stateUpdateBebefitItem = useSelector(state => state.benefitItem.benefitItem);
	const stateEmployers = useSelector(state => state.employer.eligibleEmployers);

	const [benefitItemListFormField, setBenefitItemListFormField] = useState({});
	const [benefitItemCheckListFormField, setBenefitItemCheckListFormField] = useState({});
	const [benefitItemFormData, setBenefitItemFormData] = useState(stateUpdateBebefitItem);
	const [benefitItems, setBenefitItem] = useState([]);

	const dispatch = useDispatch();
	const fieldErrorObject = { fieldName: "", errorMessage: "" };

	useEffect(() => {
		//Add new key name(bodyItemListVal) in an array of objects
		const descriptionItemListObj = benefitItemFormData.body?.descriptionItemList?.map(item => {
			return { bodyItemListVal: item };
		});
		//Add new key name(bodyCheckListVal) in an array of objects
		const descriptionCheckListObj = benefitItemFormData.body?.descriptionCheckList?.map(item => {
			return { bodyCheckListVal: item };
		});

		//reset Benefit Item objects
		let benefitItemsSet = [];
		benefitItemsSet.employerId = stateUpdateBebefitItem.employerId;
		benefitItemsSet.employerResourcePlanId = stateUpdateBebefitItem.employerResourcePlanId;
		benefitItemsSet.resourceType = stateUpdateBebefitItem.resourceType;
		benefitItemsSet.employerLogo = stateUpdateBebefitItem.employerLogo;
		benefitItemsSet.summaryTitle = stateUpdateBebefitItem.summaryTitle;
		benefitItemsSet.summaryDescription = stateUpdateBebefitItem.summaryDescription;
		benefitItemsSet.bodyTitle = stateUpdateBebefitItem.body?.title;
		benefitItemsSet.bodyCheckListVal = descriptionCheckListObj;
		benefitItemsSet.bodyItemListVal = descriptionItemListObj;
		benefitItemsSet.footerTitle = stateUpdateBebefitItem.footer?.title;
		benefitItemsSet.footerButtonText = stateUpdateBebefitItem.footer?.buttonText;
		benefitItemsSet.footerDescription = stateUpdateBebefitItem.footer?.description;
		benefitItemsSet.footerPhone = stateUpdateBebefitItem.footer?.phone;
		benefitItemsSet.footerURL = stateUpdateBebefitItem.footer?.url;
		setBenefitItem(benefitItemsSet);
		setBenefitItemFormData(stateUpdateBebefitItem);
		setBenefitItemListFormField(!validations.isEmpty(descriptionItemListObj) ? descriptionItemListObj : [{ bodyItemListVal: "" }]);
		setBenefitItemCheckListFormField(!validations.isEmpty(descriptionCheckListObj) ? descriptionCheckListObj : [{ bodyCheckListVal: "" }]);
	}, [stateUpdateBebefitItem, benefitItemFormData]);


	//This function checks required field validations.
	const validateBenefitItemFormData = () => {
		if (validations.isEmpty(benefitItems.summaryTitle))
			return getValidationResultObject("summaryTitle", properties.BENEFIT_SUMMARY_TITLE_INVALID, true);

		if (validations.isTitle(benefitItems.summaryTitle))
			return getValidationResultObject("summaryTitle", properties.BENEFIT_SUMMARY_TITLE_INVALID_MAX_CHARACTERS_REACHED, true);

		if (validations.isEmpty(benefitItems.summaryDescription))
			return getValidationResultObject("summaryDescription", properties.BENEFIT_SUMMARY_DESCRIPTION_INVALID, true);

		if (validations.isDescription(benefitItems.summaryDescription))
			return getValidationResultObject("summaryDescription", properties.BENEFIT_SUMMARY_DESCRIPTION_INVALID_MAX_CHARACTERS_REACHED, true);

		if (validations.isEmpty(benefitItems.employerLogo) || validations.isIanacareUrl(benefitItems.employerLogo))
			return getValidationResultObject("employerLogo", properties.COMMON_EMP_LOGO_URL_INVALID, true);

		if (validations.isEmpty(benefitItems.bodyTitle))
			return getValidationResultObject("bodyTitle", properties.BENEFIT_BODY_TITLE_INVALID, true);

		if (validations.isTitle(benefitItems.bodyTitle))
			return getValidationResultObject("bodyTitle", properties.BENEFIT_BODY_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

		if (benefitItemCheckListFormField?.length > 1) {
			if (benefitItemCheckListFormField.filter((bodyCheckListItem) => !validations.isEmpty(bodyCheckListItem.bodyCheckListVal)).length !== benefitItemCheckListFormField.length) {
				return getValidationResultObject("bodyCheckList", properties.BENEFIT_BODY_CHECK_LIST_INVALID, true);
			}
		}

		if (benefitItemListFormField?.length > 0) {
			if (benefitItemListFormField.filter((bodyItem) => !validations.isEmpty(bodyItem.bodyItemListVal)).length !== benefitItemListFormField.length) {
				return getValidationResultObject("bodyCheckList", properties.BENEFIT_BODY_ITEM_LIST_INVALID, true);
			}
		}

		if (validations.isEmpty(benefitItems.footerTitle))
			return getValidationResultObject("footerTitle", properties.BENEFIT_FOOTER_TITLE_INVALID, true);

		if (validations.isTitle(benefitItems.footerTitle))
			return getValidationResultObject("footerTitle", properties.BENEFIT_FOOTER_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(benefitItems.footerDescription))
			return getValidationResultObject("footerDescription", properties.BENEFIT_FOOTER_DESCRIPTION_INVALID, true);

		if (validations.isDescription(benefitItems.footerDescription))
			return getValidationResultObject("footerDescription", properties.BENEFIT_FOOTER_DESCRIPTION_MAX_CHARACTERS_REACHED_INVALID, true);

		if (!validations.isEmpty(benefitItems.footerPhone) && validations.isPhoneNumber(benefitItems.footerPhone))
			return getValidationResultObject("footerPhone", properties.BENEFIT_FOOTER_PHONE_INVALID, true);

		if (!validations.isEmpty(benefitItems.footerURL) && validations.isUrl(benefitItems.footerURL))
			return getValidationResultObject("footerURL", properties.BENEFIT_FOOTER_URL_INVALID, true);

		return { fieldErrorObject, validationsEnabled: false };
	};

	//This function calls benefit check list new fields add
	let addBodyCheckListFields = () => {
		setBenefitItemCheckListFormField([...benefitItemCheckListFormField, { bodyCheckListVal: "" }]);
	};

	//This function calls benefit item form remove file
	let removeBodyCheckListFields = (i) => {
		let newBodyCheckList = [...benefitItemCheckListFormField];
		newBodyCheckList.splice(i, 1);
		setBenefitItemCheckListFormField(newBodyCheckList);
		setBenefitItem({ ...benefitItems, "descriptionCheckList": newBodyCheckList });
	};

	//This function calls body Check List onchange
	let changeBodyCheckListFormField = (i, e) => {
		let newBodyCheckList = [...benefitItemCheckListFormField];
		newBodyCheckList[i][e.target.name] = e.target.value;
		setBenefitItemCheckListFormField(newBodyCheckList);
		setBenefitItem({ ...benefitItems, "descriptionCheckList": newBodyCheckList });
	};

	//This function calls body Check List add new file
	let addBodyItemListFields = () => {
		setBenefitItemListFormField([...benefitItemListFormField, { bodyItemListVal: "" }]);
	};

	//This function calls body check list remove file
	let removeBodyItemListFields = (i) => {
		let newBodyItemList = [...benefitItemListFormField];
		newBodyItemList.splice(i, 1);
		setBenefitItemListFormField(newBodyItemList);
		setBenefitItem({ ...benefitItems, "descriptionItemList": newBodyItemList });
	};

	//This function calls body item list onchange
	let changeBodyItemListFormField = (i, e) => {
		let newBodyItemList = [...benefitItemListFormField];
		newBodyItemList[i][e.target.name] = e.target.value;
		setBenefitItemListFormField(newBodyItemList);
		setBenefitItem({ ...benefitItems, "descriptionItemList": newBodyItemList });
	};

	//This function calls when user entering data to input fields and store data in the state after validation
	const onChange = (event) => {
		event.persist();
		let name = event.target.name;
		let val = event.target.value;
		setBenefitItem({ ...benefitItems, [name]: val });
	};

	//This function calls when user submit data
	const onSubmit = async (event) => {
		if (event) event.preventDefault();

		//check list function update
		let updatedDescriptionCheckList = {};
		if (benefitItems?.descriptionCheckList) {
			if (validations.isEmpty(benefitItems?.descriptionCheckList[0])) {
				updatedDescriptionCheckList = [];
			} else if (validations.isEmpty(benefitItems?.descriptionCheckList?.[0].bodyCheckListVal)) {
				updatedDescriptionCheckList = [];
			} else {
				updatedDescriptionCheckList = benefitItems.descriptionCheckList.map((item) => item.bodyCheckListVal);
			}
		} else {
			if (!validations.isEmpty(benefitItems.bodyCheckListVal)) {
				updatedDescriptionCheckList = benefitItems.bodyCheckListVal.map((item) => item.bodyCheckListVal);
			} else {
				updatedDescriptionCheckList = [];
			}
		}

		let benefitItemsObj = {};
		if (validations.isEmpty(fieldErrorObject.errorMessage) && Object.keys(benefitItems).length !== 0) {
			benefitItemsObj.employerId = benefitItems.employerId,
			benefitItemsObj.employerResourcePlanId = benefitItems.employerResourcePlanId,
			benefitItemsObj.resourceType = benefitItems.resourceType,
			benefitItemsObj.employerLogo = benefitItems.employerLogo,
			benefitItemsObj.summaryTitle = benefitItems.summaryTitle,
			benefitItemsObj.summaryDescription = benefitItems.summaryDescription,
			benefitItemsObj.body = {
				title: benefitItems.bodyTitle,
				descriptionItemList: benefitItems.descriptionItemList ? benefitItems.descriptionItemList.map((item) => item.bodyItemListVal) : benefitItems.bodyItemListVal.map((item) => item.bodyItemListVal),
				descriptionCheckList: updatedDescriptionCheckList
			},
			benefitItemsObj.footer = {
				title: benefitItems.footerTitle,
				description: benefitItems.footerDescription,
				phone: benefitItems.footerPhone,
				url: benefitItems.footerURL,
				buttonText: benefitItems.footerButtonText
			};
			dispatch(updateBenefitItem(benefitItemsObj, benefitItemFormData.id));
		} else {
			return;
		}
	};

	//Return object and function
	return {
		stateEmployers, benefitItemListFormField, benefitItemCheckListFormField, benefitItems, validateBenefitItemFormData,
		addBodyItemListFields, removeBodyItemListFields, changeBodyItemListFormField, addBodyCheckListFields, removeBodyCheckListFields, changeBodyCheckListFormField, onChange, onSubmit
	};
};

export default useUpdateBenefitItemAction;
