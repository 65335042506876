import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { addPillarApi } from "../../../services/api/pillarService";

//Action(s)
export const addPillar = (pillarData) => ({
	type: TYPES.ADD_PILLAR,
	pillarData
});
const addPillarSuccess = (payload) => ({
	type: TYPES.ADD_PILLAR_SUCCESS,
	payload
});
const addPillarError = (error) => ({
	type: TYPES.ADD_PILLAR_ERROR,
	payload: error
});

//Saga(s)
export function* addPillarSaga({ pillarData }) {
	try {
		const response = yield call(addPillarApi, pillarData);
		if (response.status === HTTP_CODE.OK) {
			yield put(addPillarSuccess(response.data));
		}
	} catch (error) {
		yield put(addPillarError(error.errorMessage));
	}
}
