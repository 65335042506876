import React from "react";
import Box from "@mui/material/Box";
import { Grid, LinearProgress } from "@mui/material";
import "./style.scss";
import { isEmpty } from "../../../lib/validator";

const SelectField = ({
	label = "label",
	name,
	options,
	value,
	onChange,
	selectOption,
	removeSelectOption = false,
	loading = false,
	isDisabled = false,
	sx,
	id = null
}) => {
	const selectedDisabled = isDisabled && !isEmpty(value) ? "ian-inputField selectFieldCustomArrow selected-disabled" : "ian-inputField selectFieldCustomArrow";

	return (
		<Box sx={sx} className="ian-form-group">
			<Grid container direction="row">
				<label name={name} className="form-group-label-text-area">
					{label}
				</label>
			</Grid>
			{loading ? <div className="ian-form-group-inputGroup">
				<Box sx={{ width: "100%" }} className="progress-loader-custom">
					<LinearProgress className="ian-linear-progress" />
				</Box>
			</div> : <div className="ian-form-group-inputGroup selectFieldCustomArrowParent">
				<select id={id} required disabled={isDisabled} className={selectedDisabled} onChange={onChange} value={value} name={name}>
					{removeSelectOption ? "" : <option value="">{selectOption ? selectOption : "Select an option"}</option>}
					{options?.map(option => <option key={option.value} value={option.value}> {option.label}</option>)}
				</select>
			</div>}
		</Box>
	);
};
export default SelectField;
