import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { getExpertVideoAllApi } from "../../../services/api/expertVideoService";


//Action(s)
export const getExpertVideoAll = (payload) => ({
	type: TYPES.GET_EXPERT_VIDEO_ALL,
	payload: payload
});
const getExpertVideoAllSuccess = (payload) => ({
	type: TYPES.GET_EXPERT_VIDEO_ALL_SUCCESS,
	payload: payload
});
const getExpertVideoAllError = (error) => ({
	type: TYPES.GET_EXPERT_VIDEO_ALL_ERROR,
	payload: { error }
});
export const cacheExpertVideoSearchedData = (payload) => ({
	type: TYPES.CACHE_EXPERT_VIDEO_SEARCHED_DATA,
	payload: payload
});
export const resetExpertVideoState = () => ({
	type: TYPES.CLEAR_EXPERT_VIDEO
});

//Saga(s)
export function* getExpertVideoAllSaga({ payload }) {
	try {
		const response = yield call(getExpertVideoAllApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(getExpertVideoAllSuccess(response.data));
		}
	} catch (error) {
		yield put(getExpertVideoAllError(error));
	}
}
