import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as properties from "../../../../lib/properties/properties";
import * as validations from "../../../../lib/validator";
import { getValidationResultObject } from "../../../../lib/validator/util";
import { addExpertVideo } from "../../../../state/expertContents/expertVideo/addExpertVideo";
import { getResourcePlanAll } from "../../../../state/employers/resourcePlan/getResourcePlanAll";
import { resetExpertVideoState } from "../../../../state/expertContents/expertVideo/getExpertVideoAll";

const useAddExpertVideoAction = () => {

	const stateEmployers = useSelector(state => state.employer.eligibleEmployers);

	const [expertVideoFormData, setExpertVideoFormData] = useState({});
	const [expertVideoFormValidationsEnabled, setExpertVideoFormValidationsEnabled] = useState(false);

	const dispatch = useDispatch();
	const fieldErrorObject = { fieldName: "", errorMessage: "" };

	//Get cleared video state
	useEffect(() => { dispatch(resetExpertVideoState()); }, []);

	//This function checks form field validations.
	const validateExpertVideoFormData = () => {
		if (validations.isEmpty(expertVideoFormData.employer))
			return getValidationResultObject("employer", properties.COMMON_EMPLOYER_INVALID, expertVideoFormValidationsEnabled);

		if (validations.isEmpty(expertVideoFormData.employerResourcePlan))
			return getValidationResultObject("employerResourcePlan", properties.COMMON_RESOURCE_PLAN_INVALID, true);

		if (validations.isEmpty(expertVideoFormData.resourceType))
			return getValidationResultObject("resourceType", properties.COMMON_RESOURCE_TYPE_INVALID, true);

		if (validations.isEmpty(expertVideoFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_INVALID, true);

		if (validations.isTitle(expertVideoFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(expertVideoFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_INVALID, true);

		if (validations.isDescription(expertVideoFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(expertVideoFormData.videoURL))
			return getValidationResultObject("videoURL", properties.CONTENT_EXPE_VIDEO_URL_INVALID, true);

		if (validations.isIanacareUrl(expertVideoFormData.videoURL))
			return getValidationResultObject("videoURL", properties.CONTENT_EXPE_VIDEO_URL_INVALID, true);

		if (validations.isEmpty(expertVideoFormData.videoLength))
			return getValidationResultObject("videoLength", properties.CONTENT_EXPE_VIDEO_LENGTH_INVALID, true);

		if (validations.isVideoLength(expertVideoFormData.videoLength))
			return getValidationResultObject("videoLength", properties.CONTENT_EXPE_VIDEO_LENGTH_INVALID, true);

		if (validations.isEmpty(expertVideoFormData.previewImage))
			return getValidationResultObject("previewImage", properties.CONTENT_EXPE_PREVIEW_IMAGE_URL_INVALID, true);

		if (validations.isIanacareUrl(expertVideoFormData.previewImage))
			return getValidationResultObject("previewImage", properties.CONTENT_EXPE_PREVIEW_IMAGE_URL_INVALID, true);

		return { fieldErrorObject, expertVideoFormValidationsEnabled: false };
	};

	//Get Resource plan
	const getResourcePlanData = async (value) => {
		const payload = { employerId: value };
		dispatch(getResourcePlanAll(payload));
	};

	//This function calls when user entering data to input fields and store data in the state after validation.
	const onChange = (event) => {
		event.persist();
		const name = event.target.name;
		const val = event.target.value;
		if (name !== "employer") {
			//set expertVideoFormValidationsEnabled to true when employer id field not change
			setExpertVideoFormValidationsEnabled(true);
		} else {
			if (validations.isEmpty(val)) {
				//set expertVideoFormValidationsEnabled to true when employer id change with invalid val 
				setExpertVideoFormValidationsEnabled(true);
			} else {
				getResourcePlanData(val);
				expertVideoFormData.employerResourcePlan = "";
			}
		}
		setExpertVideoFormData({ ...expertVideoFormData, [name]: val });
	};

	//This function calls when user submit data.
	const onSubmit = async (event) => {
		const expertVideoObj = {};
		if (event) event.preventDefault();

		if (validations.isEmpty(fieldErrorObject.errorMessage) && Object.keys(expertVideoFormData).length !== 0) {
			expertVideoObj.employerId = expertVideoFormData.employer;
			expertVideoObj.employerResourcePlanId = expertVideoFormData.employerResourcePlan;
			expertVideoObj.resourceType = expertVideoFormData.resourceType;
			expertVideoObj.title = expertVideoFormData.title;
			expertVideoObj.description = expertVideoFormData.description;
			expertVideoObj.url = expertVideoFormData.videoURL;
			expertVideoObj.videoLength = expertVideoFormData.videoLength;
			expertVideoObj.previewImage = expertVideoFormData.previewImage;
			dispatch(addExpertVideo(expertVideoObj));
		} else {
			return;
		}
	};

	return { stateEmployers, expertVideoFormData, validateExpertVideoFormData, onChange, onSubmit };
};

export default useAddExpertVideoAction;
