import React from "react";
import Box from "@mui/material/Box";

import "./style.scss";

const ToggleSwitch = ({ label, name, checked = false, onChange }) => {
	return (
		<Box className="ian-form-group">
			<label className="switch ian-switch">
				<span className="ian-switch-label">{label}</span>
				<input 
					type="checkbox" 
					name={name}
					checked={checked}
					onChange={onChange}
				/>
				<span className="slider round"></span>
			</label>
		</Box>
	);
};
export default ToggleSwitch;
