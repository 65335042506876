import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Divider from "@mui/material/Divider";
import { Box, LinearProgress } from "@mui/material";

import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import SelectField from "../../../common/SelectField";
import PrimaryButton from "../../../common/Button/PrimaryButton";
import BackButton from "../../../common/Button/BackButton";
import SuccessPopup from "../../../common/SuccessPopup";
import ErrorPopup from "../../../common/ErrorPopup";
import * as properties from "../../../../lib/properties/properties";
import { getRowIdAndEmployerId, getSupportedUrlTooltip } from "../../../../lib/util/commonUtil";
import { ResourceTypeOptions } from "../../../../lib/constants/ResourceTypeOptions";
import { DEFAULT_PAGE_LIMIT_20, DEFAULT_PAGE_NUMBER } from "../../../../lib/constants";

import { resetExpertVideoState } from "../../../../state/expertContents/expertVideo/getExpertVideoAll";
import { getResourcePlanAll } from "../../../../state/employers/resourcePlan/getResourcePlanAll";
import { getExpertVideo } from "../../../../state/expertContents/expertVideo/getExpertVideo";

import useUpdateExpertVideoAction from "./useUpdateExpertVideoAction";

const UpdateExpertVideo = () => {

	const stateResourcePlanOptions = useSelector(state => state.resourcePlan.resourcePlanOptions);
	const stateExpertVideo = useSelector(state => state.expertVideo);

	const dispatch = useDispatch();
	const history = useHistory();
	const urlExpertVideoEmployerPathVariables = getRowIdAndEmployerId();

	// Custom hook for data submitting
	const { stateEmployers, expertVideoFormData, validateExpertVideoFormData, onChange, onSubmit } = useUpdateExpertVideoAction();
	//Get Expert video data
	const getExpertVideoData = async () => {
		const payload = { videoId: urlExpertVideoEmployerPathVariables[0] };
		dispatch(getExpertVideo(payload));
	};

	//Get Resource plan
	const getResourcePlanData = async (value) => {
		const payload = { employerId: value, pagelimit: DEFAULT_PAGE_LIMIT_20, pagenumber: DEFAULT_PAGE_NUMBER };
		dispatch(getResourcePlanAll(payload));
	};

	useEffect(() => { getResourcePlanData(urlExpertVideoEmployerPathVariables[1]); getExpertVideoData(); }, []);

	const onClickBackButton = () => {
		history.push("/contents?tab=expertVideo");
	};

	//This function reset video data in redux state.
	const onSuccessPopupButtonClicked = () => {
		dispatch(resetExpertVideoState());
		history.push("/contents?tab=expertVideo");
	};

	const onErrorPopupButtonClicked = () => {
		dispatch(resetExpertVideoState());
	};
	return (
		<div>
			<BackButton className="px-5" onClick={onClickBackButton} />
			<div className="tabSectionRight ian-position-relative">
				<div className="ian-centeredSection ian-w-40 ian-position-relative">
					<h5 className="ian-modal-title">
						{properties.CONTENT_EXPE_VIDEO_UPDATE_TITLE}
					</h5>
					{stateExpertVideo.loading && <Box sx={{ width: "100%" }}> <LinearProgress className="ian-linear-progress" /> </Box>}
					<div className="ian-form-container">
						<SelectField
							label={properties.COMMON_EMPLOYER_LABEL}
							name="employerId"
							id="employerId"
							options={stateEmployers}
							value={expertVideoFormData.employerId}
							isDisabled={true}
						/>
						<SelectField
							label={properties.COMMON_EMPLOYER_RESOURCE_PLAN_LABEL}
							name="employerResourcePlanId"
							id="employerResourcePlanId"
							options={expertVideoFormData.employerId ? stateResourcePlanOptions : []}
							value={expertVideoFormData.employerResourcePlanId}
							isDisabled={true}
						/>
						<SelectField
							label={properties.COMMON_RESOURCE_TYPE_LABEL}
							name="resourceType"
							id="resourceType"
							options={ResourceTypeOptions}
							value={expertVideoFormData.resourceType}
							isDisabled={true}
						/>
						<TextField
							label={properties.COMMON_TITLE_LABEL}
							placeholder={properties.COMMON_TITLE_PLACEHOLDER}
							name="title"
							id="title"
							type="text"
							onChange={onChange}
							value={expertVideoFormData.title || ""}
						/>
						<TextArea
							label={properties.COMMON_DESCRIPTION_LABEL}
							placeholder={properties.COMMON_DESCRIPTION_PLACEHOLDER}
							name="description"
							id="description"
							type="text"
							onChange={onChange}
							value={expertVideoFormData.description}
							characterCount={expertVideoFormData.description?.length}
						/>

						<Divider orientation="horizontal">{properties.CONTENT_EXPE_ARTICLE_VIDEO_TITLE}</Divider>
						<TextField
							label={properties.CONTENT_EXPE_VIDEO_URL_LABEL}
							placeholder={properties.CONTENT_EXPE_VIDEO_URL_PLACEHOLDER}
							name="url"
							id="url"
							type="text"
							onChange={onChange}
							value={expertVideoFormData.url || ""}
							toolTipText={getSupportedUrlTooltip()}
						/>
						<TextField
							label={properties.CONTENT_EXPE_VIDEO_LENGTH_LABEL}
							placeholder={properties.COMMON_VIDEO_LENGTH_PLACEHOLDER}
							name="videoLength"
							id="videoLength"
							type="text"
							onChange={onChange}
							value={expertVideoFormData.videoLength || ""}
						/>
						<TextField
							label={properties.COMMON_PREVIEW_IMAGE_LABEL}
							placeholder={properties.COMMON_PREVIEW_IMAGE_PLACEHOLDER}
							name="previewImage"
							id="previewImage"
							type="text"
							onChange={onChange}
							value={expertVideoFormData.previewImage || ""}
							toolTipText={getSupportedUrlTooltip()}
						/>

						{validateExpertVideoFormData().validationsEnabled ? (
							<div className="ian-error-warning show" sx={{ display: "none" }}>
								<p>
									{" "}
									{validateExpertVideoFormData().fieldErrorObject.errorMessage}
								</p>
							</div>
						) : (
							<PrimaryButton
								className="px-5"
								label={properties.COMMON_UPDATE_LABEL}
								disabled={validateExpertVideoFormData().fieldErrorObject.errorMessage || stateExpertVideo.loading}
								showProgress={false}
								onClick={onSubmit}
							/>
						)}
						{stateExpertVideo.success ? <SuccessPopup message={properties.SUCCESS_POPUP_UPDATE_MESSAGE} okButtonAction={onSuccessPopupButtonClicked}
						/> : null}
						{stateExpertVideo.error ? <ErrorPopup
							error={stateExpertVideo.error}
							okButtonAction={onErrorPopupButtonClicked}
						/> : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UpdateExpertVideo;
