import { ADD_EXPERT_ARTICLE_URL, GET_EXPERT_ARTICLE_URL, GET_EXPERT_ARTICLE_ALL_URL, UPDATE_EXPERT_ARTICLE_URL } from "./endPoints";
import networkClient from "./networkClient";
import { HTTP_METHOD } from "../../lib/enums/httpData";

export function addExpertArticleApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.POST,
		url: ADD_EXPERT_ARTICLE_URL,
		data: payload.data
	});
}

export function getExpertArticleApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.GET,
		url: GET_EXPERT_ARTICLE_URL.replace("{articleId}", payload.articleId),
		data: payload.data
	});
}

export function getExpertArticleAllApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.GET,
		url: GET_EXPERT_ARTICLE_ALL_URL.replace("{employerid}", payload.employerId).replace("{pagelimit}", payload.pagelimit).replace("{pagenumber}", payload.pagenumber),
		data: payload.data
	});
}

export function updateExpertArticleApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.PUT,
		url: UPDATE_EXPERT_ARTICLE_URL.replace("{expertArticleId}", payload.data.id),
		data: payload.data
	});
}


