import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import VisibilityIcon from "@mui/icons-material/Visibility";

import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import SelectField from "../../../common/SelectField";
import PrimaryButton from "../../../common/Button/PrimaryButton";
import BackButton from "../../../common/Button/BackButton";
import ErrorPopup from "../../../common/ErrorPopup";
import SuccessPopup from "../../../common/SuccessPopup";
import ContentPreview from "../common/contentPreview";
import * as properties from "../../../../lib/properties/properties";
import { isEmpty } from "../../../../lib/validator";
import { cacheExpertArticleSearchedData, resetExpertArticleState } from "../../../../state/expertContents/expertArticle/getExpertArticleAll";
import { DEFAULT_PAGE_NUMBER } from "../../../../lib/constants";
import { ResourceTypeOptions } from "../../../../lib/constants/ResourceTypeOptions";
import { getSupportedUrlTooltip } from "../../../../lib/util/commonUtil";

import useAddExpertArticleAction from "./useAddExpertArticleAction";

const AddExpertArticle = () => {

	const stateExpertArticle = useSelector(state => state.expertArticle);
	const stateResourcePlan = useSelector(state => state.resourcePlan);

	//flag to say content preview popup open or not
	const [isContentPreviewOpened, setIsContentPreviewOpened] = useState(false);

	let history = useHistory();
	const dispatch = useDispatch();

	//Custom hook for article creation
	const { stateEmployers, expertArticleFormData, validateExpertArticleFormData, onChange, onSubmit } = useAddExpertArticleAction();

	//This function executes when error popup ok button clicked.
	const onErrorPopupButtonClicked = () => {
		dispatch(resetExpertArticleState());
	};

	//This function executes when go back action gets triggered
	const onClickBackButton = () => {
		history.push("/contents");
	};

	//This function executes when success popup ok button clicked.
	const onSuccessPopupButtonClicked = () => {
		dispatch(resetExpertArticleState());
		//set the previous Search results
		dispatch(cacheExpertArticleSearchedData({ employerId: expertArticleFormData.employerId, pagenumber: DEFAULT_PAGE_NUMBER }));
		history.push("/contents");
	};

	const contentPreviewClickOpen = () => {
		setIsContentPreviewOpened(true);
	};

	const contentPreviewClose = () => {
		setIsContentPreviewOpened(false);
	};

	return (
		<div>
			<BackButton className="px-5" onClick={onClickBackButton} />
			<div className="tabSectionRight ian-position-relative">
				<div className="ian-centeredSection ian-w-40 ian-position-relative">
					<h5 className="ian-modal-title">
						{properties.CONTENT_EXPE_ARTICLE_CREATE_TITLE}
					</h5>
					<div className="ian-form-container">
						<SelectField
							label={properties.COMMON_EMPLOYER_LABEL}
							name="employerId"
							id="employerId"
							options={stateEmployers}
							selectOption={properties.COMMON_SELECT_OPTION_EMPLOYER_LABEL}
							onChange={onChange}
						/>
						<SelectField
							label={properties.COMMON_EMPLOYER_RESOURCE_PLAN_LABEL}
							name="employerResourcePlanId"
							id="employerResourcePlanId"
							options={expertArticleFormData.employerId ? stateResourcePlan?.resourcePlanOptions : []}
							selectOption={properties.COMMON_SELECT_OPTION_RESOURCE_PLAN_LABEL}
							loading={stateResourcePlan?.loading}
							onChange={onChange}
							isDisabled={expertArticleFormData.employerId ? false : true}
						/>
						<SelectField
							label={properties.COMMON_RESOURCE_TYPE_LABEL}
							name="resourceType"
							id="resourceType"
							options={ResourceTypeOptions}
							onChange={onChange}
							txtCounterEnabled={false}
							selectOption={properties.COMMON_SELECT_OPTION_RESOURCE_TYPE_LABEL}
						/>
						<TextField
							label={properties.COMMON_TITLE_LABEL}
							placeholder={properties.COMMON_TITLE_PLACEHOLDER}
							name="title"
							id="title"
							type="text"
							onChange={onChange}
						/>
						<TextArea
							label={properties.COMMON_DESCRIPTION_LABEL}
							placeholder={properties.COMMON_DESCRIPTION_PLACEHOLDER}
							name="description"
							id="description"
							type="text"
							onChange={onChange}
						/>

						{!isEmpty(expertArticleFormData.content) ?
							<div className="ian-IntegrationInstructionsIcon">
								<VisibilityIcon onClick={contentPreviewClickOpen} />
							</div> : ""}

						<TextArea
							label={properties.COMMON_CONTENT_LABEL}
							placeholder={properties.COMMON_CONTENT_PLACEHOLDER}
							name="content"
							id="content"
							type="text"
							onChange={onChange}
							txtCounterEnabled={false}
						/>

						<Divider orientation="horizontal">{properties.CONTENT_EXPE_ARTICLE_PREVIEW_TITLE}</Divider>
						<TextField
							label={properties.COMMON_PREVIEW_IMAGE_LABEL}
							placeholder={properties.COMMON_PREVIEW_IMAGE_PLACEHOLDER}
							name="previewImage"
							id="previewImage"
							type="text"
							onChange={onChange}
							toolTipText={getSupportedUrlTooltip()}
						/>
						<TextField
							label={properties.COMMON_PREVIEW_TITLE_LABEL}
							placeholder={properties.COMMON_PREVIEW_TITLE_PLACEHOLDER}
							name="previewTitle"
							id="previewTitle"
							type="text"
							onChange={onChange}
						/>

						{validateExpertArticleFormData().validationsEnabled ? (
							<div className="ian-error-warning show" sx={{ display: "none" }}>
								<p>
									{" "}
									{validateExpertArticleFormData().fieldErrorObject.errorMessage}
								</p>
							</div>
						) : (
							<PrimaryButton
								className="px-5"
								label={properties.COMMON_CREATE_LABEL}
								disabled={validateExpertArticleFormData().fieldErrorObject.errorMessage || stateExpertArticle.loading}
								showProgress={false}
								onClick={onSubmit}
							/>
						)}
					</div>

					{/* Priview Content */}
					<Dialog fullWidth={true} maxWidth="md" open={isContentPreviewOpened} onClose={contentPreviewClose} className="contet-preview" >
						<ContentPreview htmlCont={expertArticleFormData.content} contentPreviewClose={contentPreviewClose} />
					</Dialog>

					{stateExpertArticle.error ? <ErrorPopup error={stateExpertArticle.error} okButtonAction={onErrorPopupButtonClicked} /> : ""}
					{
						stateExpertArticle.success ? <SuccessPopup
							message={properties.SUCCESS_POPUP_CREATE_MESSAGE} okButtonAction={onSuccessPopupButtonClicked}
						/> : null
					}
				</div>
			</div>
		</div>
	);
};

export default AddExpertArticle;
