import * as TYPES from "./types";

const initialState = {
	benefitHome: {},
	benefitHomes: []
};

const benefitHomeReducer = (state = initialState, action) => {
	switch (action.type) {
	case TYPES.ADD_BENEFIT_HOME:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.ADD_BENEFIT_HOME_SUCCESS:
		return {
			...state,
			benefitHome: action.payload,
			success:true,
			error: null
		};
	case TYPES.ADD_BENEFIT_HOME_ERROR:
		return {
			...state,
			error: action.payload.error.errorMessage
		};
	case TYPES.GET_BENEFIT_HOME:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.GET_BENEFIT_HOME_SUCCESS:
		return {
			...state,
			loading: false,
			benefitHome: action.payload,
			error: null
		};
	case TYPES.GET_BENEFIT_HOME_ERROR:
		return {
			...state,
			loading: false,
			benefitHome: null,
			error: action.payload.error.errorMessage
		};
	case TYPES.GET_BENEFIT_HOME_ALL:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.GET_BENEFIT_HOME_ALL_SUCCESS:
		return {
			...state,
			loading: false,
			benefitHomes: action.payload.benefitHomeList,
			error: null
		};
	case TYPES.GET_BENEFIT_HOME_ALL_ERROR:
		return {
			...state,
			loading: false,
			benefitHomes: null,
			error: null
		};
	case TYPES.UPDATE_BENEFIT_HOME:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.UPDATE_BENEFIT_HOME_SUCCESS:
		return {
			...state,
			loading: false,
			benefitHome: action.payload,
			success:true,
			error: null
		};
	case TYPES.UPDATE_BENEFIT_HOME_ERROR:
		return {
			...state,
			error: action.payload.error.errorMessage
		};
	case TYPES.CACHE_BENEFIT_HOME_SEARCHED_DATA:
		return {
			...state,
			search: action.payload
		};
	case TYPES.CLEAR_BENEFIT_HOME:
		return {
			...state,
			loading: false,
			success:false,
			benefitHomes: [],
			error: null
		};
	default:
		return state;
	}
};
export default benefitHomeReducer;
