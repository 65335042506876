import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import EditIcon from "@mui/icons-material/Edit";

import { DEFAULT_PAGE_NUMBER } from "../../../lib/constants";

import "./style.scss";

export default function DataTable({ headerTitle, Data, pageData, parentCallback, updateForm, isEmployerTableView = false, dataLoad = false,
	paginationActive = false, noDataLabel }) {
	let history = useHistory();
	const [page, setPage] = useState(pageData?.pageNumber);

	useEffect(() => { setPage(pageData?.pageNumber); }, [pageData]);

	const onClickEdit = (id) => {
		history.push(updateForm + "/" + id);
	};

	//This function call next page
	const onClickNextPage = (event, value) => {
		setPage(value);
		parentCallback(value);
	};

	const stringTruncate = (str) => {
		if (str) {
			var dots = str.length > 18 ? "..." : "";
			return str.substring(0, 18) + dots;
		} else {
			return "-";
		}
	};

	return (
		<div>
			<TableContainer component={Paper} className="dtable-height-set" >
				{dataLoad ? <Box sx={{ width: "100%" }}> <LinearProgress className="ian-linear-progress" /> </Box> : ""}
				<Table stickyHeader sx={{ minWidth: 650, minHeight: 118 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							{headerTitle.map((row) => { return (<TableCell key={row}>{row}</TableCell>); })}
						</TableRow>
					</TableHead>
					{/* data mapping */}
					{Data.length > 0 ?
						<TableBody>
							{Data.map((row) => {
								return (
									<TableRow key={row.value0} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} >
										<TableCell component="th" scope="row">{row.value0}</TableCell>
										<TableCell>{stringTruncate(row.value2)}</TableCell>
										<TableCell>{stringTruncate(row.value3)}</TableCell>
										<TableCell>{stringTruncate(row.value4)}</TableCell>
										<TableCell><EditIcon className="edit-icon" onClick={() => onClickEdit(isEmployerTableView ? row.value0 : row.value0 + "," + row.value1.employerId)} />
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody> :
						/* No data */
						<TableBody>
							<TableRow>
								<TableCell colSpan={5} align="center">
									<span className="status-label">{noDataLabel}</span>
								</TableCell>
							</TableRow>
						</TableBody>}
				</Table>
			</TableContainer >
			{Data.length > 0 && paginationActive && pageData?.pageCount > 1 ?
				<Pagination count={pageData?.pageCount} page={page ?? DEFAULT_PAGE_NUMBER} onChange={onClickNextPage} />
				: ""}
		</div>
	);
}
