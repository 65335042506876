import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { addEmployerApi } from "../../../services/api/employerService";

//Action(s)
export const addEmployer = (payload) => ({
	type: TYPES.ADD_EMPLOYER,
	payload: payload
});
const addEmployerSuccess = (payload) => ({
	type: TYPES.ADD_EMPLOYER_SUCCESS,
	payload: payload
});
const addEmployerError = (error) => ({
	type: TYPES.ADD_EMPLOYER_ERROR,
	payload: error
});

//Saga(s)
export function* addEmployerSaga({ payload }) {
	try {
		const response = yield call(addEmployerApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(addEmployerSuccess(response.data));
		}
	} catch (error) {
		yield put(addEmployerError(error));
	}
}
