import * as TYPES from "./types";

const initialState = {
	pillar: {},
	pillars: []
};

const pillarReducer = (state = initialState, action) => {
	switch (action.type) {
	case TYPES.ADD_PILLAR:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.ADD_PILLAR_SUCCESS:
		return {
			...state,
			loading: false,
			success: true,
			pillar: action.payload,
			error: null
		};
	case TYPES.ADD_PILLAR_ERROR:
		return {
			...state,
			loading: false,
			success: false,
			pillar: "",
			error: action.payload
		};
	case TYPES.GET_PILLAR:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.GET_PILLAR_SUCCESS:
		return {
			...state,
			loading: false,
			pillar: action.payload,
			error: null
		};
	case TYPES.GET_PILLAR_ERROR:
		return {
			...state,
			loading: false,
			pillar: "",
			error: action.payload.errorMessage
		};
	case TYPES.GET_PILLAR_ALL:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.GET_PILLAR_ALL_SUCCESS:
		return {
			...state,
			loading: false,
			pillars: action.payload.pillarHomes,
			error: null
		};
	case TYPES.GET_PILLAR_ALL_ERROR:
		return {
			...state,
			loading: false,
			pillars: [],
			error: null
		};
	case TYPES.UPDATE_PILLAR:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.UPDATE_PILLAR_SUCCESS:
		return {
			...state,
			loading: false,
			pillar: action.payload,
			success: true,
			error: null
		};
	case TYPES.UPDATE_PILLAR_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload.error.errorMessage
		};
	case TYPES.CACHE_PILLAR_SEARCHED_DATA:
		return {
			...state,
			search: action.payload
		};
	case TYPES.CLEAR_PILLAR:
		return {
			...state,
			loading: false,
			success: false,
			pillars: [],
			error: null
		};
	default:
		return state;
	}
};
export default pillarReducer;
