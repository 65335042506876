import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import { getResourcePlanApi } from "../../../services/api/employerService";
import * as TYPES from "./types";

//Action(s)
export const getResourcePlan = (payload) => ({
	type: TYPES.GET_RESOURCE_PLAN,
	payload: payload
});
const getResourcePlanSuccess = (payload) => ({
	type: TYPES.GET_RESOURCE_PLAN_SUCCESS,
	payload: payload
});
const getResourcePlanError = (error) => ({
	type: TYPES.GET_RESOURCE_PLAN_ERROR,
	payload: { error }
});

//Saga(s)
export function* getResourcePlanSaga({ payload }) {
	try {
		const response = yield call(getResourcePlanApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(getResourcePlanSuccess(response.data));
		}
	} catch (error) {
		yield put(getResourcePlanError(error));
	}
}
