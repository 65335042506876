import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { getExpertArticleAllApi } from "../../../services/api/expertArticleService";

//Action(s)
export const getExpertArticleAll = (payload) => ({
	type: TYPES.GET_EXPERT_ARTICLE_ALL,
	payload: payload
});
const getExpertArticleAllSuccess = (payload) => ({
	type: TYPES.GET_EXPERT_ARTICLE_ALL_SUCCESS,
	payload: payload
});
const getExpertArticleAllError = (error) => ({
	type: TYPES.GET_EXPERT_ARTICLE_ALL_ERROR,
	payload: { error }
});
export const cacheExpertArticleSearchedData = (payload) => ({
	type: TYPES.CACHE_EXPERT_ARTICLE_SEARCHED_DATA,
	payload: payload
});
export const resetExpertArticleState = () => ({
	type: TYPES.CLEAR_EXPERT_ARTICLE
});

//Saga(s)
export function* getExpertArticleAllSaga({ payload }) {
	try {
		const response = yield call(getExpertArticleAllApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(getExpertArticleAllSuccess(response.data));
		}
	} catch (error) {
		yield put(getExpertArticleAllError(error));
	}
}
