import React from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";

export default function LeftNavigator({ selectedIndex }) {
	let history = useHistory();

	return (
		<Box sx={{ width: "100%" }} justifyContent="center">
			<h5 className="ian-title-h5">General</h5>
			<List component="nav" className="accntPrefMenuList" sx={{ paddingTop: "0" }} >
				<ListItemButton selected={selectedIndex === 0} onClick={() => history.push("/general?tab=configData")} >
					<ListItemText primary="Config Data" />
				</ListItemButton>
				<ListItemButton selected={selectedIndex === 1} onClick={() => history.push("/general?tab=notification")} >
					<ListItemText primary="Notification" />
				</ListItemButton>
			</List>
		</Box>
	);
}
