import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import "./style.scss";
const AutoComplete = ({
	id,
	label,
	sx,
	onChange,
	options,
	value,
	isDisabled,
	placeholder,
	name
}) => {

	useEffect(() => { setSearchedValue(value); }, [value]);
	
	const [searchedValue, setSearchedValue] = React.useState(value);
	const [inputValue, setInputValue] = React.useState("");

	const onChangeAutoComplete = (event, newValue) => {
		setSearchedValue(newValue);
		onChange(event);
	};

	return (
		<Box sx={sx} className="ian-form-group">
			<label name={label} className="ian-form-group-label">
				{label}
			</label>
			<Autocomplete
				className="ian-autocomplete ian-autocompleteNoChck"
				value={searchedValue}
				onChange={onChangeAutoComplete}
				disabled={isDisabled}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue);
				}}
				name={name}
				id={id}
				options={options}
				renderInput={(params) => <TextField {...params} placeholder={placeholder} onKeyDown={e => {
					if (e.code === "Enter") {
						e.stopPropagation();
					}
				}} />}
				isOptionEqualToValue={(option, value) => option.id === value.id}
			/>
		</Box>
	);
};
export default AutoComplete;
