import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import SelectField from "../../../common/SelectField";
import PrimaryButton from "../../../common/Button/PrimaryButton";
import BackButton from "../../../common/Button/BackButton";
import ErrorPopup from "../../../common/ErrorPopup";
import SuccessPopup from "../../../common/SuccessPopup";
import * as properties from "../../../../lib/properties/properties";
import { ResourceTypeOptions } from "../../../../lib/constants/ResourceTypeOptions";
import { getSupportedUrlTooltip } from "../../../../lib/util/commonUtil";
import BenefitItemSection from "../common/benefitItemSection";
import { cacheBenefitHomeSearchedData, resetBenefitHomeState } from "../../../../state/benefits/benefitHome/getBenefitHomeAll";

import useAddBenefitHomeAction from "./useAddBenefitHomeAction";

import "../../common/style.scss";

const AddBenefitHome = () => {

	const stateResourcePlan = useSelector(state => state.resourcePlan);
	const stateBenefitHome = useSelector(state => state.benefitHome);

	let history = useHistory();
	const dispatch = useDispatch();

	//Custom hook for this form action
	const { stateEmployers, benefitHomeFormItems, benefitHomeFormData, validateBenefitHomeFormData, addBenefitItemFormFields, changeBenefitItemFormField, removeBenefitItemFormFields, onChange, onSubmit } = useAddBenefitHomeAction();

	//This function executes when error popup ok button clicked.
	const onErrorPopupButtonClicked = () => {
		dispatch(resetBenefitHomeState());
	};

	//This function executes when go back action gets triggered
	const onClickBackButton = () => {
		history.push("/benefits?tab=benefitHome");
	};

	//This function executes when success popup ok button clicked.
	const onSuccessPopupButtonClicked = () => {
		dispatch(resetBenefitHomeState());
		//set the previous Search results
		dispatch(cacheBenefitHomeSearchedData({ employerId: benefitHomeFormData.employerId }));
		history.push("/benefits?tab=benefitHome");
	};

	return (
		<div>
			<BackButton className="px-5" onClick={onClickBackButton} />
			<div className="tabSectionRight ian-position-relative">
				<div className="ian-centeredSection ian-w-40 scrollTop">
					<div className="ian-form-container">
						<h5 className="ian-modal-title">
							{properties.BENEFIT_HOME_CREATE_TITLE}
						</h5>
						<SelectField
							label={properties.COMMON_EMPLOYER_LABEL}
							name="employerId"
							id="employerId"
							options={stateEmployers}
							selectOption={properties.COMMON_SELECT_OPTION_EMPLOYER_LABEL}
							onChange={onChange}
						/>
						<SelectField
							label={properties.COMMON_EMPLOYER_RESOURCE_PLAN_LABEL}
							name="employerResourcePlanId"
							id="employerResourcePlanId"
							options={benefitHomeFormData?.employerId ? stateResourcePlan?.resourcePlanOptions : []}
							selectOption={properties.COMMON_SELECT_OPTION_RESOURCE_PLAN_LABEL}
							loading={stateResourcePlan?.loading}
							onChange={onChange}
							isDisabled={!benefitHomeFormData?.employerId}
						/>
						<SelectField
							label={properties.COMMON_RESOURCE_TYPE_LABEL}
							name="resourceType"
							id="resourceType"
							options={ResourceTypeOptions}
							onChange={onChange}
							selectOption={properties.COMMON_SELECT_OPTION_RESOURCE_TYPE_LABEL}
						/>
						<TextField
							label={properties.COMMON_TITLE_LABEL}
							placeholder={properties.COMMON_TITLE_PLACEHOLDER}
							name="title"
							id="title"
							type="text"
							onChange={onChange}
						/>
						<TextArea
							label={properties.COMMON_DESCRIPTION_LABEL}
							placeholder={properties.COMMON_DESCRIPTION_PLACEHOLDER}
							name="description"
							id="description"
							type="text"
							onChange={onChange}
						/>
						<TextField
							label={properties.COMMON_EMPLOYER_LOGO_MANDATORY_URL_LABEL}
							placeholder={properties.COMMON_EMPLOYER_LOGO_URL_PLACEHOLDER}
							name="employerLogo"
							id="employerLogo"
							type="text"
							onChange={onChange}
							toolTipText={getSupportedUrlTooltip()}
						/>
						<BenefitItemSection benefitItems={benefitHomeFormItems} benefitHomes={benefitHomeFormData} formOnChange={changeBenefitItemFormField} addFormFields={addBenefitItemFormFields} removeFormFields={removeBenefitItemFormFields} />

						{validateBenefitHomeFormData().validationsEnabled ? (
							<div className="ian-error-warning show px-5 mt-5" sx={{ display: "none" }}>
								<p>
									{validateBenefitHomeFormData().fieldErrorObject.errorMessage}
								</p>
							</div>
						) : (
							<PrimaryButton
								className="px-5"
								label={properties.COMMON_CREATE_LABEL}
								disabled={validateBenefitHomeFormData().fieldErrorObject.errorMessage || stateBenefitHome.loading}
								showProgress={false}
								onClick={onSubmit}
							/>
						)}
					</div>
					{stateBenefitHome.error ? <ErrorPopup error={stateBenefitHome.error} okButtonAction={onErrorPopupButtonClicked} /> : ""}
					{stateBenefitHome.success ? <SuccessPopup
						message={properties.SUCCESS_POPUP_CREATE_MESSAGE} okButtonAction={onSuccessPopupButtonClicked}
					/> : null}
				</div>
			</div>
		</div>
	);
};

export default AddBenefitHome;
