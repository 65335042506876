import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import * as validations from "../../../lib/validator";
import { getBenefitHomeAll, cacheBenefitHomeSearchedData, resetBenefitHomeState } from "../../../state/benefits/benefitHome/getBenefitHomeAll";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_LIMIT_20 } from "../../../lib/constants";

const useBenefitHomeAction = () => {

	const stateEmployers = useSelector(state => state.employer?.eligibleEmployers);
	const stateSearchedBenefitHome = useSelector(state => state.benefitHome.search);

	const dispatch = useDispatch();
	const history = useHistory();
	const [benefitHomeSearchedEmployer, setBenefitHomeSearchedEmployer] = useState({ employerId: stateSearchedBenefitHome?.employerId });

	const getBenefitHomeData = async () => {
		const payload = { employerId: benefitHomeSearchedEmployer.employerId, pagelimit: DEFAULT_PAGE_LIMIT_20, pagenumber: DEFAULT_PAGE_NUMBER };
		dispatch(getBenefitHomeAll(payload));
		//set the previous Search results
		dispatch(cacheBenefitHomeSearchedData({ employerId: benefitHomeSearchedEmployer.employerId }));
	};

	//This function checks required field validations.
	const isValidData = () => {
		return !validations.isEmpty(benefitHomeSearchedEmployer.employerId);
	};

	//This function call search data
	const onClickSearch = () => {
		getBenefitHomeData();
	};

	//This function call create new benefit home form
	const onClickAddForm = () => {
		history.push("/benefits?tab=addBenefitHome");
	};

	//This function calls when user entering data to input fields and store data in the state after validation
	const onChange = (event) => {
		event.persist();
		let name = event.target.name;
		let val = event.target.value;
		dispatch(resetBenefitHomeState());
		dispatch(cacheBenefitHomeSearchedData({ employerId: "", pagenumber: DEFAULT_PAGE_NUMBER }));
		setBenefitHomeSearchedEmployer({ ...benefitHomeSearchedEmployer, [name]: val });
	};

	//Return object and function
	return { stateEmployers, benefitHomeSearchedEmployer, isValidData, onClickAddForm, onClickSearch, onChange };
};

export default useBenefitHomeAction;
