import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { updateBenefitHomeApi } from "../../../services/api/benefitHomeService";

//Action(s)
export const updateBenefitHome = (payload) => ({
	type: TYPES.UPDATE_BENEFIT_HOME,
	payload: payload
});
const updateBenefitHomeSuccess = (payload) => ({
	type: TYPES.UPDATE_BENEFIT_HOME_SUCCESS,
	payload: payload
});
const updateBenefitHomeError = (error) => ({
	type: TYPES.UPDATE_BENEFIT_HOME_ERROR,
	payload: { error }
});

//Saga(s)
export function* updateBenefitHomeSaga({ payload }) {
	try {
		const response = yield call(updateBenefitHomeApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(updateBenefitHomeSuccess(response.data));
		}
	} catch (error) {
		yield put(updateBenefitHomeError(error));
	}
}
