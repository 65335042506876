import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { splitByHyphenAndTrim } from "../../../../lib/util/commonUtil";
import * as properties from "../../../../lib/properties/properties";
import * as validations from "../../../../lib/validator";
import { getValidationResultObject } from "../../../../lib/validator/util";

import { updatePillar } from "../../../../state/pillars/pillar/updatePillar";

const useUpdatePillarAction = () => {

	const stateEmployers = useSelector(state => state.employer.eligibleEmployers);
	const stateExpertArticles = useSelector(state => state.expertArticle.expertArticles);
	const stateExpertVideos = useSelector(state => state.expertVideo.expertVideos);
	const stateUpdatePillarItem = useSelector(state => state.pillar.pillar);

	const [pillarFormData, setPillarFormData] = useState(stateUpdatePillarItem);
	const [pillarFormArticles, setPillarFormArticles] = useState([{}]);
	const [pillarFormVideos, setPillarFormVideos] = useState([{}]);
	const [pillarFormBenefitsDisabled, setPillarFormBenefitsDisabled] = useState(pillarFormData.benefitsDisabled);

	const dispatch = useDispatch();
	const fieldErrorObject = { fieldName: "", errorMessage: "" };

	const generateArticleIdWithTitle = articleId => {
		const searchedTitle = stateExpertArticles.find((stateArticle) => stateArticle.id === articleId)?.title;
		const title = validations.isEmpty(searchedTitle) ? "Loading.." : searchedTitle;
		return (articleId + " - " + title);
	};
	const generateVideoIdWithTitle = videoId => {
		const searchedTitle = stateExpertVideos.find((stateVideo) => stateVideo.id === videoId)?.title;
		const title = validations.isEmpty(searchedTitle) ? "Loading.." : searchedTitle;
		return (videoId + " - " + title);
	};

	useEffect(() => {
		setPillarFormData(stateUpdatePillarItem);
		const expertArticlesObj = stateUpdatePillarItem?.expertArticles ? stateUpdatePillarItem.expertArticles.map((expertArticle) =>
			({
				expertArticleId: generateArticleIdWithTitle(expertArticle.id),
				articlePreviewImage: expertArticle.previewImage,
				previewTitle: expertArticle.previewTitle
			})
		) : [];
		const expertVideosObj = stateUpdatePillarItem?.expertVideos ? stateUpdatePillarItem.expertVideos.map((expertVideo) =>
			({
				expertVideoId: generateVideoIdWithTitle(expertVideo.id),
				expertVideoTitle: expertVideo.title,
				videoLength: expertVideo.videoLength,
				videoPreviewImage: expertVideo.previewImage
			})
		) : [];
		setPillarFormArticles(expertArticlesObj);
		setPillarFormVideos(expertVideosObj);
	}, [stateUpdatePillarItem, stateExpertArticles, stateExpertVideos]);

	//update pillar
	const updatePillarAPI = async (pillarFormData) => {
		const payload = { data: pillarFormData };
		dispatch(updatePillar(payload));
	};

	const changeBenefitDisabled = () => {
		setPillarFormBenefitsDisabled(!pillarFormBenefitsDisabled);
		setPillarFormData({ ...pillarFormData, "benefitsDisabled": !pillarFormBenefitsDisabled });
	};

	//This function checks required field validations.
	const validatePillarFormData = () => {
		if (validations.isEmpty(pillarFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_INVALID, true);

		if (validations.isTitle(pillarFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(pillarFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_INVALID, true);

		if (validations.isDescription(pillarFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_MAX_CHARACTERS_REACHED_INVALID, true);

		if (!validations.isEmpty(pillarFormData.employerLogo) && validations.isIanacareUrl(pillarFormData.employerLogo))
			return getValidationResultObject("employerLogo", properties.COMMON_EMP_LOGO_URL_INVALID, true);

		for (let pillarFormArticle of pillarFormArticles) {
			if (validations.isEmpty(pillarFormArticle.expertArticleId))
				return getValidationResultObject("expertArticleId", properties.CONTENT_EXPE_ARTICLE_ID_INVALID, true);

			if (validations.isEmpty(pillarFormArticle.articlePreviewImage) || validations.isIanacareUrl(pillarFormArticle.articlePreviewImage))
				return getValidationResultObject("articlePreviewImage", properties.CONTENT_EXPE_ARTICLE_PREVIEW_IMAGE_URL_INVALID, true);

			if (validations.isEmpty(pillarFormArticle.previewTitle))
				return getValidationResultObject("previewTitle", properties.CONTENT_EXPE_PREVIEW_TITLE_INVALID, true);

			if (validations.isTitle(pillarFormArticle.previewTitle))
				return getValidationResultObject("previewTitle", properties.COMMON_PREVIEW_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);
		}

		for (let pillarFormVideo of pillarFormVideos) {
			if (validations.isEmpty(pillarFormVideo.expertVideoId))
				return getValidationResultObject("expertVideoId", properties.CONTENT_EXPE_VIDEO_ID_INVALID, true);

			if (validations.isEmpty(pillarFormVideo.expertVideoTitle))
				return getValidationResultObject("expertVideoTitle", properties.COMMON_VIDEO_TITLE_INVALID, true);

			if (validations.isTitle(pillarFormVideo.expertVideoTitle))
				return getValidationResultObject("expertVideoTitle", properties.COMMON_VIDEO_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

			if (validations.isEmpty(pillarFormVideo.videoLength) || validations.isVideoLength(pillarFormVideo.videoLength))
				return getValidationResultObject("videoLength", properties.CONTENT_EXPE_VIDEO_LENGTH_INVALID, true);

			if (validations.isEmpty(pillarFormVideo.videoPreviewImage) || validations.isIanacareUrl(pillarFormVideo.videoPreviewImage))
				return getValidationResultObject("videoPreviewImage", properties.CONTENT_EXPE_VIDEO_PREVIEW_IMAGE_URL_INVALID, true);

		}

		return { fieldErrorObject, validationsEnabled: false };
	};

	//This function calls expert article form add new fields
	const addExpertArticleFormFields = () => {
		setPillarFormArticles([...pillarFormArticles, { expertArticleId: "", articlePreviewImage: "", previewTitle: "" }]);
	};

	//This function calls expert article form remove new fields
	const removeExpertArticleFormFields = (i) => {
		const newArticles = [...pillarFormArticles];
		newArticles.splice(i, 1);
		setPillarFormArticles(newArticles);
		setPillarFormData({ ...pillarFormData, "expertArticles": newArticles });
	};

	//This function calls expert article form onchange
	const changeExpertArticleFormFields = (i, e) => {
		const newFormValues = [...pillarFormArticles];
		newFormValues[i][e.target.name] = e.target.value;
		if (validations.isEmpty(e.target.name)) {
			const selectedArticle = stateExpertArticles?.find(expertArticle => expertArticle.id === splitByHyphenAndTrim(e.target.innerHTML));
			if (validations.isEmpty(selectedArticle)) {
				newFormValues[i]["expertArticleId"] = null;
				newFormValues[i]["articlePreviewImage"] = null;
				newFormValues[i]["previewTitle"] = null;
			} else {
				newFormValues[i]["expertArticleId"] = selectedArticle?.id + "-" + selectedArticle?.title;
				newFormValues[i]["articlePreviewImage"] = selectedArticle?.previewImage;
				newFormValues[i]["previewTitle"] = selectedArticle?.previewTitle;
			}
		} else {
			newFormValues[i][e.target.name] = e.target.value;
		}
		setPillarFormArticles(newFormValues);
		setPillarFormData({ ...pillarFormData, "expertArticles": newFormValues });
	};

	//This function calls expert video form add new fields
	const addExpertVideoFormFields = () => {
		setPillarFormVideos([...pillarFormVideos, { expertVideoId: "", expertVideoTitle: "", videoLength: "", videoPreviewImage: "" }]);
	};

	//This function calls expert video form remove fields
	const removeExpertVideoFormFields = (i) => {
		const newVideos = [...pillarFormVideos];
		newVideos.splice(i, 1);
		setPillarFormVideos(newVideos);
		setPillarFormData({ ...pillarFormData, "expertVideos": newVideos });
	};

	//This function calls expert video form onchange
	const changeExpertVideoFormFields = (i, e) => {
		e.persist();
		const newFormValues = [...pillarFormVideos];
		if (validations.isEmpty(e.target.name)) {
			const selectedVideo = stateExpertVideos?.find(expertVideo => expertVideo.id === splitByHyphenAndTrim(e.target.innerHTML));
			if (validations.isEmpty(selectedVideo)) {
				newFormValues[i]["expertVideoId"] = null;
				newFormValues[i]["expertVideoTitle"] = null;
				newFormValues[i]["videoLength"] = null;
				newFormValues[i]["videoPreviewImage"] = null;
			} else {
				newFormValues[i]["expertVideoId"] = selectedVideo?.id + "-" + selectedVideo?.title;
				newFormValues[i]["expertVideoTitle"] = selectedVideo?.title;
				newFormValues[i]["videoLength"] = selectedVideo?.videoLength;
				newFormValues[i]["videoPreviewImage"] = selectedVideo?.previewImage;
			}
		} else {
			newFormValues[i][e.target.name] = e.target.value;
		}
		setPillarFormVideos(newFormValues);
		setPillarFormData({ ...pillarFormData, "expertVideos": newFormValues });
	};

	//This function calls when user entering data to input fields and store data in the state after validation.
	const onChange = (event) => {
		event.persist();
		const name = event.target.name;
		const val = event.target.value;
		setPillarFormData({ ...pillarFormData, [name]: val });
	};

	//This function calls when user submit data.
	const onSubmit = (event) => {
		let pillarDataObj = {};
		if (event) event.preventDefault();
		if (validations.isEmpty(fieldErrorObject.errorMessage) && Object.keys(pillarFormData).length !== 0) {
			pillarDataObj = pillarFormData;
			//Remove hyphen from id before submission
			pillarDataObj.expertArticles = pillarFormArticles.map((expertArticle) => ({
				"id": splitByHyphenAndTrim(expertArticle.expertArticleId),
				"previewImage": expertArticle.articlePreviewImage,
				"previewTitle": expertArticle.previewTitle
			}));
			pillarDataObj.expertVideos = pillarFormVideos.map((expertVideo) => ({
				"id": splitByHyphenAndTrim(expertVideo.expertVideoId),
				"title": expertVideo.expertVideoTitle,
				"videoLength": expertVideo.videoLength,
				"previewImage": expertVideo.videoPreviewImage
			}));
			updatePillarAPI(pillarDataObj);
		} else {
			return;
		}
	};

	return {
		stateEmployers, pillarFormData, pillarFormArticles, pillarFormVideos, pillarFormBenefitsDisabled, onChange, onSubmit, validatePillarFormData, changeBenefitDisabled,
		addExpertVideoFormFields, changeExpertVideoFormFields, removeExpertVideoFormFields, addExpertArticleFormFields, changeExpertArticleFormFields, removeExpertArticleFormFields
	};
};

export default useUpdatePillarAction;
