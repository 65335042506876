import React from "react";
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";

import * as properties from "../../../../lib/properties/properties";
import { logoutWithRedirect } from "../../../../services/cognito/cognitoUtil";
import LabelButton from "../../Button/LabelButton";
import Popup from "../../Popup";
import { resetState } from "../../../../state/user/signout";

import "./style.scss";

const LogoutConfirmationPopup = ({ openPopup, setOpenPopup, showCancelButton }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	/**
   * This function executes when user confirmed logout.
   */
	const logOutUser = () => {
		dispatch(resetState());
		localStorage.setItem("isLoggedIn", false);
		logoutWithRedirect(history);
	};

	return (
		<Grid container spacing={2}>
			<Grid item md={12}>
				<div className=" ian-vw-100 ian-position-relative notModal">
					<Grid item md={4}>
						<Popup
							title={properties.LOGOUT_CONFIRMATION_POPUP_TITLE}
							openPopup={openPopup}
							setOpenPopup={setOpenPopup}
							isDisplyaCancelBtn={showCancelButton}
							ianTitleClass={true}
							logoutModalPopup={true}
						>
							<Grid>
								<Grid item md={12} xs={12}>
									<div className="ian-logout">
										<LabelButton
											className="submit-btn"
											label={properties.LOGOUT_CONFIRMATION_POPUP_BUTTON_TITLE}
											type="submit"
											onClick={logOutUser}
											showProgress={false}
										/>
									</div>
								</Grid>
							</Grid>
						</Popup>
					</Grid>
				</div>
			</Grid>
			<Grid item md={6}></Grid>
		</Grid>
	);
};

export default LogoutConfirmationPopup;
