import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { getPillarAllApi } from "../../../services/api/pillarService";

//Action(s)
export const getPillarAll = (payload) => ({
	type: TYPES.GET_PILLAR_ALL,
	payload: payload
});
const getPillarAllSuccess = (payload) => ({
	type: TYPES.GET_PILLAR_ALL_SUCCESS,
	payload: payload
});
const getPillarAllError = (error) => ({
	type: TYPES.GET_PILLAR_ALL_ERROR,
	payload: { error }
});
export const cachePillarSearchedData = (payload) => ({
	type: TYPES.CACHE_PILLAR_SEARCHED_DATA,
	payload: payload
});
export const resetPillarState = () => ({
	payload: null,
	type: TYPES.CLEAR_PILLAR
});

//Saga(s)
export function* getPillarAllSaga({ payload }) {
	try {
		const response = yield call(getPillarAllApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(getPillarAllSuccess(response.data));
		}
	} catch (error) {
		yield put(getPillarAllError(error));
	}
}
