import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import * as validations from "../../../lib/validator";
import { DEFAULT_PAGE_LIMIT_20, DEFAULT_PAGE_NUMBER } from "../../../lib/constants";
import { cacheResourcePlanSearchedData, getResourcePlanAll, resetResourcePlanState } from "../../../state/employers/resourcePlan/getResourcePlanAll";

const useResourcePlanAction = () => {

	const stateEmployer = useSelector(state => state.employer);
	const stateSearchedResourcePlan = useSelector(state => state.resourcePlan.search);
	const [resourcePlanSearchedEmployer, setResourcePlanSearchedEmployer] = useState({ employerId: stateSearchedResourcePlan?.employerId });

	const dispatch = useDispatch();
	const history = useHistory();

	const getResourcePlanData = async () => {
		const payload = { employerId: resourcePlanSearchedEmployer.employerId, pagelimit: DEFAULT_PAGE_LIMIT_20, pagenumber: DEFAULT_PAGE_NUMBER };
		dispatch(getResourcePlanAll(payload));
		//set the previous Search results
		dispatch(cacheResourcePlanSearchedData({ employerId: resourcePlanSearchedEmployer.employerId }));
	};

	//This function checks field validations.
	const isValidData = () => {
		return !validations.isEmpty(resourcePlanSearchedEmployer.employerId);
	};

	//Get clear resource plan state
	useEffect(() => { dispatch(resetResourcePlanState()); }, []);

	//This function call search data
	const onClickSearch = () => {
		getResourcePlanData();
	};

	//This function redirects to add resource plan form
	const onClickAddForm = () => {
		history.push("/employers?tab=addResourcePlan");
	};

	//This function calls when user entering data to input fields and store data in the state after validation
	const onChange = (event) => {
		event.persist();
		let name = event.target.name;
		let val = event.target.value;
		dispatch(resetResourcePlanState());
		dispatch(cacheResourcePlanSearchedData({ employerId: "" }));
		setResourcePlanSearchedEmployer({ ...resourcePlanSearchedEmployer, [name]: val });
	};

	//Return object and function
	return { stateEmployer, resourcePlanSearchedEmployer, isValidData, onClickAddForm, onClickSearch, onChange };
};

export default useResourcePlanAction;
