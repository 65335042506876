import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { splitByHyphenAndTrim } from "../../../../lib/util/commonUtil";
import * as properties from "../../../../lib/properties/properties";
import * as validations from "../../../../lib/validator";
import { getValidationResultObject } from "../../../../lib/validator/util";
import { NO_PAGE_LIMIT, NO_PAGE_COUNT } from "../../../../lib/constants";

import { getResourcePlanAll } from "../../../../state/employers/resourcePlan/getResourcePlanAll";
import { getExpertArticleAll } from "../../../../state/expertContents/expertArticle/getExpertArticleAll";
import { getExpertVideoAll } from "../../../../state/expertContents/expertVideo/getExpertVideoAll";
import { addPillar } from "../../../../state/pillars/pillar/addPillar";
import { resetPillarState } from "../../../../state/pillars/pillar/getPillarAll";

const useAddPillarAction = () => {

	const stateEmployers = useSelector(state => state.employer.eligibleEmployers);
	const stateExpertArticles = useSelector(state => state.expertArticle.expertArticles);
	const stateExpertVideos = useSelector(state => state.expertVideo.expertVideos);

	const [pillarFormData, setPillarFormData] = useState({ benefitsDisabled: true });
	const [pillarFormArticles, setPillarFormArticles] = useState([{ expertArticleId: "", articlePreviewImage: "", previewTitle: "" }]);
	const [pillarFormVideos, setPillarFormVideos] = useState([{ expertVideoId: "", expertVideoTitle: "", videoLength: "", videoPreviewImage: "" }]);
	const [pillarFormBenefitsDisabled, setPillarFormBenefitsDisabled] = useState(true);
	const [pillarFormValidationsEnabled, setPillarFormValidationsEnabled] = useState(false);

	const dispatch = useDispatch();
	const fieldErrorObject = { fieldName: "", errorMessage: "" };

	//Clear pillar state
	useEffect(() => { dispatch(resetPillarState()); }, []);

	// This function loads all needed API data.
	const loadAPIData = async (value) => {
		const payload = { employerId: value, pagelimit: NO_PAGE_LIMIT, pagenumber: NO_PAGE_COUNT };
		dispatch(getResourcePlanAll(payload));
		dispatch(getExpertArticleAll(payload));
		dispatch(getExpertVideoAll(payload));
	};

	// This function creates pillar.
	const createPillar = async (pllarData) => {
		dispatch(addPillar(pllarData));
	};

	//This function validates pillar form data.
	const validatePillarFormData = () => {
		if (validations.isEmpty(pillarFormData.employerId))
			return getValidationResultObject("employerId", properties.COMMON_EMPLOYER_INVALID, pillarFormValidationsEnabled);

		if (validations.isEmpty(pillarFormData.employerResourcePlanId))
			return getValidationResultObject("employerResourcePlanId", properties.COMMON_RESOURCE_PLAN_INVALID, true);

		if (validations.isEmpty(pillarFormData.resourceType))
			return getValidationResultObject("resourceType", properties.COMMON_RESOURCE_TYPE_INVALID, true);

		if (validations.isEmpty(pillarFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_INVALID, true);

		if (validations.isTitle(pillarFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(pillarFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_INVALID, true);

		if (validations.isDescription(pillarFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_MAX_CHARACTERS_REACHED_INVALID, true);

		if (!validations.isEmpty(pillarFormData.employerLogo) && validations.isIanacareUrl(pillarFormData.employerLogo))
			return getValidationResultObject("employerLogo", properties.COMMON_EMP_LOGO_URL_INVALID, true);

		for (let pillarFormArticle of pillarFormArticles) {
			if (validations.isEmpty(pillarFormArticle.expertArticleId))
				return getValidationResultObject("expertArticleId", properties.CONTENT_EXPE_ARTICLE_ID_INVALID, true);

			if (validations.isEmpty(pillarFormArticle.articlePreviewImage) || validations.isIanacareUrl(pillarFormArticle.articlePreviewImage))
				return getValidationResultObject("articlePreviewImage", properties.CONTENT_EXPE_ARTICLE_PREVIEW_IMAGE_URL_INVALID, true);

			if (validations.isEmpty(pillarFormArticle.previewTitle))
				return getValidationResultObject("previewTitle", properties.CONTENT_EXPE_PREVIEW_TITLE_INVALID, true);

			if (validations.isTitle(pillarFormArticle.previewTitle))
				return getValidationResultObject("previewTitle", properties.COMMON_PREVIEW_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

		}

		for (let pillarFormVideo of pillarFormVideos) {
			if (validations.isEmpty(pillarFormVideo.expertVideoId))
				return getValidationResultObject("expertVideoId", properties.CONTENT_EXPE_VIDEO_ID_INVALID, true);

			if (validations.isEmpty(pillarFormVideo.expertVideoTitle))
				return getValidationResultObject("expertVideoTitle", properties.COMMON_VIDEO_TITLE_INVALID, true);

			if (validations.isTitle(pillarFormVideo.expertVideoTitle))
				return getValidationResultObject("expertVideoTitle", properties.COMMON_VIDEO_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

			if (validations.isEmpty(pillarFormVideo.videoLength) || validations.isVideoLength(pillarFormVideo.videoLength))
				return getValidationResultObject("videoLength", properties.CONTENT_EXPE_VIDEO_LENGTH_INVALID, true);

			if (validations.isEmpty(pillarFormVideo.videoPreviewImage) || validations.isIanacareUrl(pillarFormVideo.videoPreviewImage))
				return getValidationResultObject("videoPreviewImage", properties.CONTENT_EXPE_VIDEO_PREVIEW_IMAGE_URL_INVALID, true);

		}

		return { fieldErrorObject, pillarFormValidationsEnabled: false };
	};

	// This function calls when the user changes benefit disabled toggle
	const changeBenefitDisabled = () => {
		setPillarFormBenefitsDisabled(!pillarFormBenefitsDisabled);
		setPillarFormData({ ...pillarFormData, "benefitsDisabled": !pillarFormBenefitsDisabled });
		setPillarFormValidationsEnabled(true);
	};

	// This function calls when the user adds expert article form field
	const addExpertArticleFormFields = () => {
		setPillarFormArticles([...pillarFormArticles, { expertArticleId: "", articlePreviewImage: "", previewTitle: "" }]);
	};

	// This function calls when the user removes expert article form field
	const removeExpertArticleFormFields = (i) => {
		const newArticles = [...pillarFormArticles];
		newArticles.splice(i, 1);
		setPillarFormArticles(newArticles);
		setPillarFormData({ ...pillarFormData, "expertArticles": newArticles });
	};

	// This function calls when the user changes expert article form field
	const changeExpertArticleFormFields = (i, e) => {
		const newFormValues = [...pillarFormArticles];
		if (validations.isEmpty(e.target.name)) {
			const selectedArticle = stateExpertArticles?.find(expertArticle => expertArticle.id === splitByHyphenAndTrim(e.target.innerHTML));
			if (validations.isEmpty(selectedArticle)) {
				newFormValues[i]["expertArticleId"] = null;
				newFormValues[i]["articlePreviewImage"] = null;
				newFormValues[i]["previewTitle"] = null;
			} else {
				newFormValues[i]["expertArticleId"] = selectedArticle?.id + " - " + selectedArticle?.title;
				newFormValues[i]["articlePreviewImage"] = selectedArticle?.previewImage;
				newFormValues[i]["previewTitle"] = selectedArticle?.previewTitle;
			}
		} else {
			newFormValues[i][e.target.name] = e.target.value;
			// set pillarFormValidationsEnabled to true when expert article id field not change
			setPillarFormValidationsEnabled(true);
		}
		setPillarFormArticles(newFormValues);
		setPillarFormData({ ...pillarFormData, "expertArticles": newFormValues });
	};

	//This function calls expert video form add new fields
	const addExpertVideoFormFields = () => {
		setPillarFormVideos([...pillarFormVideos, { expertVideoId: "", expertVideoTitle: "", videoLength: "", videoPreviewImage: "" }]);
	};

	//This function calls expert video form remove fields
	const removeExpertVideoFormFields = (i) => {
		const newVideos = [...pillarFormVideos];
		newVideos.splice(i, 1);
		setPillarFormVideos(newVideos);
		setPillarFormData({ ...pillarFormData, "expertVideos": newVideos });
	};

	//This function calls expert video form onchange
	const changeExpertVideoFormFields = (i, e) => {
		const newFormValues = [...pillarFormVideos];
		if (validations.isEmpty(e.target.name)) {
			const selectedVideo = stateExpertVideos?.find(expertVideo => expertVideo.id === splitByHyphenAndTrim(e.target.innerHTML));
			if (validations.isEmpty(selectedVideo)) {
				newFormValues[i]["expertVideoId"] = null;
				newFormValues[i]["expertVideoTitle"] = null;
				newFormValues[i]["videoLength"] = null;
				newFormValues[i]["videoPreviewImage"] = null;
			} else {
				newFormValues[i]["expertVideoId"] = selectedVideo?.id + " - " + selectedVideo?.title;
				newFormValues[i]["expertVideoTitle"] = selectedVideo?.title;
				newFormValues[i]["videoLength"] = selectedVideo?.videoLength;
				newFormValues[i]["videoPreviewImage"] = selectedVideo?.previewImage;
			}
		} else {
			newFormValues[i][e.target.name] = e.target.value;
			// set pillarFormValidationsEnabled to true when expert article id field not change
			setPillarFormValidationsEnabled(true);
		}
		setPillarFormVideos(newFormValues);
		setPillarFormData({ ...pillarFormData, "expertVideos": newFormValues });
	};

	// This function calls when the user changes form data.
	const onChange = (event) => {
		event.persist();
		const name = event.target.name;
		const val = event.target.value;
		if (name !== "employerId") {
			//set pillarFormValidationsEnabled to true when employer id field not change 
			setPillarFormValidationsEnabled(true);
		} else {
			if (validations.isEmpty(val)) {
				//set pillarFormValidationsEnabled to true when employer id change with invalid val 
				setPillarFormValidationsEnabled(true);
			} else {
				loadAPIData(val);
				pillarFormData.employerResourcePlanId = "";
			}
			setPillarFormArticles([{ expertArticleId: "", articlePreviewImage: "", previewTitle: "" }]);
			setPillarFormVideos([{ expertVideoId: "", expertVideoTitle: "", videoLength: "", videoPreviewImage: "" }]);
		}
		setPillarFormData({ ...pillarFormData, [name]: val });
	};

	//This function calls when user submit data.
	const onSubmit = (event) => {
		let pillarDataObj = {};
		if (event) event.preventDefault();
		if (validations.isEmpty(fieldErrorObject.errorMessage) && Object.keys(pillarFormData).length !== 0) {
			pillarDataObj = pillarFormData;
			pillarDataObj.expertArticles = pillarFormData.expertArticles.map((expertArticle) => ({
				"id": splitByHyphenAndTrim(expertArticle.expertArticleId) || expertArticle.id,
				"previewImage": expertArticle.articlePreviewImage || expertArticle.previewImage,
				"previewTitle": expertArticle.previewTitle
			}));
			pillarDataObj.expertVideos = pillarFormData.expertVideos.map((expertVideo) => ({
				"id": splitByHyphenAndTrim(expertVideo.expertVideoId) || expertVideo.id,
				"title": expertVideo.expertVideoTitle || expertVideo.title,
				"videoLength": expertVideo.videoLength,
				"previewImage": expertVideo.videoPreviewImage || expertVideo.previewImage
			}));
			createPillar(pillarDataObj);
		} else {
			return;
		}
	};

	return {
		stateEmployers, pillarFormData, pillarFormArticles, pillarFormVideos, pillarFormBenefitsDisabled, onChange, onSubmit, validatePillarFormData, changeBenefitDisabled,
		addExpertVideoFormFields, changeExpertVideoFormFields, removeExpertVideoFormFields, addExpertArticleFormFields, changeExpertArticleFormFields, removeExpertArticleFormFields
	};
};

export default useAddPillarAction;
