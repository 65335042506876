// HTTP Headers
export const HEADER_KEY_AUTHORIZATION = "Authorization";
export const HEADER_KEY_API_KEY = "x-api-key";
export const HEADER_KEY_ACCEPT = "Accept";
export const HEADER_VAL_ACCEPT = "*/*";
export const HEADER_KEY_PLATFORM = "app-platform";
export const HEADER_VAL_PLATFORM = "WEB";
export const HEADER_KEY_CAPTCHA = "captcha";
export const HEADER_APP_VERSION = "app-version";
export const HEADER_APP_VERSION_VAL = "2.0.0";

// Domain attributes
export const ID = "id";
export const EXPERT_ARTICLE_ID = "expertArticleId";
export const EXPERT_VIDEO_ID = "expertVideoId";

// pagination
export const DEFAULT_PAGE_LIMIT = 10;
export const DEFAULT_PAGE_LIMIT_20 = 20;
export const DEFAULT_PAGE_LIMIT_50 = 50;
export const DEFAULT_PAGE_NUMBER = 1;
export const NO_PAGE_LIMIT = 0;
export const NO_PAGE_COUNT = 0;
