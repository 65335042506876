import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { getExpertArticleApi } from "../../../services/api/expertArticleService";

//Action(s)
export const getExpertArticle = (payload) => ({
	type: TYPES.GET_EXPERT_ARTICLE,
	payload: payload
});
const getExpertArticleSuccess = (payload) => ({
	type: TYPES.GET_EXPERT_ARTICLE_SUCCESS,
	payload: payload
});
const getExpertArticleError = (error) => ({
	type: TYPES.GET_EXPERT_ARTICLE_ERROR,
	payload: { error }
});

//Saga(s)
export function* getExpertArticleSaga({ payload }) {
	try {
		const response = yield call(getExpertArticleApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(getExpertArticleSuccess(response.data));
		}
	} catch (error) {
		yield put(getExpertArticleError(error));
	}
}
