import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Grid } from "@mui/material";

import * as properties from "../../../lib/properties/properties";
import { logoutWithRedirect } from "../../../services/cognito/cognitoUtil";
import { resetState } from "../../../state/user/signout";
import LabelButton from "../Button/LabelButton";
import Popup from "../Popup";

import "./style.scss";

const AuthErrorPopup = ({ openPopup, setOpenPopup, showCancelButton }) => {

	const dispatch = useDispatch();
	const history = useHistory();

	const onClickOk = () => { 
		dispatch(resetState());
		setOpenPopup(false);
		localStorage.setItem("isLoggedIn", false);
		logoutWithRedirect(history);
		window.location.href = `https://${process.env.REACT_APP_AWS_AMPLIFY_AUTH_DOMAIN}/logout?client_id=${process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID}&logout_uri=${process.env.REACT_APP_URL}`;
	};

	return (
		<Grid container spacing={2}>
			<Grid item md={12}>
				<div className="ian-vw-100 ian-position-relative notModal">
					<Grid item md={4}>
						<Popup
							title={properties.SIGNIN_UNAUTHORIZED_MESSAGE}
							openPopup={openPopup}
							setOpenPopup={setOpenPopup}
							isDisplyaCancelBtn={showCancelButton}
							ianTitleClass={true}
						>
							<Grid>
								<Grid item md={12} xs={12}>
									<div className="ian-auth-error">
										<LabelButton
											className="submit-btn"
											label={properties.ERROR_POPUP_BUTTON_TITLE}
											type="submit"
											onClick={onClickOk}
											showProgress={false}
										/>
									</div>
								</Grid>
							</Grid>
						</Popup>
					</Grid>
				</div>
			</Grid>
			<Grid item md={6}></Grid>
		</Grid>
	);
};

export default AuthErrorPopup;
