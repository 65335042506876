import { call, put } from "redux-saga/effects";
import * as TYPES from "./types";
import { HTTP_CODE } from "../../../lib/enums/httpData";

import { updateResourcePlanApi } from "../../../services/api/employerService";

//Action(s)
export const updateResourcePlan = (resourcePlanData) => ({
	type: TYPES.UPDATE_RESOURCE_PLAN,
	payload: resourcePlanData
});
const updateResourcePlanSuccess = (payload) => ({
	type: TYPES.UPDATE_RESOURCE_PLAN_SUCCESS,
	payload
});
const updateResourcePlanError = (error) => ({
	type: TYPES.UPDATE_RESOURCE_PLAN_ERROR,
	payload: error
});

//Saga(s)
export function* updateResourcePlanSaga(resourcePlan) {
	try {
		const response = yield call(updateResourcePlanApi, resourcePlan.payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(updateResourcePlanSuccess(response.data));
		}
	} catch (error) {
		yield put(updateResourcePlanError(error));
	}
}

