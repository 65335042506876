import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Grid } from "@mui/material";

import SelectField from "../../common/SelectField";
import LabelButton from "../../common/Button/LabelButton";
import DataTable from "../../common/DataTable";
import * as properties from "../../../lib/properties/properties";
import { isEmpty } from "../../../lib/validator";
import { NO_PAGE_LIMIT, NO_PAGE_COUNT, DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_LIMIT_50 } from "../../../lib/constants";
import { RESOURCE_TYPE } from "../../../lib/enums/resourceType";
import { getPillarAll, resetPillarState } from "../../../state/pillars/pillar/getPillarAll";
import { getEmployerAll } from "../../../state/employers/employer/getEmployerAll";

import usePillarsAction from "./usePillarsAction";

const Pillar = () => {

	//Get pillar data from state
	const statePillar = useSelector(state => state.pillar);

	const dispatch = useDispatch();

	//Custom hook for this form action
	const { stateEmployer, pillarSearchedEmployer, isValidData, onClickAddForm, onClickSearch, onChange } = usePillarsAction();

	const getEmployerData = async () => {
		const payload = { pagelimit: NO_PAGE_LIMIT, pagenumber: NO_PAGE_COUNT };
		dispatch(getEmployerAll(payload));
	};

	const getPillarData = async () => {
		const payload = { employerId: pillarSearchedEmployer.employerId, pagelimit: DEFAULT_PAGE_LIMIT_50, pagenumber: DEFAULT_PAGE_NUMBER };
		dispatch(getPillarAll(payload));
	};

	useEffect(() => {
		dispatch(resetPillarState());
		stateEmployer.isEmployerChanged ? getEmployerData() : "";
		!isEmpty(statePillar?.search?.employerId) && !isEmpty(document.getElementById("employerId")?.value) ? getPillarData() : null;
	}, [stateEmployer.eligibleEmployers]);

	//pillar table header items
	const headerTitle = ["Id", "Resource Plan", "Resource Type", "Title", "Action"];

	//pillar table body data
	const pillarTableData = statePillar.pillars && !isEmpty(pillarSearchedEmployer.employerId)
		&& !isEmpty(stateEmployer.eligibleEmployers) && !stateEmployer.loading && !isEmpty(document.getElementById("employerId")?.value) ?
		statePillar.pillars.map((
			{
				id,
				employer,
				resourcePlan,
				resourceType,
				title
			}) => ({
			value0: id,
			value1: employer,
			value2: resourcePlan?.employerResourcePlanName,
			value3: RESOURCE_TYPE[resourceType],
			value4: title
		})) : [];

	return (
		<div className="tabSectionRight ian-position-relative">
			<div className="ian-centeredSection">
				<div className="ian-form-container ian-position-relative">
					<Grid container spacing={0} px={0} >
						<Grid item md={6} xs={12}>
							<SelectField
								label={properties.COMMON_EMPLOYER_SEARCH_LABEL}
								name="employerId"
								id="employerId"
								options={stateEmployer.eligibleEmployers}
								value={pillarSearchedEmployer.employerId}
								onChange={onChange}
								selectOption={properties.COMMON_SELECT_OPTION_EMPLOYER_LABEL}
							/>
						</Grid>
						<Grid item md={3} xs={12}>
							<div className="searchBtn-wthSelect">
								<LabelButton color="inherit" label={properties.COMMON_SEARCH_LABEL} type="submit" disabled={!isValidData() || isEmpty(document.getElementById("employerId")?.value)} showProgress={false} onClick={onClickSearch} />
							</div>
						</Grid>
						<Grid item md={3} xs={12}>
							<div className="add-button">
								<LabelButton color="inherit" label={properties.COMMON_ADD_NEW_LABEL} type="submit" disabled={statePillar.loading} showProgress={false} onClick={onClickAddForm} />
							</div>
						</Grid>
					</Grid>

					<DataTable
						Data={pillarTableData}
						headerTitle={headerTitle}
						updateForm={"/pillars?tab=updatePillar"}
						dataLoad={statePillar.loading}
						noDataLabel={properties.COMMON_NO_DATA_SELECT_AND_SEARCH_LABEL} />
				</div>
			</div>
		</div>
	);
};

export default Pillar;
