import { ADD_EXPERT_VIDEO_URL, GET_EXPERT_VIDEO_URL, GET_EXPERT_VIDEO_ALL_URL, UPDATE_EXPERT_VIDEO_URL } from "./endPoints";
import networkClient from "./networkClient";
import { HTTP_METHOD } from "../../lib/enums/httpData";

export function addExpertVideoApi(expertVideoData) {
	return networkClient().request({
		method: HTTP_METHOD.POST,
		url: ADD_EXPERT_VIDEO_URL,
		data: expertVideoData
	});
}

export function getExpertVideoApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.GET,
		url: GET_EXPERT_VIDEO_URL.replace("{videoId}", payload.videoId)
	});
}

export function getExpertVideoAllApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.GET,
		url: GET_EXPERT_VIDEO_ALL_URL.replace("{employerid}", payload.employerId).replace("{pagenumber}", payload.pagenumber).replace("{pagelimit}", payload.pagelimit)
	});
}

export function updateExpertVideoApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.PUT,
		url: UPDATE_EXPERT_VIDEO_URL.replace("{expertVideoId}", payload.videoId),
		data: payload.data
	});
}


