import React, { useState, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { Auth } from "aws-amplify";

import { isCognitoCallbackUrl } from "../lib/validator";
import AuthErrorPopup from "../components/common/AuthErrorPopup";

/* Custom hook to handle authentication status and loading state */
const useAuthentication = () => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function checkAuthStatus() {
			try {
				/* Check if user is authenticated */
				await Auth.currentAuthenticatedUser();
				setIsAuthenticated(true);
			} catch (error) {
				setIsAuthenticated(false);
			} finally {
				/* Set loading to false once authentication status is determined */
				setLoading(false);
			}
		}

		/* Initial check of authentication status */
		checkAuthStatus();

		/* Periodically refresh authentication status */
		const interval = setInterval(checkAuthStatus, 30000); /* Check every 30 seconds */
		return () => clearInterval(interval); /* Cleanup interval on component unmount */
	}, []);

	/* Return authentication status and loading state */
	return { isAuthenticated, loading };
};

/* Component for routes that require authentication */
export const AuthRoute = ({ ...props }) => {
	const { isAuthenticated, loading } = useAuthentication();
	const [openPopup, setOpenPopup] = useState(false);

	useEffect(() => {
		/* Get the URL of the current page */
		if (isCognitoCallbackUrl(window.location.href)) {
			/* Set openPopup state to true to open the AuthErrorPopup */
			setOpenPopup(true);
		}
	}, []); /* Empty dependency array to run the effect only once when the component mounts */

	if (loading) {
		/* Render nothing while authentication status is being checked */
		return null;
	}

	if (isAuthenticated) {
		/* Redirect to the general route if user is authenticated */
		return <Redirect to="/general" />;
	}

	/* Render the specified route if user is not authenticated */
	return (
		<>
			{/* Render AuthErrorPopup if openPopup state is true */}
			{openPopup && <AuthErrorPopup openPopup={openPopup} setOpenPopup={setOpenPopup} />}
			<Route {...props} />
		</>
	);
};

/* Component for private routes that require authentication and authorization */
export const PrivateRoute = ({ component, ...props }) => {
	const { isAuthenticated, loading } = useAuthentication();
	const [openPopup, setOpenPopup] = useState(true);

	if (loading) {
		/* Render nothing while authentication status is being checked */
		return null;
	}

	if (!isAuthenticated) {
		/* Render authentication error popup if user is not authenticated */
		return <AuthErrorPopup openPopup={openPopup} setOpenPopup={setOpenPopup} />;
	} else {
		/* Render the specified component if user is authenticated */
		return <Route component={component} {...props} />;
	}
};
