import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, LinearProgress } from "@mui/material";

import ToggleSwitch from "../../../common/ToggleSwitch";
import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import SelectField from "../../../common/SelectField";
import PrimaryButton from "../../../common/Button/PrimaryButton";
import BackButton from "../../../common/Button/BackButton";
import ErrorPopup from "../../../common/ErrorPopup";
import SuccessPopup from "../../../common/SuccessPopup";
import ExpertArticle from "../common/expertArticleSection";
import ExpertVideo from "../common/expertVideoSection";
import * as properties from "../../../../lib/properties/properties";
import { getRowIdAndEmployerId, getSupportedUrlTooltip } from "../../../../lib/util/commonUtil";
import { NO_PAGE_LIMIT, NO_PAGE_COUNT } from "../../../../lib/constants";
import { ResourceTypeOptions } from "../../../../lib/constants/ResourceTypeOptions";

import { resetPillarState } from "../../../../state/pillars/pillar/getPillarAll";
import { getPillar } from "../../../../state/pillars/pillar/getPillar";
import { getResourcePlanAll } from "../../../../state/employers/resourcePlan/getResourcePlanAll";
import { getExpertArticleAll } from "../../../../state/expertContents/expertArticle/getExpertArticleAll";
import { getExpertVideoAll } from "../../../../state/expertContents/expertVideo/getExpertVideoAll";

import UpdatePillarAction from "./useUpdatePillarAction";

const UpdatePillar = () => {
	const statePillar = useSelector(state => state.pillar);
	const stateResourcePlanOptions = useSelector(state => state.resourcePlan.resourcePlanOptions);

	let history = useHistory();
	const dispatch = useDispatch();
	const urlPillarEmployerPathVariables = getRowIdAndEmployerId();

	//Get Pillar Data
	const getPillarData = async () => {
		const payload = { pillarId: urlPillarEmployerPathVariables[0], employerid: urlPillarEmployerPathVariables[1] };
		dispatch(getPillar(payload));
	};

	// This function loads all needed API data.
	const loadAPIData = async () => {
		const payload = { employerId: urlPillarEmployerPathVariables[1], pagelimit: NO_PAGE_LIMIT, pagenumber: NO_PAGE_COUNT };
		dispatch(getResourcePlanAll(payload));
		dispatch(getExpertArticleAll(payload));
		dispatch(getExpertVideoAll(payload));
	};

	useEffect(() => { getPillarData(); loadAPIData(); }, []);

	//Customer hook for update pillar
	const { stateEmployers, pillarFormData, pillarFormArticles, pillarFormVideos, pillarFormBenefitsDisabled, onChange, onSubmit, validatePillarFormData, changeBenefitDisabled,
		addExpertVideoFormFields, changeExpertVideoFormFields, removeExpertVideoFormFields, addExpertArticleFormFields, changeExpertArticleFormFields, removeExpertArticleFormFields } = UpdatePillarAction();

	//This function executes when error popup ok button clicked.
	const onErrorPopupButtonClicked = () => {
		dispatch(resetPillarState());
	};

	const onClickBackButton = () => {
		history.push("/pillars");
	};

	//This function executes when success popup ok button clicked.
	const onSuccessPopupButtonClicked = () => {
		dispatch(resetPillarState());
		history.push("/pillars");
	};

	return (
		<div>
			<BackButton className="px-5" onClick={onClickBackButton} />
			<div className="tabSectionRight ian-position-relative">
				<div className="ian-centeredSection ian-w-40 ian-position-relative">
					<h5 className="ian-modal-title">
						{properties.PILLAR_UPDATE_TITLE}
					</h5>
					{statePillar.loading && <Box sx={{ width: "100%" }}> <LinearProgress className="ian-linear-progress" /> </Box>}
					<div className="ian-form-container">
						<SelectField
							label={properties.COMMON_EMPLOYER_LABEL}
							name="employerId"
							id="employerId"
							options={stateEmployers}
							selectOption={properties.COMMON_SELECT_OPTION_EMPLOYER_LABEL}
							value={pillarFormData.employerId}
							isDisabled={true}
							onChange={onChange}
						/>
						<SelectField
							label={properties.COMMON_EMPLOYER_RESOURCE_PLAN_LABEL}
							name="employerResourcePlanId"
							id="employerResourcePlanId"
							options={pillarFormData.employerId ? stateResourcePlanOptions : []}
							selectOption={properties.COMMON_SELECT_OPTION_RESOURCE_PLAN_LABEL}
							value={pillarFormData.employerResourcePlanId}
							onChange={onChange}
							isDisabled={true}
						/>
						<SelectField
							label={properties.COMMON_RESOURCE_TYPE_LABEL}
							name="resourceType"
							id="resourceType"
							options={ResourceTypeOptions}
							value={pillarFormData.resourceType}
							onChange={onChange}
							isDisabled={true}
							txtCounterEnabled={false}
						/>
						<TextField
							label={properties.COMMON_TITLE_LABEL}
							placeholder={properties.COMMON_TITLE_PLACEHOLDER}
							name="title"
							id="title"
							type="text"
							value={pillarFormData.title || ""}
							onChange={onChange}
						/>
						<TextArea
							label={properties.COMMON_DESCRIPTION_LABEL}
							placeholder={properties.COMMON_DESCRIPTION_PLACEHOLDER}
							name="description"
							id="description"
							type="text"
							value={pillarFormData.description}
							onChange={onChange}
							characterCount={pillarFormData.description?.length}
						/>
						<TextField
							label={properties.COMMON_EMPLOYER_LOGO_URL_LABEL}
							placeholder={properties.COMMON_EMPLOYER_LOGO_URL_PLACEHOLDER}
							name="employerLogo"
							id="employerLogo"
							type="text"
							value={pillarFormData.employerLogo || ""}
							onChange={onChange}
							toolTipText={getSupportedUrlTooltip()}
						/>
						<ExpertArticle
							expertArticles={pillarFormArticles}
							addExpertArticleFormFields={addExpertArticleFormFields}
							removeExpertArticleFormFields={removeExpertArticleFormFields}
							pillarData={pillarFormData}
							expertArticleFormOnChange={changeExpertArticleFormFields}
						/>
						<br />
						<ExpertVideo
							expertVideos={pillarFormVideos}
							pillarData={pillarFormData}
							addExpertVideoFormFields={addExpertVideoFormFields}
							removeExpertVideoFormFields={removeExpertVideoFormFields}
							expertVideoFormOnChange={changeExpertVideoFormFields}
						/>
						<br />
						<ToggleSwitch label={properties.PILLAR_BENEFITS_LABEL} name="benefitsChecked" checked={!pillarFormBenefitsDisabled} onChange={changeBenefitDisabled} />

						{validatePillarFormData().validationsEnabled ? (
							<div className="ian-error-warning show px-5 mt-5" sx={{ display: "none" }}>
								<p>
									{validatePillarFormData().fieldErrorObject.errorMessage}
								</p>
							</div>
						) : (
							<PrimaryButton
								className="px-5"
								label={properties.COMMON_UPDATE_LABEL}
								disabled={validatePillarFormData().fieldErrorObject.errorMessage || statePillar.loading}
								showProgress={false}
								onClick={onSubmit}
							/>
						)}
					</div>
					{statePillar.error ? <ErrorPopup error={statePillar.error} okButtonAction={onErrorPopupButtonClicked} /> : ""}
					{statePillar.success ? <SuccessPopup
						message={statePillar.pillar ? properties.SUCCESS_POPUP_UPDATE_MESSAGE : null} okButtonAction={onSuccessPopupButtonClicked}
					/> : null}
				</div>
			</div>
		</div>
	);
};

export default UpdatePillar;
