import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Container } from "@mui/material";

import LeftNavigator from "./common/LeftNavigator";
import Pillar from "./pillar";
import AddPillar from "./pillar/addPillar";
import UpdatePillar from "./pillar/updatePillar";
import AuthenticatedLayout from "../common/Layout/AuthenticatedLayout";
import { containsTab } from "../../lib/util/commonUtil";
import { PAGE } from "../../lib/enums/enum";

import "./style.scss";

const Pillars = () => {
	const [selectedIndex, setSelectedIndex] = useState();
	const location = useLocation();

	useEffect(() => {
		switch (containsTab()) {
		case "pillarHome":
			setSelectedIndex(0);
			break;
		case "addPillar":
			setSelectedIndex(1);
			break;
		case "updatePillar":
			setSelectedIndex(2);
			break;
		default:
			setSelectedIndex(0);
			break;
		}
	}, [location]);

	return (
		<AuthenticatedLayout selectedPage={selectedIndex === 0 || selectedIndex === 1 || selectedIndex === 2 ? PAGE.PILLARS : ""}>
			<Container maxWidth="lg" className="el1260W">
				<Grid container spacing={0} px={0} className="contentSection ian-table-content">
					<Grid item xs={12} sm={5} lg={3} className="ian-position-relative  borderRight el20pW">
						<LeftNavigator selectedIndex={selectedIndex}></LeftNavigator>
					</Grid>
					<Grid item xs={12} sm={7} lg={9} className="el80pW">
						{selectedIndex === 0 ? (
							<Pillar />
						) : selectedIndex === 1 ? (
							<AddPillar />
						) : selectedIndex === 2 ? (
							<UpdatePillar />
						) : null}
					</Grid>
				</Grid>
			</Container>
		</AuthenticatedLayout>
	);
};

export default Pillars;
