import React from "react";
import NonAuthenticatedHeader from "../Header/NonAuthenticatedHeader";

const NonAuthenticatedLayout = ({ children, selectedPage }) => {
	return (
		<React.Fragment>
			<NonAuthenticatedHeader selectedPage={selectedPage}/>
			<div className="App">
				{children}
			</div>
		</React.Fragment>
	);
};
export default NonAuthenticatedLayout;
