export const ADD_EXPERT_VIDEO = "ADD_EXPERT_VIDEO";
export const ADD_EXPERT_VIDEO_SUCCESS = "ADD_EXPERT_VIDEO_SUCCESS";
export const ADD_EXPERT_VIDEO_ERROR = "ADD_EXPERT_VIDEO_ERROR";

export const  GET_EXPERT_VIDEO = "GET_EXPERT_VIDEO";
export const  GET_EXPERT_VIDEO_SUCCESS = "GET_EXPERT_VIDEO_SUCCESS";
export const  GET_EXPERT_VIDEO_ERROR = "GET_EXPERT_VIDEO_ERROR";

export const  GET_EXPERT_VIDEO_ALL = "GET_EXPERT_VIDEO_ALL";
export const  GET_EXPERT_VIDEO_ALL_SUCCESS = "GET_EXPERT_VIDEO_ALL_SUCCESS";
export const  GET_EXPERT_VIDEO_ALL_ERROR = "GET_EXPERT_VIDEO_ALL_ERROR";

export const UPDATE_EXPERT_VIDEO = "UPDATE_EXPERT_VIDEO";
export const UPDATE_EXPERT_VIDEO_SUCCESS = "UPDATE_EXPERT_VIDEO_SUCCESS";
export const UPDATE_EXPERT_VIDEO_ERROR = "UPDATE_EXPERT_VIDEO_ERROR";

export const CACHE_EXPERT_VIDEO_SEARCHED_DATA = "CACHE_EXPERT_VIDEO_SEARCHED_DATA";

export const CLEAR_EXPERT_VIDEO = "CLEAR_EXPERT_VIDEO";
