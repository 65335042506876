import * as properties from "../../../lib/properties/properties";
import * as validations from "../../../lib/validator";

import { omit } from "lodash";
import { addConfigData } from "../../../state/general/configData/addConfigData";
import { useDispatch } from "react-redux";
import { useState } from "react";

const ConfigDataAction = () => {
	const [getConfigData, setValues] = useState({});
	const [errors, setErrors] = useState({});
	const dispatch = useDispatch();

	/**
   * This function validates data when user entering on input fields.
   */
	const validate = (event, name, value) => {
		let newObj = omit(errors, name);
		switch (name) {
		case "bashUrl":
			!validations.isEmpty(value) || !validations.isUrl(value)
				? setErrors(newObj)
				: setErrors({ ...errors, bashUrl: properties.COMMON_EMP_LOGO_URL_INVALID });
			break;

		case "termsAndConditionUrl":
			!validations.isEmpty(value) || !validations.isUrl(value)
				? setErrors(newObj)
				: setErrors({ ...errors, termsAndConditionUrl: properties.COMMON_EMP_LOGO_URL_INVALID });
			break;
		default:
			break;
		}
	};

	/**
* This function calls when user entering data to input fields and store data in the state after validation.
*/
	const onChange = (event) => {
		event.persist();
		let name = event.target.name;
		let val = event.target.value;
		validate(event, name, val);
		setValues({ ...getConfigData, [name]: val });
	};

	/**
   * This function calls when user submit data.
   */
	const onSubmit = (event) => {
		if (event) event.preventDefault();
		if (Object.keys(errors).length === 0 && Object.keys(getConfigData).length !== 0) {
			dispatch(addConfigData(getConfigData));
		} else {
			return;
		}
	};

	return { getConfigData, errors, onChange, onSubmit };
};

export default ConfigDataAction;
