import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { getBenefitItemApi } from "../../../services/api/benefitItemService";

//Action(s)
export const getBenefitItem = (payload) => ({
	type: TYPES.GET_BENEFIT_ITEM,
	payload: payload
});
const getBenefitItemSuccess = (payload) => ({
	type: TYPES.GET_BENEFIT_ITEM_SUCCESS,
	payload: payload
});
const getBenefitItemError = (error) => ({
	type: TYPES.GET_BENEFIT_ITEM_ERROR,
	payload: error
});

//Saga(s)
export function* getBenefitItemSaga({ payload }) {
	try {
		const response = yield call(getBenefitItemApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(getBenefitItemSuccess(response.data));
		}
	} catch (error) {
		yield put(getBenefitItemError(error));
	}
}
