import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as properties from "../../../../lib/properties/properties";
import * as validations from "../../../../lib/validator";
import { getValidationResultObject } from "../../../../lib/validator/util";
import { splitByHyphenAndTrim } from "../../../../lib/util/commonUtil";
import { NO_PAGE_LIMIT, NO_PAGE_COUNT } from "../../../../lib/constants";
import { addBenefitHome } from "../../../../state/benefits/benefitHome/addBenefitHome";
import { resetBenefitHomeState } from "../../../../state/benefits/benefitHome/getBenefitHomeAll";
import { getResourcePlanAll } from "../../../../state/employers/resourcePlan/getResourcePlanAll";
import { getBenefitItemAll } from "../../../../state/benefits/benefitItem/getBenefitItemAll";

const useAddBenefitHomeAction = () => {

	const stateEmployers = useSelector(state => state.employer.eligibleEmployers);
	const stateBenefitItems = useSelector(state => state.benefitItem.benefitItems);

	const [benefitHomeFormData, setBenefitHomeFormData] = useState({});
	const [benefitHomeFormItems, setBenefitHomeFormItems] = useState([{ id: "", summaryTitle: "", summaryDescription: "" }]);
	const [benefitHomeFormValidationEnabled, setBenefitHomeFormValidationEnabled] = useState(false);
	const [isBenefitItemFormChangeWithoutId, setIsBenefitItemFormChangeWithoutId] = useState(false);

	const dispatch = useDispatch();
	const fieldErrorObject = { fieldName: "", errorMessage: "" };

	// Clear benefit home state
	useEffect(() => { dispatch(resetBenefitHomeState()); }, []);

	// This function loads all needed API data.
	const loadAPIData = async (value) => {
		const payload = { employerId: value, pagelimit: NO_PAGE_LIMIT, pagenumber: NO_PAGE_COUNT };
		dispatch(getResourcePlanAll(payload));
		dispatch(getBenefitItemAll(payload));
	};

	// This function creates benefit home.
	const createBenefitHome = async (benefitHomeAPIRequest) => {
		const payload = { data: benefitHomeAPIRequest };
		dispatch(addBenefitHome(payload));
	};

	// This function validates benefit home form data.
	const validateBenefitHomeFormData = () => {
		if (validations.isEmpty(benefitHomeFormData.employerId))
			return getValidationResultObject("employerId", properties.COMMON_EMPLOYER_INVALID, benefitHomeFormValidationEnabled);

		if (validations.isEmpty(benefitHomeFormData.employerResourcePlanId))
			return getValidationResultObject("employerResourcePlanId", properties.COMMON_RESOURCE_PLAN_INVALID, true);

		if (validations.isEmpty(benefitHomeFormData.resourceType))
			return getValidationResultObject("resourceType", properties.COMMON_RESOURCE_TYPE_INVALID, true);

		if (validations.isEmpty(benefitHomeFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_INVALID, true);

		if (validations.isTitle(benefitHomeFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(benefitHomeFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_INVALID, true);

		if (validations.isDescription(benefitHomeFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(benefitHomeFormData.employerLogo) || validations.isIanacareUrl(benefitHomeFormData.employerLogo))
			return getValidationResultObject("employerLogo", properties.COMMON_EMP_LOGO_URL_INVALID, true);

		for (let benefitFormItem of benefitHomeFormItems) {
			if (benefitHomeFormItems.length > 1 || !validations.isEmpty(benefitHomeFormItems[0]?.id)) {
				if (validations.isEmpty(benefitFormItem?.id)) {
					return getValidationResultObject("id", properties.BENEFIT_ITEM_ID_INVALID, true);
				}
				if (validations.isEmpty(benefitFormItem?.summaryTitle)) {
					return getValidationResultObject("summaryTitle", properties.BENEFIT_SUMMARY_TITLE_INVALID, true);
				}
				if (validations.isTitle(benefitFormItem?.summaryTitle)) {
					return getValidationResultObject("summaryTitle", properties.BENEFIT_SUMMARY_TITLE_INVALID_MAX_CHARACTERS_REACHED, true);
				}
				if (validations.isEmpty(benefitFormItem?.summaryDescription)) {
					return getValidationResultObject("summaryDescription", properties.BENEFIT_SUMMARY_DESCRIPTION_INVALID, true);
				}
				if (validations.isDescription(benefitFormItem?.summaryDescription)) {
					return getValidationResultObject("summaryDescription", properties.BENEFIT_SUMMARY_DESCRIPTION_INVALID_MAX_CHARACTERS_REACHED, true);
				}
			}
		}

		if (isBenefitItemFormChangeWithoutId)
			return getValidationResultObject("id", properties.BENEFIT_ITEM_ID_INVALID, true);

		return { fieldErrorObject, validationsEnabled: false };
	};

	//This function calls benefit item form add new file
	let addBenefitItemFormFields = () => {
		setBenefitHomeFormItems([...benefitHomeFormItems, { id: "", summaryTitle: "", summaryDescription: "" }]);
		setIsBenefitItemFormChangeWithoutId(true);
	};

	//This function calls benefit item form remove file
	let removeBenefitItemFormFields = (i) => {
		let newBenefitItems = [...benefitHomeFormItems];
		newBenefitItems.splice(i, 1);
		setBenefitHomeFormItems(newBenefitItems);
		setBenefitHomeFormData({ ...benefitHomeFormData, "benefitSummaries": newBenefitItems });
		setIsBenefitItemFormChangeWithoutId(false);
	};

	//This function calls benefit item form onchange
	let changeBenefitItemFormField = (i, e) => {
		let newFormValues = [...benefitHomeFormItems];
		if (validations.isEmpty(e.target.name)) {
			let selectedBenefitItem = stateBenefitItems?.find(benefitItem => benefitItem.id === splitByHyphenAndTrim(e.target.innerHTML));
			if (validations.isEmpty(selectedBenefitItem)) {
				newFormValues[i]["id"] = null,
				newFormValues[i]["summaryTitle"] = null;
				newFormValues[i]["summaryDescription"] = null;
			} else {
				newFormValues[i]["id"] = selectedBenefitItem?.id + " - " + selectedBenefitItem?.summaryTitle;
				newFormValues[i]["summaryTitle"] = selectedBenefitItem?.summaryTitle;
				newFormValues[i]["summaryDescription"] = selectedBenefitItem?.summaryDescription;
				setIsBenefitItemFormChangeWithoutId(false);
			}
		} else {
			newFormValues[i][e.target.name] = e.target.value;
			// set validationsEnabled to true when benefit item id field not change
			setBenefitHomeFormValidationEnabled(true);
			//Check benefit item id fields null or not
			const benefitItemIdsNonEmptyArr = benefitHomeFormItems.filter(benefitItem => !validations.isEmpty(benefitItem.id));
			if (benefitItemIdsNonEmptyArr?.length !== benefitHomeFormItems.length) {
				validations.isEmpty(newFormValues[i][e.target.name]) ? setIsBenefitItemFormChangeWithoutId(false)
					: setIsBenefitItemFormChangeWithoutId(true);
				benefitHomeFormItems.length > 1 && setIsBenefitItemFormChangeWithoutId(true);
			}
		}
		setBenefitHomeFormItems(newFormValues);
		setBenefitHomeFormData({ ...benefitHomeFormData, "benefitSummaries": newFormValues });
	};

	//This function calls when user entering data to input fields and store data in the state after validation
	const onChange = (event) => {
		event.persist();
		let name = event.target.name;
		let val = event.target.value;
		if (name !== "employerId") {
			//set validationsEnabled to true when employer id field not change 
			setBenefitHomeFormValidationEnabled(true);
		} else {
			if (validations.isEmpty(val)) {
				//set validationsEnabled to true when employer id change with invalid val 
				setBenefitHomeFormValidationEnabled(true);
			} else {
				loadAPIData(val);
				benefitHomeFormData.employerResourcePlanId = "";
			}
			setBenefitHomeFormItems([{ id: "", summaryTitle: "", summaryDescription: "" }]);
			setIsBenefitItemFormChangeWithoutId(false);
		}
		setBenefitHomeFormData({ ...benefitHomeFormData, [name]: val });
	};

	// This function calls when the user submits data.
	const onSubmit = (event) => {
		if (event) event.preventDefault();
		if (validations.isEmpty(fieldErrorObject.errorMessage) && Object.keys(benefitHomeFormData).length !== 0) {
			//Remove hyphen from id before submission
			benefitHomeFormData.benefitSummaries = benefitHomeFormData.benefitSummaries.map((benefitHomeSummary) => ({
				"id": splitByHyphenAndTrim(benefitHomeSummary.id),
				"summaryDescription": benefitHomeSummary.summaryDescription,
				"summaryTitle": benefitHomeSummary.summaryTitle
			}));
			if (!Object.keys(benefitHomeFormData).includes("benefitSummaries") || validations.isEmpty(benefitHomeFormData.benefitSummaries[0]?.id))
				benefitHomeFormData.benefitSummaries = [];
			createBenefitHome(benefitHomeFormData);
		} else {
			return;
		}
	};

	//Return object and function
	return { stateEmployers, benefitHomeFormItems, benefitHomeFormData, validateBenefitHomeFormData, addBenefitItemFormFields, changeBenefitItemFormField, removeBenefitItemFormFields, onChange, onSubmit };
};

export default useAddBenefitHomeAction;
