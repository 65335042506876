import { BENEFIT_BODY_ITEM_LIST_LABEL } from "../properties/properties";
import { AON_EMPLOYER_PLATFORM } from "../constants/employerConst";

//This function get tab id.
export const containsTab = () => {
	const tab = new URLSearchParams(window.location.search).get("tab");
	const path_arr = tab ? tab.includes("/") ? tab.split("/") : tab : tab;
	const tabs = Array.isArray(path_arr) ? path_arr[0] : path_arr;
	return tabs;
};

//This function identify label
export const identifyLabel = (properties, index) => {
	const showOrHideAstric = properties === BENEFIT_BODY_ITEM_LIST_LABEL ? " *" : " ";
	const label = index ? properties + " " + (index + 1) + showOrHideAstric : properties + " " + 1 + showOrHideAstric;
	return label;
};

//This function return the maximum number of items to render for articles and videos on pillar home
export const getMaxNoOfItemsToShow = () => 50;

//This function return the maximum number of items to render
export const getMaxNoOfItemsToShowInBenefitHome = () => 50;

//This function returns the typed text count/total text count
export const textAreaTextCounter = (txtCounterEnabled, typedCount) => { return txtCounterEnabled ? typedCount + "/200" : ""; };

//This function returns the selected record id and the employer id
export const getRowIdAndEmployerId = () => {
	const rowId = window.location.href.split("/");
	return rowId[rowId.length - 1].split(",");
};

//This function returns the aon employers
export const getAonEmployers = (stateEmployers) => stateEmployers?.filter(employer => employer.platform?.toLowerCase() === AON_EMPLOYER_PLATFORM);

//This function returns domain url array
export const getDomainUrlArray = () => process.env.REACT_APP_AWS_S3_DOMAIN.split(",");

//This function returns S3 domain url tooltip
export const getSupportedUrlTooltip = () => getDomainUrlArray().map((url) => url + "...");

//This function returns resource plan name tooltip
export const getSupportedResourcePlanNameTooltip = () => ["Name should contain employer name and plan A suffix.", "Ex : Ianacare plan A"];

//This function returns split by hyphen and trimmed value
export const splitByHyphenAndTrim = items => items?.split("-")[0].trim();
