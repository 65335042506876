import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Grid, Container } from "@mui/material";

import LeftNavigator from "./common/LeftNavigator";
import BenefitHome from "./BenefitHome";
import BenefitItem from "./BenefitItem";
import AddBenefitHome from "./BenefitHome/addBenefitHome";
import AddBenefitItem from "./BenefitItem/addBenefitItem";
import UpdateBenefitHome from "./BenefitHome/updateBenefitHome";
import UpdateBenefitItem from "./BenefitItem/updateBenefitItem";
import AuthenticatedLayout from "../common/Layout/AuthenticatedLayout";
import { containsTab } from "../../lib/util/commonUtil";
import { PAGE } from "../../lib/enums/enum";

import "./style.scss";

const Benefits = () => {
	const [selectedIndex, setSelectedIndex] = useState();
	const location = useLocation();

	useEffect(() => {
		switch (containsTab()) {
		case "benefitHome":
			setSelectedIndex(0);
			break;
		case "benefitItem":
			setSelectedIndex(1);
			break;
		case "addBenefitItem":
			setSelectedIndex(2);
			break;
		case "addBenefitHome":
			setSelectedIndex(3);
			break;
		case "updateBenefitItem":
			setSelectedIndex(4);
			break;
		case "updateBenefitHome":
			setSelectedIndex(5);
			break;
		default:
			setSelectedIndex(1);
			break;
		}
	}, [location]);

	return (
		<AuthenticatedLayout selectedPage={selectedIndex === 0 || selectedIndex === 1 || selectedIndex === 2 || selectedIndex === 3 || selectedIndex === 4 || selectedIndex === 5 ? PAGE.BENEFITS : ""}>
			<Container maxWidth="lg" className="el1260W">
				<Grid container spacing={0} px={0} className="contentSection ian-table-content">
					<Grid item xs={12} sm={5} lg={3} className="ian-position-relative  borderRight el20pW">
						<LeftNavigator selectedIndex={selectedIndex}></LeftNavigator>
					</Grid>
					<Grid item xs={12} sm={7} lg={9} className="el80pW">
						{selectedIndex === 0 ? (
							<BenefitHome />
						) : selectedIndex === 1 ? (
							<BenefitItem />
						) : selectedIndex === 2 ? (
							<AddBenefitItem />
						) : selectedIndex === 3 ? (
							<AddBenefitHome />
						) : selectedIndex === 4 ? (
							<UpdateBenefitItem />
						) : selectedIndex === 5 ? (
							<UpdateBenefitHome />
						) : null}
					</Grid>
				</Grid>
			</Container>
		</AuthenticatedLayout>
	);
};

export default Benefits;
