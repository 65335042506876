import React from "react";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";

import CancelButton from "../Button/CancelButton";
import * as properties from "../../../lib/properties/properties";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "1px solid #000",
	boxShadow: 24,
	p: 4
};

const Popup = (props) => {
	const {
		title,
		children,
		isDisplyaCancelBtn,
		openPopup,
		disabled,
		setOpenPopup,
		ianTitleClass = false,
		logoutModalPopup = false
	} = props;

	const titleClass = ianTitleClass ? "ian-modal-title ian-title-style" : "ian-modal-title";
	const ianLogoutModal = logoutModalPopup ? "ian-modal-width ian-logoutModal" : "ian-modal-width";

	const cancelBtn = (
		<CancelButton
			label={properties.CANCEL_LABEL}
			type="submit"
			disabled={disabled}
			showProgress={false}
			onClick={() => {
				setOpenPopup(false);
			}}
		/>
	);

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={openPopup === true}
			onClose={(_, reason) => {
				if (reason !== "backdropClick") {
					setOpenPopup(false);
				}
			}}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500
			}}
		>
			<Fade in={openPopup === true}>
				<Box sx={style} className={ianLogoutModal}>
					<h3 className={titleClass}>{title}</h3>
					<Typography id="transition-modal-description" sx={{ mt: 2 }} component="div">
						{children}
						{isDisplyaCancelBtn ? cancelBtn : ""}
					</Typography>
				</Box>
			</Fade>
		</Modal>
	);
};

export default Popup;
