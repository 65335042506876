import React from "react";
import AuthenticatedHeader from "../Header/AuthenticatedHeader";

const AuthenticatedLayout = ({ children, selectedPage }) => {
	return (
		<React.Fragment>
			<AuthenticatedHeader selectedPage={selectedPage} />
			<div className="App">
				{children}
			</div>
		</React.Fragment>
	);
};
export default AuthenticatedLayout;
