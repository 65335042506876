import React, { useState } from "react";
import { Box, Toolbar } from "@mui/material";
import Logo from "../../assets/images/ianacare/logo.svg";
import PrimaryButton from "../common/Button/PrimaryButton";
import * as properties from "../../lib/properties/properties";
import { signIn } from "../../services/cognito/cognitoUtil";

const SignIn = () => {
	const [signInInProgress, setSignInInProgress] = useState(false);

	/* Function to handle user login with Cognito */
	const loginWithCognito = async () => {
		/* If sign-in process is already in progress, do nothing */
		if (signInInProgress) {
			return;
		}
		try {
			setSignInInProgress(true); /* Set sign-in progress flag to true */
			await signIn(); /* Initiate sign-in process */
		} catch (error) {
			/* Error handling can be customized if necessary */
		} finally {
			setSignInInProgress(false); /* Reset sign-in progress flag regardless of success or failure */
		}
	};

	return (
		<div className="ian-signIn-verticalMiddle">
			<div className="ian-text-center">
				{/* Sign-in form title and logo */}
				<h1 className="ian-h1-title" px={0} py={0}>
					<Toolbar>
						<Box sx={{ flexGrow: 5, textAlign: "left" }}>
							<img
								src={Logo}
								className="navBarLogo ian-centeredSection homeLogo"
								alt="ianacare logo"
							/>
							<p className="ian-login-desc">{properties.SIGNIN_DESCRIPTION_LABEL}</p>
						</Box>
					</Toolbar>
				</h1>
			</div>
			<div className="ian-centeredSection ian-w-160">
				{/* Sign-in button */}
				<div className="ian-form-container">
					<PrimaryButton
						label={properties.SIGNIN_LABEL}
						onClick={loginWithCognito} /* Call loginWithCognito function on button click */
					/>
				</div>
			</div>
		</div>
	);
};

export default SignIn;
