// HTTP Methods
export const HTTP_METHOD = {
	POST : "post",
	GET : "get",
	PUT : "put",
	DELETE : "delete"
};

// HTTP Status Codes
export const HTTP_CODE = {
	OK : 200,
	CREATED : 201,
	BAD_REQUEST : 400,
	UNAUTHORIZED : 401,
	NOT_FOUND : 404,
	INTERNAL_SERVER_ERROR : 500,
	SERVICE_UNAVAILABLE : 503
};

