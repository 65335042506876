import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Box, LinearProgress } from "@mui/material";

import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import BackButton from "../../../common/Button/BackButton";
import PrimaryButton from "../../../common/Button/PrimaryButton";
import SelectField from "../../../common/SelectField";
import ErrorPopup from "../../../common/ErrorPopup";
import SuccessPopup from "../../../common/SuccessPopup";
import ToggleSwitch from "../../../common/ToggleSwitch";
import CheckboxesTags from "../../../common/CheckboxesTags";
import * as properties from "../../../../lib/properties/properties";
import { EmployerAuthOptions, EmployerPlatform } from "../../../../lib/constants/employerConst";
import { isEmpty } from "../../../../lib/validator";
import { getEmployer } from "../../../../state/employers/employer/getEmployer";
import { resetEmployerState } from "../../../../state/employers/employer/getEmployerAll";

import useUpdateEmployerAction from "./useUpdateEmployerAction";

const UpdateEmployer = () => {

	const stateEmployer = useSelector(state => state.employer);

	let history = useHistory();
	const dispatch = useDispatch();

	let urlPathVariables = window.location.href.split("/");
	let urlEmployerPathVariables = urlPathVariables[urlPathVariables.length - 1];

	useEffect(() => { getEmployerData(); }, []);

	//Get Employer Data
	const getEmployerData = async () => {
		const payload = { employerId: urlEmployerPathVariables };
		dispatch(getEmployer(payload));
	};

	//Custom hook for this form action
	const { employerFormData, employerFormAuthOptions, employerFormResourceEnabled, validateEmployerFormData, onChangeAuthOptions, changeResourceEnabled, onChange, onSubmit } = useUpdateEmployerAction();

	//This function executes when error popup ok button clicked.
	const onErrorPopupButtonClicked = () => {
		dispatch(resetEmployerState());
	};

	//This function executes when success popup ok button clicked.
	const onSuccessPopupButtonClicked = () => {
		dispatch(resetEmployerState());
		history.push("/employers");
	};

	const onClickBackButton = () => {
		history.push("/employers");
	};

	return (
		<div>
			<BackButton className="px-5" onClick={onClickBackButton} />
			<div className="tabSectionRight ian-position-relative">
				<div className="ian-centeredSection ian-w-40 scrollTop">
					<h5 className="ian-modal-title">
						{properties.EMPLOYER_UPDATE_TITLE}
					</h5>
					{stateEmployer.loading && <Box sx={{ width: "100%" }}> <LinearProgress className="ian-linear-progress" /> </Box>}
					<div className="ian-form-container">
						<TextField
							label={properties.EMPLOYER_NAME_LABEL}
							placeholder={properties.EMPLOYER_NAME_PLACEHOLDER}
							name="name"
							id="name"
							type="text"
							value={employerFormData.name}
							onChange={onChange}
						/>
						<TextArea
							label={properties.EMPLOYER_DESCRIPTION_LABEL}
							placeholder={properties.EMPLOYER_DESCRIPTION_PLACEHOLDER}
							name="description"
							id="description"
							type="text"
							value={!isEmpty(employerFormData.description) ? employerFormData.description : ""}
							onChange={onChange}
						/>
						<TextField
							label={properties.EMPLOYER_LINK_LABEL}
							placeholder={properties.EMPLOYER_LINK_PLACEHOLDER}
							name="link"
							id="link"
							type="text"
							value={!isEmpty(employerFormData.link) ? employerFormData.link : ""}
							onChange={onChange}
						/>
						<TextArea
							label={properties.EMPLOYER_WELCOME_NOTE_LABEL}
							placeholder={properties.EMPLOYER_WELCOME_NOTE_PLACEHOLDER}
							name="welcomeNote"
							id="welcomeNote"
							type="text"
							value={!isEmpty(employerFormData.welcomeNote) ? employerFormData.welcomeNote : ""}
							onChange={onChange}
						/>
						<SelectField
							label={properties.EMPLOYER_PLAT_FORM_LABEL}
							name="platform"
							id="platform"
							options={EmployerPlatform}
							onChange={onChange}
							value={employerFormData.platform}
							txtCounterEnabled={false}
							selectOption={properties.EMPLOYER_PLAT_FORM_PLACEHOLDER}
						/>
						<CheckboxesTags
							label={properties.EMPLOYER_AUTH_OPTIONS_LABEL}
							placeholder={properties.EMPLOYER_AUTH_OPTIONS_LABEL}
							name="authOptions"
							id="authOptions"
							type="text"
							options={EmployerAuthOptions}
							value={employerFormAuthOptions}
							onChange={onChangeAuthOptions}
						/>
						<div className="ian-toggle-custom-switch">
							<ToggleSwitch label={properties.EMPLOYER_RESOURCE_ENABLED_LABEL} name="resourceEnabled" checked={employerFormResourceEnabled} onChange={changeResourceEnabled} />
						</div>

						{validateEmployerFormData().validationsEnabled ? (
							<div className="ian-error-warning show px-5 mt-5" sx={{ display: "none" }}>
								<p>
									{validateEmployerFormData().fieldErrorObject.errorMessage}
								</p>
							</div>
						) : (
							<PrimaryButton
								className="px-5"
								label={properties.COMMON_UPDATE_LABEL}
								disabled={validateEmployerFormData().fieldErrorObject.errorMessage || employerFormData.loading}
								showProgress={false}
								onClick={onSubmit}
							/>
						)}
					</div>
					{stateEmployer.error ? <ErrorPopup error={stateEmployer.error} okButtonAction={onErrorPopupButtonClicked} /> : ""}
					{stateEmployer.success ? <SuccessPopup
						message={properties.SUCCESS_POPUP_UPDATE_MESSAGE} okButtonAction={onSuccessPopupButtonClicked}
					/> : null}
				</div>
			</div>
		</div>
	);
};

export default UpdateEmployer;
