import * as TYPES from "./types";

const initialState = {
	expertVideo: {},
	expertVideos: []
};

const expertVideoReducer = (state = initialState, action) => {
	switch (action.type) {
	case TYPES.ADD_EXPERT_VIDEO:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.ADD_EXPERT_VIDEO_SUCCESS:
		return {
			...state,
			loading: false,
			success: true,
			expertVideo: action.payload,
			error: null
		};
	case TYPES.ADD_EXPERT_VIDEO_ERROR:
		return {
			...state,
			loading: false,
			expertVideo: "",
			error: action.payload.error.errorMessage
		};
	case TYPES.GET_EXPERT_VIDEO:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.GET_EXPERT_VIDEO_SUCCESS:
		return {
			...state,
			loading: false,
			expertVideo: action.payload,
			error: null
		};
	case TYPES.GET_EXPERT_VIDEO_ERROR:
		return {
			...state,
			loading: false,
			expertVideo: "",
			error: action.payload.error
		};
	case TYPES.GET_EXPERT_VIDEO_ALL:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.GET_EXPERT_VIDEO_ALL_SUCCESS:
		return {
			...state,
			loading: false,
			expertVideos: action.payload.expertVideosRS,
			pageData: { pageCount: action.payload.pageCount, pageLimit: action.payload.pageLimit, pageNumber: action.payload.pageNumber },
			error: null
		};
	case TYPES.GET_EXPERT_VIDEO_ALL_ERROR:
		return {
			...state,
			loading: false,
			expertVideos: [],
			error: null
		};
	case TYPES.UPDATE_EXPERT_VIDEO:
		return {
			...state,
			loading: true,
			error: null
		};
	case TYPES.UPDATE_EXPERT_VIDEO_SUCCESS:
		return {
			...state,
			loading: false,
			success: true,
			expertVideo: action.payload,
			error: null
		};
	case TYPES.UPDATE_EXPERT_VIDEO_ERROR:
		return {
			...state,
			loading: false,
			error: action.payload
		};
	case TYPES.CACHE_EXPERT_VIDEO_SEARCHED_DATA:
		return {
			...state,
			search: action.payload
		};
	case TYPES.CLEAR_EXPERT_VIDEO:
		return {
			...state,
			loading: false,
			success: false,
			expertVideos: [],
			error: null
		};
	default:
		return state;
	}
};
export default expertVideoReducer;
