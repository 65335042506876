import { ADD_PILLAR_URL, GET_PILLAR_ALL_URL, GET_PILLAR_URL, UPDATE_PILLAR_URL } from "./endPoints";
import networkClient from "./networkClient";
import { HTTP_METHOD } from "../../lib/enums/httpData";

export function addPillarApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.POST,
		url: ADD_PILLAR_URL,
		data: payload
	});
}

export function getPillarApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.GET,
		url: GET_PILLAR_URL.replace("{pillarId}", payload.pillarId)
	});
}

export function getPillarAllApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.GET,
		url: GET_PILLAR_ALL_URL.replace("{employerid}", payload.employerId).replace("{pagelimit}", payload.pagelimit).replace("{pagenumber}", payload.pagenumber)
	});
}

export function updatePillarApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.PUT,
		url: UPDATE_PILLAR_URL.replace("{pillarhomeid}", payload.data.id),
		data: payload.data
	});
}


