import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../..//lib/enums/httpData";
import * as TYPES from "./types";
import { updateExpertVideoApi } from "../../../services/api/expertVideoService";

//Action(s)
export const updateExpertVideo = (payload) => ({
	type: TYPES.UPDATE_EXPERT_VIDEO,
	payload: payload
});
const updateExpertVideoSuccess = (payload) => ({
	type: TYPES.UPDATE_EXPERT_VIDEO_SUCCESS,
	payload
});
const updateExpertVideoError = (error) => ({
	type: TYPES.UPDATE_EXPERT_VIDEO_ERROR,
	payload: error
});

//Saga(s)
export function* updateExpertVideoSaga({ payload }) {
	try {
		const response = yield call(updateExpertVideoApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(updateExpertVideoSuccess(response.data));
		}
	} catch (error) {
		yield put(updateExpertVideoError(error.errorMessage));
	}
}
