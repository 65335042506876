import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Divider, LinearProgress } from "@mui/material";

import PrimaryButton from "../../../common/Button/PrimaryButton";
import BackButton from "../../../common/Button/BackButton";
import SelectField from "../../../common/SelectField";
import TextArea from "../../../common/TextArea";
import TextField from "../../../common/TextField";
import ErrorPopup from "../../../common/ErrorPopup";
import SuccessPopup from "../../../common/SuccessPopup";
import ContentPreview from "../common/contentPreview";
import * as properties from "../../../../lib/properties/properties";
import { getRowIdAndEmployerId, getSupportedUrlTooltip } from "../../../../lib/util/commonUtil";
import { isEmpty } from "../../../../lib/validator";
import { DEFAULT_PAGE_LIMIT_20, DEFAULT_PAGE_NUMBER } from "../../../../lib/constants";
import { ResourceTypeOptions } from "../../../../lib/constants/ResourceTypeOptions";
import { resetExpertArticleState } from "../../../../state/expertContents/expertArticle/getExpertArticleAll";
import { getResourcePlanAll } from "../../../../state/employers/resourcePlan/getResourcePlanAll";
import { getExpertArticle } from "../../../../state/expertContents/expertArticle/getExpertArticle";

import useUpdateExpertArticleAction from "./useUpdateExpertArticleAction";

const UpdateExpertArticle = () => {

	const stateExpertArticle = useSelector(state => state.expertArticle);
	const stateResourcePlanOptions = useSelector(state => state.resourcePlan.resourcePlanOptions);

	//flag to say content preview popup open or not
	const [isContentPreviewOpened, setIsContentPreviewOpened] = useState(false);

	let history = useHistory();
	const dispatch = useDispatch();
	const urlExpertArticleEmployerPathVariables = getRowIdAndEmployerId();

	//Get Expert article data
	const getExpertArticleData = async () => {
		const payload = { articleId: urlExpertArticleEmployerPathVariables[0] };
		dispatch(getExpertArticle(payload));
	};

	//Get Resource plan
	const getResourcePlanData = async (value) => {
		const payload = { employerId: value, pagelimit: DEFAULT_PAGE_LIMIT_20, pagenumber: DEFAULT_PAGE_NUMBER };
		dispatch(getResourcePlanAll(payload));
	};

	useEffect(() => { getResourcePlanData(urlExpertArticleEmployerPathVariables[1]); getExpertArticleData(); }, []);

	// Custom hook for article update
	const { stateEmployers, expertArticleFormData, validateExpertArticleFormData, onChange, onSubmit } = useUpdateExpertArticleAction();

	//This function executes when error popup ok button clicked.
	const onErrorPopupButtonClicked = () => {
		dispatch(resetExpertArticleState());
	};

	//This function executes when go back action gets triggered
	const onClickBackButton = () => {
		history.push("/contents");
	};

	//This function executes when success popup ok button clicked.
	const onSuccessPopupButtonClicked = () => {
		dispatch(resetExpertArticleState());
		history.push("/contents");
	};

	const contentPreviewClickOpen = () => {
		setIsContentPreviewOpened(true);
	};

	const contentPreviewClose = () => {
		setIsContentPreviewOpened(false);
	};

	return (
		<div>
			<BackButton className="px-5" onClick={onClickBackButton} />
			<div className="tabSectionRight ian-position-relative">
				<div className="ian-centeredSection ian-w-40 ian-position-relative">
					<h5 className="ian-modal-title">
						{properties.CONTENT_EXPE_ARTICLE_UPDATE_TITLE}
					</h5>
					{stateExpertArticle?.loading && <Box sx={{ width: "100%" }}> <LinearProgress className="ian-linear-progress" /> </Box>}
					<div className="ian-form-container">
						<SelectField
							label={properties.COMMON_EMPLOYER_LABEL}
							name="employerId"
							id="employerId"
							options={stateEmployers}
							onChange={onChange}
							value={expertArticleFormData.employerId}
							isDisabled={true}
						/>
						<SelectField
							label={properties.COMMON_EMPLOYER_RESOURCE_PLAN_LABEL}
							name="employerResourcePlanId"
							id="employerResourcePlanId"
							options={expertArticleFormData.employerId ? stateResourcePlanOptions : []}
							value={expertArticleFormData.employerResourcePlanId}
							isDisabled={true}
						/>
						<SelectField
							label={properties.COMMON_RESOURCE_TYPE_LABEL}
							name="resourceType"
							id="resourceType"
							options={ResourceTypeOptions}
							value={expertArticleFormData.resourceType}
							isDisabled={true}
							onChange={onChange}
						/>
						<TextField
							label={properties.COMMON_TITLE_LABEL}
							placeholder={properties.COMMON_TITLE_PLACEHOLDER}
							name="title"
							id="title"
							type="text"
							value={expertArticleFormData.title || ""}
							onChange={onChange}
						/>
						<TextArea
							label={properties.COMMON_DESCRIPTION_LABEL}
							placeholder={properties.COMMON_DESCRIPTION_PLACEHOLDER}
							name="description"
							id="description"
							type="text"
							onChange={onChange}
							value={expertArticleFormData.description}
							characterCount={expertArticleFormData.description?.length}
						/>

						{!isEmpty(expertArticleFormData.content) ?
							<div className="ian-IntegrationInstructionsIcon">
								<VisibilityIcon onClick={contentPreviewClickOpen} />
							</div> : ""}

						<TextArea
							label={properties.COMMON_CONTENT_LABEL}
							placeholder={properties.COMMON_CONTENT_PLACEHOLDER}
							name="content"
							id="content"
							type="text"
							onChange={onChange}
							value={expertArticleFormData.content}
							txtCounterEnabled={false}
						/>
						<Divider orientation="horizontal">{properties.CONTENT_EXPE_ARTICLE_PREVIEW_TITLE}</Divider>
						<TextField
							label={properties.COMMON_PREVIEW_IMAGE_LABEL}
							placeholder={properties.COMMON_PREVIEW_IMAGE_PLACEHOLDER}
							name="previewImage"
							id="previewImage"
							type="text"
							onChange={onChange}
							value={expertArticleFormData.previewImage || ""}
							toolTipText={getSupportedUrlTooltip()}
						/>
						<TextField
							label={properties.COMMON_PREVIEW_TITLE_LABEL}
							placeholder={properties.COMMON_PREVIEW_TITLE_PLACEHOLDER}
							name="previewTitle"
							id="previewTitle"
							type="text"
							onChange={onChange}
							value={expertArticleFormData.previewTitle || ""}
						/>
						{validateExpertArticleFormData().validationsEnabled ? (
							<div className="ian-error-warning show" sx={{ display: "none" }}>
								<p>
									{" "}
									{validateExpertArticleFormData().fieldErrorObject.errorMessage}
								</p>
							</div>
						) : (
							<PrimaryButton
								className="px-5"
								label={properties.COMMON_UPDATE_LABEL}
								disabled={validateExpertArticleFormData().fieldErrorObject.errorMessage || stateExpertArticle?.loading}
								showProgress={false}
								onClick={onSubmit} />
						)}
					</div>

					{/* Priview Content */}
					<Dialog fullWidth={true} maxWidth="md" open={isContentPreviewOpened} onClose={contentPreviewClose} className="contet-preview" >
						<ContentPreview htmlCont={expertArticleFormData.content} contentPreviewClose={contentPreviewClose} />
					</Dialog>

					{stateExpertArticle?.error ? <ErrorPopup error={stateExpertArticle.error} okButtonAction={onErrorPopupButtonClicked} /> : ""}
					{stateExpertArticle?.success ? <SuccessPopup
						message={properties.SUCCESS_POPUP_UPDATE_MESSAGE} okButtonAction={onSuccessPopupButtonClicked}
					/> : null}
				</div>
			</div>
		</div>
	);
};
export default UpdateExpertArticle;
