import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import Divider from "@mui/material/Divider";

import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import SelectField from "../../../common/SelectField";
import PrimaryButton from "../../../common/Button/PrimaryButton";
import BackButton from "../../../common/Button/BackButton";
import SuccessPopup from "../../../common/SuccessPopup";
import ErrorPopup from "../../../common/ErrorPopup";
import * as properties from "../../../../lib/properties/properties";
import { getMaxNoOfItemsToShowInBenefitHome, getSupportedUrlTooltip, identifyLabel } from "../../../../lib/util/commonUtil";
import { resetBenefitItemState } from "../../../../state/benefits/benefitItem/addBenefitItem";
import { cacheBenefitItemSearchedData } from "../../../../state/benefits/benefitItem/getBenefitItemAll";
import { DEFAULT_PAGE_NUMBER } from "../../../../lib/constants";
import { ResourceTypeOptions } from "../../../../lib/constants/ResourceTypeOptions";

import useAddBenefitItemAction from "./useAddBenefitItemAction";

const AddBenefitItem = () => {

	const stateResourcePlan = useSelector(state => state.resourcePlan);
	const stateBenefitItem = useSelector(state => state.benefitItem);

	const dispatch = useDispatch();
	const history = useHistory();

	//Custom hook for this form action
	const { stateEmployers, benefitItemListFormField, benefitItemCheckListFormField, benefitItemFormData,
		validateBenefitItemFormData, addItemListFormField, removeItemListFormField, changItemListFormField, addCheckListFormField, removeCheckListFormField, changeCheckListFormFileld, onChange, onSubmit } = useAddBenefitItemAction();

	// This function reset benefit item data in redux state.
	const resetState = () => {
		dispatch(resetBenefitItemState());
	};

	//This function executes when go back action gets triggered
	const onClickBackButton = () => {
		history.push("/benefits?tab=benefitItem");
	};

	const onSuccessPopupButtonClicked = () => {
		resetState();
		//set the previous Search results
		dispatch(cacheBenefitItemSearchedData({ employerId: benefitItemFormData.employerId, pagenumber: DEFAULT_PAGE_NUMBER }));
		history.push("/benefits?tab=benefitItem");
	};

	const onErrorPopupButtonClicked = () => {
		resetState();
	};

	return (
		<div>
			<BackButton className="px-5" onClick={onClickBackButton} />
			<div className="tabSectionRight ian-position-relative">
				<div className="ian-centeredSection ian-w-40">
					<div className="ian-form-container">
						<h3 className="ian-modal-title"> {properties.BENEFIT_ITEM_CREATE_TITLE} </h3>
						<SelectField
							label={properties.COMMON_EMPLOYER_LABEL}
							name="employerId"
							id="employerId"
							options={stateEmployers}
							selectOption={properties.COMMON_SELECT_OPTION_EMPLOYER_LABEL}
							onChange={onChange}
						/>

						<SelectField
							label={properties.COMMON_EMPLOYER_RESOURCE_PLAN_LABEL}
							name="employerResourcePlanId"
							id="employerResourcePlanId"
							options={benefitItemFormData?.employerId ? stateResourcePlan?.resourcePlanOptions : []}
							selectOption={properties.COMMON_SELECT_OPTION_RESOURCE_PLAN_LABEL}
							loading={stateResourcePlan?.loading}
							onChange={onChange}
							isDisabled={benefitItemFormData?.employerId ? false : true}
						/>
						<SelectField
							label={properties.COMMON_RESOURCE_TYPE_LABEL}
							name="resourceType"
							id="resourceType"
							options={ResourceTypeOptions}
							onChange={onChange}
							selectOption={properties.COMMON_SELECT_OPTION_RESOURCE_TYPE_LABEL}
						/>
						<TextField
							label={properties.BENEFIT_SUMMARY_TITLE_LABEL}
							placeholder={properties.BENEFIT_SUMMARY_TITLE_PLACEHOLDER}
							name="summaryTitle"
							id="summaryTitle"
							type="text"
							onChange={onChange}
						/>

						<TextArea
							label={properties.BENEFIT_SUMMARY_DESCRIPTION_LABEL}
							placeholder={properties.BENEFIT_SUMMARY_DESCRIPTION_PLACEHOLDER}
							name="summaryDescription"
							id="summaryDescription"
							type="text"
							onChange={onChange}
						/>

						<TextField
							label={properties.COMMON_EMPLOYER_LOGO_MANDATORY_URL_LABEL}
							placeholder={properties.COMMON_EMPLOYER_LOGO_URL_PLACEHOLDER}
							name="employerLogo"
							id="employerLogo"
							type="text"
							onChange={onChange}
							toolTipText={getSupportedUrlTooltip()}
						/>

						<Divider orientation="horizontal">Body</Divider>
						<TextField
							label={properties.BENEFIT_BODY_TITLE_LABEL}
							placeholder={properties.BENEFIT_BODY_TITLE_PLACEHOLDER}
							name="bodyTitle"
							id="bodyTitle"
							type="text"
							onChange={onChange}
						/>
						{benefitItemListFormField.map((element, index) => (
							<div key={index} className="textFieldWthRmvBtn">
								{index ? <RemoveCircleOutlineOutlinedIcon className="button-section-remove-form" onClick={() => removeItemListFormField(index)} /> : ""}
								<TextField
									label={identifyLabel(properties.BENEFIT_BODY_ITEM_LIST_LABEL, index)}
									placeholder={properties.BENEFIT_BODY_ITEM_LIST_PLACEHOLDER}
									name="bodyItemList"
									id="bodyItemList"
									value={element.bodyItemList || ""}
									type="text"
									onChange={e => changItemListFormField(index, e)}
								/>
							</div>))}
						{benefitItemListFormField.length < getMaxNoOfItemsToShowInBenefitHome() && <div className="button-section custom-add-btn">
							<AddCircleOutlineOutlinedIcon onClick={() => addItemListFormField()} /><br />
						</div>}

						{benefitItemCheckListFormField.map((element, index) => (
							<div key={index} className="textFieldWthRmvBtn">
								{index ? <RemoveCircleOutlineOutlinedIcon className="button-section-remove-form" onClick={() => removeCheckListFormField(index)} /> : ""}
								<TextField
									label={identifyLabel(properties.BENEFIT_BODY_CHECK_LIST_LABEL, index)}
									placeholder={properties.BENEFIT_BODY_CHECK_LIST_LABEL}
									name="bodyCheckList"
									id="bodyCheckList"
									value={element.bodyCheckList || ""}
									type="text"
									onChange={e => changeCheckListFormFileld(index, e)}
								/>
							</div>))}
						{benefitItemCheckListFormField.length < getMaxNoOfItemsToShowInBenefitHome() && <div className="button-section custom-add-btn">
							<AddCircleOutlineOutlinedIcon onClick={() => addCheckListFormField()} /><br />
						</div>}
						<Divider orientation="horizontal">Footer</Divider>
						<TextField
							label={properties.BENEFIT_FOOTER_TITLE_LABEL}
							placeholder={properties.BENEFIT_FOOTER_TITLE_PLACEHOLDER}
							name="footerTitle"
							id="footerTitle"
							type="text"
							onChange={onChange}
						/>
						<TextArea
							label={properties.BENEFIT_FOOTER_DESCRIPTION_LABEL}
							placeholder={properties.BENEFIT_FOOTER_DESCRIPTION_PLACEHOLDER}
							name="footerDescription"
							id="footerDescription"
							type="text"
							onChange={onChange}
						/>
						<TextField
							label={properties.BENEFIT_FOOTER_BUTTON_TEXT_LABEL}
							placeholder={properties.BENEFIT_FOOTER_BUTTON_TEXT_LABEL}
							name="footerButtonText"
							id="footerButtonText"
							type="text"
							onChange={onChange}
						/>

						<TextField
							label={properties.BENEFIT_FOOTER_PHONE_LABEL}
							placeholder={properties.BENEFIT_FOOTER_PHONE_PLACEHOLDER}
							name="footerPhone"
							id="footerPhone"
							type="text"
							onChange={onChange}
						/>

						<TextField
							label={properties.BENEFIT_FOOTER_URL_LABEL}
							placeholder={properties.BENEFIT_FOOTER_URL_PLACEHOLDER}
							name="footerURL"
							id="footerURL"
							type="text"
							onChange={onChange}
						/>

						{validateBenefitItemFormData().validationsEnabled ? (
							<div className="ian-error-warning show" sx={{ display: "none" }}>
								<p>
									{" "}
									{validateBenefitItemFormData().fieldErrorObject.errorMessage}
								</p>
							</div>
						) : (
							<PrimaryButton
								className="px-5"
								label={properties.COMMON_CREATE_LABEL}
								disabled={validateBenefitItemFormData().fieldErrorObject.errorMessage || stateBenefitItem.loading}
								showProgress={false}
								onClick={onSubmit}
							/>
						)}
						{stateBenefitItem.success ? <SuccessPopup
							message={properties.SUCCESS_POPUP_CREATE_MESSAGE} okButtonAction={onSuccessPopupButtonClicked}
						/> : null}
						{stateBenefitItem.error ? <ErrorPopup
							error={stateBenefitItem.error}
							okButtonAction={onErrorPopupButtonClicked}
						/> : null}
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddBenefitItem;
