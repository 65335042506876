import React from "react";
import { Box } from "@mui/material";
import BackIcon from "../../../../assets/images/icn-arrow-left.svg";

import "../style.scss";

const BackButton = ({ onClick = () => null, disabled = false }) => {
	return (
		<Box className="ian-form-group">
			<button
				className="ian-btn-back"
				onClick={onClick}
				disabled={disabled}
			>
				<img src={BackIcon} alt="back" /><span>Back</span>
			</button>
		</Box>
	);
};

export default BackButton;
