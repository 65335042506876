import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import * as validations from "../../../lib/validator";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_LIMIT } from "../../../lib/constants";

import { getExpertVideoAll, cacheExpertVideoSearchedData, resetExpertVideoState } from "../../../state/expertContents/expertVideo/getExpertVideoAll";

const useExpertVideoAction = () => {

	const stateEmployers = useSelector(state => state.employer.eligibleEmployers);
	const stateSearchedExpertVideo = useSelector(state => state.expertVideo?.search?.employerId);
	const [expertVideoSearchedEmployer, setExpertVideoSearchedEmployer] = useState({ employerId: stateSearchedExpertVideo });

	const history = useHistory();
	const dispatch = useDispatch();

	const getExpertVideoData = async () => {
		const payload = { employerId: expertVideoSearchedEmployer.employerId, pagelimit: DEFAULT_PAGE_LIMIT, pagenumber: DEFAULT_PAGE_NUMBER };
		dispatch(getExpertVideoAll(payload));
		//set the previous Search results
		dispatch(cacheExpertVideoSearchedData({ employerId: expertVideoSearchedEmployer.employerId, pagenumber: DEFAULT_PAGE_NUMBER }));
	};

	//This function checks required field validations.
	const isValidData = () => {
		return !validations.isEmpty(expertVideoSearchedEmployer.employerId);
	};

	//This function call search data
	const onClickSearch = () => {
		getExpertVideoData();
	};

	//This function calls when user entering data to input fields and store data in the state after validation
	const onChange = (event) => {
		event.persist();
		const name = event.target.name;
		const val = event.target.value;
		dispatch(resetExpertVideoState());
		dispatch(cacheExpertVideoSearchedData({ employerId: "", pagenumber: DEFAULT_PAGE_NUMBER }));
		setExpertVideoSearchedEmployer({ ...expertVideoSearchedEmployer, [name]: val });
	};

	const onClickAddForm = () => {
		history.push("/contents?tab=addExpertVideo");
	};

	return { stateEmployers, expertVideoSearchedEmployer, isValidData, onClickSearch, onChange, onClickAddForm };
};

export default useExpertVideoAction;
