import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import * as properties from "../../../../lib/properties/properties";
import * as validations from "../../../../lib/validator";
import { getValidationResultObject } from "../../../../lib/validator/util";
import { AON_EMPLOYER_PLATFORM } from "../../../../lib/constants/employerConst";
import { updateEmployers } from "../../../../state/employers/employer/updateEmployer";

const useUpdateEmployerAction = () => {

	const stateUpdateEmployer = useSelector(state => state.employer.employer);

	const [employerFormData, setEmployerFormData] = useState(stateUpdateEmployer);
	const [employerFormAuthOptions, setEmployerFormAuthOptions] = useState(!validations.isEmpty(stateUpdateEmployer.authOptions?.[0]) ? stateUpdateEmployer.authOptions : []);
	const [employerFormResourceEnabled, setEmployerFormResourceEnabled] = useState(stateUpdateEmployer.resourceEnabled);

	const dispatch = useDispatch();
	const fieldErrorObject = { fieldName: "", errorMessage: "" };

	useEffect(() => {
		setEmployerFormAuthOptions(!validations.isEmpty(stateUpdateEmployer.authOptions?.[0]) ? stateUpdateEmployer.authOptions : []);
		setEmployerFormData(!validations.isEmpty(stateUpdateEmployer) ? stateUpdateEmployer : []);
		setEmployerFormResourceEnabled(stateUpdateEmployer.resourceEnabled);
	}, [stateUpdateEmployer]);

	//Get create employer
	const updateEmployer = async (updateAPIrequest) => {
		const payload = { data: updateAPIrequest };
		dispatch(updateEmployers(payload));
	};

	//This function checks required field validations.
	const validateEmployerFormData = () => {
		if (validations.isEmpty(employerFormData.name))
			return getValidationResultObject("name", properties.EMPLOYER_NAME_INVALID, true);

		if (validations.isTitle(employerFormData.name))
			return getValidationResultObject("name", properties.EMPLOYER_NAME_MAX_CHARACTERS_REACHED_INVALID, true);

		if (!validations.isEmpty(employerFormData.description) && validations.isDescription(employerFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_MAX_CHARACTERS_REACHED_INVALID, true);

		if (!validations.isEmpty(employerFormData.link) && validations.isUrl(employerFormData.link))
			return getValidationResultObject("link", properties.EMPLOYER_LINK_INVALID, true);

		if (!validations.isEmpty(employerFormData.welcomeNote) && validations.isDescription(employerFormData.welcomeNote))
			return getValidationResultObject("welcomeNote", properties.EMPLOYER_WELCOME_NOTE_MAX_CHARACTERS_REACHED_INVALID, true);

		if (employerFormData.platform === AON_EMPLOYER_PLATFORM && validations.isEmpty(employerFormAuthOptions))
			return getValidationResultObject("platform", properties.EMPLOYER_AUTH_OPTIONS_REQUIERED, true);

		return { fieldErrorObject, validationsEnabled: false };
	};

	const changeResourceEnabled = () => {
		setEmployerFormResourceEnabled(!employerFormResourceEnabled);
		setEmployerFormData({ ...employerFormData, "resourceEnabled": !employerFormResourceEnabled });
	};

	const onChangeAuthOptions = (event, value) => {
		event.persist();
		setEmployerFormAuthOptions(value);
	};

	//This function calls when user entering data to input fields and store data in the state after validation
	const onChange = (event) => {
		event.persist();
		let name = event.target.name;
		let val = event.target.value;
		setEmployerFormData({ ...employerFormData, [name]: val });
	};

	//This function calls when user submit data
	const onSubmit = (event) => {
		if (event) event.preventDefault();
		if (validations.isEmpty(fieldErrorObject.errorMessage) && Object.keys(employerFormData).length !== 0) {
			employerFormData.authOptions = employerFormAuthOptions;
			updateEmployer(employerFormData);
		} else {
			return;
		}
	};

	//Return object and function
	return { employerFormData, employerFormAuthOptions, employerFormResourceEnabled, validateEmployerFormData, onChangeAuthOptions, changeResourceEnabled, onChange, onSubmit };
};

export default useUpdateEmployerAction;
