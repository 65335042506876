import { ADD_BENEFIT_HOME_URL, GET_BENEFIT_HOME_URL, GET_BENEFIT_HOME_ALL_URL, UPDATE_BENEFIT_HOME_URL } from "./endPoints";
import networkClient from "./networkClient";
import { HTTP_METHOD } from "../../lib/enums/httpData";

export function addBenefitHomeApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.POST,
		url: ADD_BENEFIT_HOME_URL,
		data: payload.data
	});
}

export function getBenefitHomeApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.GET,
		url: GET_BENEFIT_HOME_URL.replace("{benefitHomeId}", payload.benefitHomeId).replace("{employerid}", payload.employerid)
	});
}

export function getBenefitHomeAllApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.GET,
		url: GET_BENEFIT_HOME_ALL_URL.replace("{employerid}", payload.employerId).replace("{pagelimit}", payload.pagelimit).replace("{pagenumber}", payload.pagenumber)
	});
}

export function updateBenefitHomeApi(payload) {
	return networkClient().request({
		method: HTTP_METHOD.PUT,
		url: UPDATE_BENEFIT_HOME_URL.replace("{benefitHomeId}", payload.data.id),
		data: payload.data
	});
}

