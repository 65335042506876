//api
export const SIGN_OUT_URL = process.env.REACT_APP_BASE_URL + "users/signUp";

export const REQUEST_INSTRUCTIONS_URL = process.env.REACT_APP_BASE_URL + "users/request-instruction-api";

//EXPERT VIDEO API URL
export const ADD_EXPERT_VIDEO_URL = "{userid}/admin/resources/expertcontents/videos";
export const GET_EXPERT_VIDEO_URL = "{userid}/admin/resources/expertcontents/videos/{videoId}";
export const GET_EXPERT_VIDEO_ALL_URL = "{userid}/admin/resources/expertcontents/videos?employerid={employerid}&pagenumber={pagenumber}&pagelimit={pagelimit}";
export const UPDATE_EXPERT_VIDEO_URL = "{userid}/admin/resources/expertcontents/videos/{expertVideoId}";

//Expert article api URL
export const ADD_EXPERT_ARTICLE_URL = "{userid}/admin/resources/expertcontents/articles";
export const GET_EXPERT_ARTICLE_URL = "{userid}/admin/resources/expertcontents/articles/{articleId}";
export const GET_EXPERT_ARTICLE_ALL_URL = "{userid}/admin/resources/expertcontents/articles?employerid={employerid}&pagelimit={pagelimit}&pagenumber={pagenumber}";
export const UPDATE_EXPERT_ARTICLE_URL = "{userid}/admin/resources/expertcontents/articles/{expertArticleId}";

//BENEFIT ITEM API URL
export const ADD_BENEFIT_ITEM_URL = "{userid}/admin/resources/employerbenefits/benefititems";
export const GET_BENEFIT_ITEM_URL = "{userid}/admin/resources/employerbenefits/benefititems/{benefitItemId}?employerid={employerid}";
export const GET_BENEFIT_ITEM_ALL_URL = "{userid}/admin/resources/employerbenefits/benefititems?employerid={employerid}&pagelimit={pagelimit}&pagenumber={pagenumber}";
export const UPDATE_BENEFIT_ITEM_URL = "{userid}/admin/resources/employerbenefits/benefititems/{benefitItemId}";

//Benefit Home api URL
export const ADD_BENEFIT_HOME_URL = "{userid}/admin/resources/employerbenefits/benefithomes";
export const GET_BENEFIT_HOME_URL = "{userid}/admin/resources/employerbenefits/benefithomes/{benefitHomeId}?employerId={employerid}";
export const GET_BENEFIT_HOME_ALL_URL = "{userid}/admin/resources/employerbenefits/benefithomes?employerid={employerid}&pagelimit={pagelimit}&pagenumber={pagenumber}";
export const UPDATE_BENEFIT_HOME_URL = "{userid}/admin/resources/employerbenefits/benefithomes/{benefitHomeId}";

//PILLAR API URL
export const ADD_PILLAR_URL = "{userid}/admin/resources/pillarhomes";
export const GET_PILLAR_URL = "{userid}/admin/resources/pillarhomes/{pillarId}";
export const GET_PILLAR_ALL_URL = "{userid}/admin/resources/pillarhomes?employerid={employerid}";
export const UPDATE_PILLAR_URL = "{userid}/admin/resources/pillarhomes/{pillarhomeid}";

//Resource Plans api URL
export const ADD_RESOURCE_PLAN_URL = "{userid}/admin/employers/{employerid}/resourceplans";
export const GET_RESOURCE_PLAN_ALL_URL = "{userid}/employer/{employerid}/resourceplans";
export const GET_RESOURCE_PLAN_URL = "{userid}/admin/employers/{employerid}/resourceplans/{resourcePlanId}";
export const UPDATE_RESOURCE_PLAN_URL = "{userid}/admin/employers/{employerid}/resourceplans/{resourcePlanId}";

//Employers api URL
export const ADD_EMPLOYER_URL = "{userid}/admin/employers";
export const GET_EMPLOYER_URL = "{userid}/admin/employers/{employerid}";
export const GET_EMPLOYER_ALL_URL = "/{userid}/admin/employers?employername={searchedEmployer}&pagelimit={pagelimit}&pagenumber={pagenumber}";
export const UPDATE_EMPLOYER_URL = "{userid}/admin/employers/{employerid}";

export const ADD_CONFIG_DATA_URL = process.env.REACT_APP_BASE_URL + "URL";

export const ADD_NOTIFICATION_URL = process.env.REACT_APP_BASE_URL + "URL";

