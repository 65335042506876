export const isVideoLength = value =>
	!new RegExp(/^(?:(?:)?([0-5]?\d):)?([0-5][0-9])$/).test(value);

//^                   # Start of string
//(?:                 # Try to match...
// (?:                #  Try to match...
//  ([01]?\d|[0-9]+): #   HH:
// )?                 #  (optionally).
// ([0-5]?\d):        #  MM: (required)
//)?                  # (entire group optional, so either HH:MM:, MM: or nothing)
//([0-5]?\d)          # SS (required)
//$                   # End of string

export default isVideoLength;
