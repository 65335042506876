import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";

import "./style.scss";

export default function NonAuthenticatedHeader() {

	const displayDesktop = () => {
		return (
			<Toolbar>
				<Box sx={{ flexGrow: 3, textAlign: "center" }}>
				</Box>
			</Toolbar>
		);
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar
				position="static"
				className="appBar ian-landing"
				style={{ background: "transparent", boxShadow: "none", color: "#ddd" }}
			>
				<Container maxWidth="lg">
					{displayDesktop()}
				</Container>
			</AppBar>
		</Box>
	);
}
