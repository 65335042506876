import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import SelectField from "../../../common/SelectField";
import PrimaryButton from "../../../common/Button/PrimaryButton";
import BackButton from "../../../common/Button/BackButton";
import ErrorPopup from "../../../common/ErrorPopup";
import SuccessPopup from "../../../common/SuccessPopup";
import ToggleSwitch from "../../../common/ToggleSwitch";
import ExpertArticleSection from "../common/expertArticleSection";
import ExpertVideoSection from "../common/expertVideoSection";
import * as properties from "../../../../lib/properties/properties";
import { ResourceTypeOptions } from "../../../../lib/constants/ResourceTypeOptions";
import { getSupportedUrlTooltip } from "../../../../lib/util/commonUtil";

import { cachePillarSearchedData, resetPillarState } from "../../../../state/pillars/pillar/getPillarAll";

import AddPillarAction from "./useAddPillarAction";

const AddPillar = () => {

	const statePillar = useSelector(state => state.pillar);
	const stateResourcePlan = useSelector(state => state.resourcePlan);

	let history = useHistory();
	const dispatch = useDispatch();

	//Customer hook for add pillars
	const { stateEmployers, pillarFormData, pillarFormArticles, pillarFormVideos, pillarFormBenefitsDisabled, onChange, onSubmit, validatePillarFormData, changeBenefitDisabled,
		addExpertVideoFormFields, changeExpertVideoFormFields, removeExpertVideoFormFields, addExpertArticleFormFields, changeExpertArticleFormFields, removeExpertArticleFormFields } = AddPillarAction();

	//This function executes when error popup ok button clicked.
	const onErrorPopupButtonClicked = () => {
		dispatch(resetPillarState());
	};

	//This function executes when go back action gets triggered
	const onClickBackButton = () => {
		history.push("/pillars");
	};

	//This function executes when success popup ok button clicked.
	const onSuccessPopupButtonClicked = () => {
		dispatch(resetPillarState());
		//set the previous Search results
		dispatch(cachePillarSearchedData({ employerId: pillarFormData.employerId }));
		history.push("/pillars");
	};

	return (
		<div>
			<BackButton className="px-5" onClick={onClickBackButton} />
			<div className="tabSectionRight ian-position-relative">
				<div className="ian-centeredSection ian-w-40 ian-position-relative">
					<h5 className="ian-modal-title">
						{properties.PILLAR_CREATE_TITLE}
					</h5>
					<div className="ian-form-container">
						<SelectField
							label={properties.COMMON_EMPLOYER_LABEL}
							name="employerId"
							id="employerId"
							options={stateEmployers}
							selectOption={properties.COMMON_SELECT_OPTION_EMPLOYER_LABEL}
							onChange={onChange}
						/>
						<SelectField
							label={properties.COMMON_EMPLOYER_RESOURCE_PLAN_LABEL}
							name="employerResourcePlanId"
							id="employerResourcePlanId"
							options={pillarFormData?.employerId ? stateResourcePlan?.resourcePlanOptions : []}
							selectOption={properties.COMMON_SELECT_OPTION_RESOURCE_PLAN_LABEL}
							loading={stateResourcePlan?.loading}
							onChange={onChange}
							isDisabled={pillarFormData?.employerId ? false : true}
						/>
						<SelectField
							label={properties.COMMON_RESOURCE_TYPE_LABEL}
							name="resourceType"
							id="resourceType"
							options={ResourceTypeOptions}
							onChange={onChange}
							txtCounterEnabled={false}
							selectOption={properties.COMMON_SELECT_OPTION_RESOURCE_TYPE_LABEL}
						/>
						<TextField
							label={properties.COMMON_TITLE_LABEL}
							placeholder={properties.COMMON_TITLE_PLACEHOLDER}
							name="title"
							id="title"
							type="text"
							onChange={onChange}
						/>
						<TextArea
							label={properties.COMMON_DESCRIPTION_LABEL}
							placeholder={properties.COMMON_DESCRIPTION_PLACEHOLDER}
							name="description"
							id="description"
							type="text"
							onChange={onChange}
						/>
						<TextField
							label={properties.COMMON_EMPLOYER_LOGO_URL_LABEL}
							placeholder={properties.COMMON_EMPLOYER_LOGO_URL_PLACEHOLDER}
							name="employerLogo"
							id="employerLogo"
							type="text"
							onChange={onChange}
							toolTipText={getSupportedUrlTooltip()}
						/>
						<ExpertArticleSection
							expertArticles={pillarFormArticles}
							pillarData={pillarFormData}
							addExpertArticleFormFields={addExpertArticleFormFields}
							removeExpertArticleFormFields={removeExpertArticleFormFields}
							expertArticleFormOnChange={changeExpertArticleFormFields}
						/>
						<br />
						<ExpertVideoSection
							expertVideos={pillarFormVideos}
							pillarData={pillarFormData}
							addExpertVideoFormFields={addExpertVideoFormFields}
							removeExpertVideoFormFields={removeExpertVideoFormFields}
							expertVideoFormOnChange={changeExpertVideoFormFields}
						/>
						<br />
						<ToggleSwitch label={properties.PILLAR_BENEFITS_LABEL} name="benefitsChecked" checked={!pillarFormBenefitsDisabled} onChange={changeBenefitDisabled} />

						{validatePillarFormData().validationsEnabled ? (
							<div className="ian-error-warning show px-5 mt-5" sx={{ display: "none" }}>
								<p>
									{validatePillarFormData().fieldErrorObject.errorMessage}
								</p>
							</div>
						) : (
							<PrimaryButton
								className="px-5"
								label={properties.COMMON_CREATE_LABEL}
								disabled={validatePillarFormData().fieldErrorObject.errorMessage || statePillar.loading}
								showProgress={false}
								onClick={onSubmit}
							/>
						)}
					</div>
					{statePillar.success ? <SuccessPopup
						message={statePillar.pillar ? properties.SUCCESS_POPUP_CREATE_MESSAGE : null} okButtonAction={onSuccessPopupButtonClicked}
					/> : null}
					{statePillar.error ? <ErrorPopup
						error={statePillar.error}
						okButtonAction={onErrorPopupButtonClicked}
					/> : null}
				</div>
			</div>
		</div>
	);
};

export default AddPillar;
