import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@mui/material";

import SelectField from "../../common/SelectField";
import LabelButton from "../../common/Button/LabelButton";
import DataTable from "../../common/DataTable";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_LIMIT_20 } from "../../../lib/constants";
import { RESOURCE_TYPE } from "../../../lib/enums/resourceType";
import * as properties from "../../../lib/properties/properties";
import { isEmpty } from "../../../lib/validator";
import { getBenefitHomeAll, resetBenefitHomeState } from "../../../state/benefits/benefitHome/getBenefitHomeAll";

import useBenefitHomeAction from "./useBenefitHomeAction";

const BenefitHome = () => {
	//Get benefit home data from state
	const stateBenefitHome = useSelector(state => state.benefitHome);

	const dispatch = useDispatch();

	//Custom hook for this form action
	const { stateEmployers, benefitHomeSearchedEmployer, isValidData, onClickAddForm, onClickSearch, onChange } = useBenefitHomeAction();

	const getBenefitHomeData = async (employerId) => {
		const payload = { employerId: employerId, pagelimit: DEFAULT_PAGE_LIMIT_20, pagenumber: DEFAULT_PAGE_NUMBER };
		dispatch(getBenefitHomeAll(payload));
	};

	useEffect(() => {
		dispatch(resetBenefitHomeState());
		!isEmpty(stateBenefitHome?.search?.employerId) && !isEmpty(document.getElementById("employerId")?.value) ?
			getBenefitHomeData(stateBenefitHome?.search.employerId) : null;
	}, []);

	//Benefit home table header items
	const headerTitle = ["Id", "Resource Plan", "Resource Type", "Title", "Action"];

	//Benefit home table body data
	const benefitHomeTableData = stateBenefitHome.benefitHomes && !isEmpty(benefitHomeSearchedEmployer.employerId) && !isEmpty(document.getElementById("employerId")?.value)
		? stateBenefitHome.benefitHomes.map((
			{
				id,
				employer,
				resourcePlan,
				resourceType,
				title
			}) => ({
			value0: id,
			value1: employer,
			value2: resourcePlan?.employerResourcePlanName,
			value3: RESOURCE_TYPE[resourceType],
			value4: title
		})) : [];

	return (
		<div className="tabSectionRight ian-position-relative">
			<div className="ian-centeredSection">
				<div className="ian-form-container ian-position-relative">
					<Grid container spacing={0} px={0} >
						<Grid item md={6} xs={12}>
							<SelectField
								label={properties.COMMON_EMPLOYER_SEARCH_LABEL}
								name="employerId"
								id="employerId"
								options={stateEmployers}
								value={benefitHomeSearchedEmployer.employerId}
								onChange={onChange}
								selectOption={properties.COMMON_SELECT_OPTION_EMPLOYER_LABEL}
							/>
						</Grid>
						<Grid item md={3} xs={12}>
							<div className="searchBtn-wthSelect">
								<LabelButton color="inherit" label="Search" type="submit" disabled={!isValidData() || isEmpty(document.getElementById("employerId")?.value)} showProgress={false} onClick={onClickSearch} />
							</div>
						</Grid>
						<Grid item md={3} xs={12}>
							<div className="add-button">
								<LabelButton color="inherit" label="Add New" type="submit" disabled={stateBenefitHome.loading} showProgress={false} onClick={onClickAddForm} />
							</div>
						</Grid>
					</Grid>
					<DataTable
						Data={benefitHomeTableData}
						headerTitle={headerTitle}
						updateForm={"/benefits?tab=updateBenefitHome"}
						dataLoad={stateBenefitHome.loading}
						noDataLabel={properties.COMMON_NO_DATA_SELECT_AND_SEARCH_LABEL}
					/>
				</div>
			</div>
		</div>
	);
};

export default BenefitHome;
