import { Auth } from "aws-amplify";

/* Function to retrieve the user ID from the current authenticated user */
export const getUserId = async () => {
	const user = await Auth.currentAuthenticatedUser();
	return user.attributes["custom:iana_user_id"];
};

/* Function to retrieve the access token from the current session */
export const getAccessToken = async () => {
	const session = await Auth.currentSession();
	return session.getAccessToken().getJwtToken();
};

/* Function to initiate the sign-in process using AWS Amplify */
export const signIn = async () => {
	try {
		await Auth.federatedSignIn(); /* Initiates federated sign-in */
	} catch (error) {
		/* Error handling can be customized if necessary */
	}
};

/* Function to initiate the sign-out process and redirect to home */
export const logoutWithRedirect = async (history) => {
	await Auth.signOut();
	history.push("/");
};
