import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import { addResourcePlanApi } from "../../../services/api/employerService";
import * as TYPES from "./types";

//Action(s)
export const addResourcePlan = (resourcePlanData) => ({
	type: TYPES.ADD_RESOURCE_PLAN,
	resourcePlanData
});
const addResourcePlanSuccess = (payload) => ({
	type: TYPES.ADD_RESOURCE_PLAN_SUCCESS,
	payload
});
const adddResourcePlanError = (error) => ({
	type: TYPES.ADD_RESOURCE_PLAN_ERROR,
	payload: error
});

//Saga(s)
export function* addResourcePlanSaga({ resourcePlanData }) {
	try {
		const response = yield call(addResourcePlanApi, resourcePlanData);
		if (response.status === HTTP_CODE.OK) {
			yield put(addResourcePlanSuccess(response.data));
		}
	} catch (error) {
		yield put(adddResourcePlanError(error));
	}
}

