import React, { createContext, useState, useEffect } from "react";
import { Auth } from "aws-amplify";

/* Create a context for the authentication state */
const CognitoAuthContext = createContext();

/* Create a provider component */
const CognitoAuthProvider = ({ children }) => {
	const [user, setUser] = useState(null); /* State to store the authenticated user */

	/* Check if the user is already authenticated when the component mounts */
	useEffect(() => {
		checkUser();
	}, []);

	/* Function to check if the user is authenticated */
	const checkUser = async () => {
		try {
			setUser(await Auth.currentAuthenticatedUser());
		} catch (error) {
			setUser(null);
		}
	};

	return (
		<CognitoAuthContext.Provider value={{ user, checkUser }}>
			{children}
		</CognitoAuthContext.Provider>
	);
};

export { CognitoAuthProvider, CognitoAuthContext };
