import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import Divider from "@mui/material/Divider";
import { Box, LinearProgress } from "@mui/material";

import TextField from "../../../common/TextField";
import TextArea from "../../../common/TextArea";
import SelectField from "../../../common/SelectField";
import PrimaryButton from "../../../common/Button/PrimaryButton";
import BackButton from "../../../common/Button/BackButton";
import SuccessPopup from "../../../common/SuccessPopup";
import ErrorPopup from "../../../common/ErrorPopup";
import * as properties from "../../../../lib/properties/properties";
import { isEmpty } from "../../../../lib/validator";
import { getMaxNoOfItemsToShowInBenefitHome, identifyLabel, getRowIdAndEmployerId, getSupportedUrlTooltip } from "../../../../lib/util/commonUtil";
import { ResourceTypeOptions } from "../../../../lib/constants/ResourceTypeOptions";

import { resetBenefitItemState } from "../../../../state/benefits/benefitItem/addBenefitItem";
import { getBenefitItem } from "../../../../state/benefits/benefitItem/getBenefitItem";
import { getResourcePlanAll } from "../../../../state/employers/resourcePlan/getResourcePlanAll";

import useUpdateBenefitItemAction from "./useUpdateBenefitItemAction";

const UpdateBenefitItem = () => {

	const stateResourcePlanOptions = useSelector(state => state.resourcePlan?.resourcePlanOptions);
	const stateBenefitItem = useSelector(state => state.benefitItem);

	const dispatch = useDispatch();
	const history = useHistory();
	const urlBenefitItemEmployerPathVariables = getRowIdAndEmployerId();

	//Get Benefit Item Data
	const getBenefitItemData = async () => {
		const payload = { benefititemid: urlBenefitItemEmployerPathVariables[0], employerid: urlBenefitItemEmployerPathVariables[1] };
		dispatch(getBenefitItem(payload));
	};

	//Get Resource plane
	const getResourcePlaneData = async (value) => {
		const payload = { employerId: value };
		dispatch(getResourcePlanAll(payload));
	};

	//Custom hook for this form action
	const { stateEmployers, benefitItemListFormField, benefitItemCheckListFormField, benefitItems, validateBenefitItemFormData,
		addBodyItemListFields, removeBodyItemListFields, changeBodyItemListFormField, addBodyCheckListFields, removeBodyCheckListFields, changeBodyCheckListFormField, onChange, onSubmit } = useUpdateBenefitItemAction();

	useEffect(() => { getResourcePlaneData(urlBenefitItemEmployerPathVariables[1]); getBenefitItemData(); }, []);

	//This function reset benefit item data in redux state.
	const resetState = () => {
		dispatch(resetBenefitItemState());
	};

	const onClickBackButton = () => {
		history.push("/benefits?tab=benefitItem");
	};

	const onSuccessPopupButtonClicked = () => {
		resetState();
		history.push("/benefits?tab=benefitItem");
	};

	const onErrorPopupButtonClicked = () => {
		resetState();
	};

	return (
		<div>
			<BackButton className="px-5" onClick={onClickBackButton} />
			<div className="tabSectionRight ian-position-relative">
				<div className="ian-centeredSection ian-w-40">
					<div className="ian-form-container">
						<h3 className="ian-modal-title">
							{properties.BENEFIT_ITEM_UPDATE_TITLE}
						</h3>
						{stateBenefitItem.loading && <Box sx={{ width: "100%" }}> <LinearProgress className="ian-linear-progress" /> </Box>}
						<div className="ian-form-container">
							<SelectField
								label={properties.COMMON_EMPLOYER_LABEL}
								name="employerId"
								id="employerId"
								options={stateEmployers}
								value={benefitItems.employerId}
								isDisabled={true}
							/>

							<SelectField
								label={properties.COMMON_EMPLOYER_RESOURCE_PLAN_LABEL}
								name="employerResourcePlanId"
								id="employerResourcePlanId"
								options={benefitItems.employerId ? stateResourcePlanOptions : []}
								value={benefitItems.employerResourcePlanId}
								isDisabled={true}
							/>
							<SelectField
								label={properties.COMMON_RESOURCE_TYPE_LABEL}
								name="resourceType"
								id="resourceType"
								options={ResourceTypeOptions}
								value={benefitItems.resourceType}
								isDisabled={true}
							/>
							<TextField
								label={properties.BENEFIT_SUMMARY_TITLE_LABEL}
								placeholder={properties.BENEFIT_SUMMARY_TITLE_PLACEHOLDER}
								name="summaryTitle"
								id="summaryTitle"
								type="text"
								onChange={onChange}
								value={benefitItems.summaryTitle || ""}
							/>
							<TextArea
								label={properties.BENEFIT_SUMMARY_DESCRIPTION_LABEL}
								placeholder={properties.BENEFIT_SUMMARY_DESCRIPTION_PLACEHOLDER}
								name="summaryDescription"
								id="summaryDescription"
								type="text"
								onChange={onChange}
								value={benefitItems.summaryDescription || ""}
								characterCount={benefitItems.summaryDescription?.length}
							/>
							<TextField
								label={properties.COMMON_EMPLOYER_LOGO_MANDATORY_URL_LABEL}
								placeholder={properties.COMMON_EMPLOYER_LOGO_URL_PLACEHOLDER}
								name="employerLogo"
								id="employerLogo"
								type="text"
								onChange={onChange}
								value={benefitItems.employerLogo || ""}
								toolTipText={getSupportedUrlTooltip()}
							/>
							<Divider orientation="horizontal">Body</Divider>
							<TextField
								label={properties.BENEFIT_BODY_TITLE_LABEL}
								placeholder={properties.BENEFIT_BODY_TITLE_PLACEHOLDER}
								name="bodyTitle"
								id="bodyTitle"
								type="text"
								onChange={onChange}
								value={benefitItems.bodyTitle || ""}
							/>
							{!isEmpty(benefitItemListFormField) ? benefitItemListFormField.map((element, index) => (
								<div key={index} className="textFieldWthRmvBtn">
									{index ? <RemoveCircleOutlineOutlinedIcon className="button-section-remove-form" onClick={() => removeBodyItemListFields(index)} /> : ""}
									<TextField
										label={identifyLabel(properties.BENEFIT_BODY_ITEM_LIST_LABEL, index)}
										placeholder={properties.BENEFIT_BODY_ITEM_LIST_LABEL}
										name="bodyItemListVal"
										id="bodyItemListVal"
										type="text"
										value={element.bodyItemListVal || ""}
										onChange={e => changeBodyItemListFormField(index, e)}
									/>
								</div>)) : ""}
							{!isEmpty(benefitItemListFormField) ? benefitItemListFormField.length < getMaxNoOfItemsToShowInBenefitHome() && <div className="button-section custom-add-btn">
								<AddCircleOutlineOutlinedIcon onClick={() => addBodyItemListFields()} /><br />
							</div> : ""}
							{!isEmpty(benefitItemCheckListFormField) ? benefitItemCheckListFormField?.map((element, index) => (
								<div key={index} className="textFieldWthRmvBtn">
									{index ? <RemoveCircleOutlineOutlinedIcon className="button-section-remove-form" onClick={() => removeBodyCheckListFields(index)} /> : ""}
									<TextField
										label={identifyLabel(properties.BENEFIT_BODY_CHECK_LIST_LABEL, index)}
										placeholder={properties.BENEFIT_BODY_CHECK_LIST_LABEL}
										name="bodyCheckListVal"
										id="bodyCheckListVal"
										type="text"
										value={element.bodyCheckListVal || ""}
										onChange={e => changeBodyCheckListFormField(index, e)}
									/>
								</div>)) : ""}
							{!isEmpty(benefitItemCheckListFormField) ? benefitItemCheckListFormField.length < getMaxNoOfItemsToShowInBenefitHome() && <div className="button-section custom-add-btn">
								<AddCircleOutlineOutlinedIcon onClick={() => addBodyCheckListFields()} /><br />
							</div> : ""}
							<Divider orientation="horizontal">Footer</Divider>
							<TextField
								label={properties.BENEFIT_FOOTER_TITLE_LABEL}
								placeholder={properties.BENEFIT_FOOTER_TITLE_PLACEHOLDER}
								name="footerTitle"
								id="footerTitle"
								type="text"
								onChange={onChange}
								value={benefitItems.footerTitle || ""}
							/>
							<TextArea
								label={properties.BENEFIT_FOOTER_DESCRIPTION_LABEL}
								placeholder={properties.BENEFIT_FOOTER_DESCRIPTION_PLACEHOLDER}
								name="footerDescription"
								id="footerDescription"
								type="text"
								onChange={onChange}
								value={benefitItems.footerDescription || ""}
								characterCount={benefitItems.footerDescription?.length}
							/>
							<TextField
								label={properties.BENEFIT_FOOTER_BUTTON_TEXT_LABEL}
								placeholder={properties.BENEFIT_FOOTER_BUTTON_TEXT_LABEL}
								name="footerButtonText"
								id="footerButtonText"
								type="text"
								onChange={onChange}
								value={benefitItems.footerButtonText || ""}
							/>

							<TextField
								label={properties.BENEFIT_FOOTER_PHONE_LABEL}
								placeholder={properties.BENEFIT_FOOTER_PHONE_PLACEHOLDER}
								name="footerPhone"
								id="footerPhone"
								type="text"
								onChange={onChange}
								value={benefitItems.footerPhone || ""}
							/>

							<TextField
								label={properties.BENEFIT_FOOTER_URL_LABEL}
								placeholder={properties.BENEFIT_FOOTER_URL_PLACEHOLDER}
								value={benefitItems.footerURL || ""}
								name="footerURL"
								id="footerURL"
								type="text"
								onChange={onChange}
							/>

							{validateBenefitItemFormData().validationsEnabled ? (
								<div className="ian-error-warning show" sx={{ display: "none" }}>
									<p>
										{" "}
										{validateBenefitItemFormData().fieldErrorObject.errorMessage}
									</p>
								</div>
							) : (
								<PrimaryButton
									className="px-5"
									label={properties.COMMON_UPDATE_LABEL}
									disabled={validateBenefitItemFormData().fieldErrorObject.errorMessage || stateBenefitItem.loading}
									showProgress={false}
									onClick={onSubmit}
								/>
							)}

							{stateBenefitItem.success ? <SuccessPopup
								message={properties.SUCCESS_POPUP_UPDATE_MESSAGE} okButtonAction={onSuccessPopupButtonClicked}
							/> : null}
							{stateBenefitItem.error ? <ErrorPopup
								error={stateBenefitItem.error}
								okButtonAction={onErrorPopupButtonClicked}
							/> : null}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UpdateBenefitItem;
