import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import * as validations from "../../../lib/validator";
import { cacheEmployerSearchedData, cacheEmployerSearchClicked } from "../../../state/employers/employer/getEmployerAll";
import { getPaginatedEmployers } from "../../../state/employers/employer/getPaginatedEmployers";
import { DEFAULT_PAGE_NUMBER, DEFAULT_PAGE_LIMIT } from "../../../lib/constants";

const useEmployerAction = () => {

	const stateSearchedEmployer = useSelector(state => state.employer?.search);
	const [employerSearchedEmployer, setEmployerSearchedEmployer] = useState({ employer: stateSearchedEmployer?.employer });

	const dispatch = useDispatch();
	const history = useHistory();

	const getEmployerData = async (searchEmployer) => {
		const payload = {
			employer: searchEmployer.trim(),
			pagelimit: DEFAULT_PAGE_LIMIT, pagenumber: DEFAULT_PAGE_NUMBER
		};
		dispatch(getPaginatedEmployers(payload));
		//set the previous Search results
		dispatch(cacheEmployerSearchedData({ employer: searchEmployer, pagenumber: DEFAULT_PAGE_NUMBER }));
	};

	//This function checks required field validations.
	const isValidData = () => {
		return !validations.isEmpty(employerSearchedEmployer.employer);
	};

	//This function call search data
	const onClickSearch = () => {
		getEmployerData(employerSearchedEmployer.employer);
		dispatch(cacheEmployerSearchClicked(true));
	};

	//This function call create new benefit home form
	const onClickAddForm = () => {
		history.push("/employers?tab=addEmployer");
	};

	//This function calls when user entering data to input fields and store data in the state after validation
	const onChange = (event) => {
		event.persist();
		let name = event.target.name;
		let val = event.target.value;
		if (validations.isEmpty(val) && (val.indexOf(" ") < 0)) {
			getEmployerData(val);
		}
		setEmployerSearchedEmployer({ ...employerSearchedEmployer, [name]: val });
		dispatch(cacheEmployerSearchClicked(false));
	};

	//Return object and function
	return { employerSearchedEmployer, isValidData, onClickAddForm, onClickSearch, onChange };
};

export default useEmployerAction;
