import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { addBenefitItemApi } from "../../../services/api/benefitItemService";

//Action(s)
export const addBenefitItem = (benefitItemData) => ({
	type: TYPES.ADD_BENEFIT_ITEM,
	benefitItemData
});
export const resetBenefitItemState = () => ({
	type: TYPES.CLEAR_BENEFIT_ITEM
});
const addBenefitItemSuccess = (payload) => ({
	type: TYPES.ADD_BENEFIT_ITEM_SUCCESS,
	payload
});
const addBenefitItemError = (error) => ({
	type: TYPES.ADD_BENEFIT_ITEM_ERROR,
	payload: error
});

//Saga(s)
export function* addBenefitItemSaga({ benefitItemData }) {
	try {
		const response = yield call(addBenefitItemApi, benefitItemData);
		if (response.status === HTTP_CODE.OK) {
			yield put(addBenefitItemSuccess(response.data));
		}
	} catch (error) {
		yield put(addBenefitItemError(error.errorMessage));
	}
}
