import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { AppBar, Box, Toolbar, Link, Container } from "@mui/material";

import Logo from "../../../assets/images/ianacare/logo.svg";
import LogoutConfirmationPopup from "./logoutConfirmationPopup";
import LabelButton from "../Button/LabelButton";
import { PAGE } from "../../../lib/enums/enum";

import "./style.scss";

export default function AuthenticatedHeader({ selectedPage }) {
	const [openPopup, setOpenPopup] = useState(false);

	/**
   * This is the callback function which executes when user click on logout button.
   */
	const onLogOutClicked = () => {
		setOpenPopup(true);
	};

	const linkProps = {
		component: RouterLink,
		color: "inherit",
		underline: "none"
	};

	const authLinks = (
		<Box sx={{ display: { xs: "none", md: "flex" } }} className="navBarItems">
			<Link className="navbar-tab" sx={{ borderBottom: selectedPage === PAGE.GENERAL ? "3px solid #156873" : "none" }}
				{...{
					...linkProps,
					to: "general"
				}}>
				General
			</Link>
			<Link className="navbar-tab" sx={{ borderBottom: selectedPage === PAGE.EMPLOYERS ? "3px solid #156873" : "none" }}
				{...{
					...linkProps,
					to: PAGE.EMPLOYER
				}}>
				Employers
			</Link>
			<Link className="navbar-tab" sx={{ borderBottom: selectedPage === PAGE.PILLARS ? "3px solid #156873" : "none" }}
				{...{
					...linkProps,
					to: "pillars"
				}}>
				Pillars
			</Link>
			<Link className="navbar-tab" sx={{ borderBottom: selectedPage === PAGE.CONTENTS ? "3px solid #156873" : "none" }}
				{...{
					...linkProps,
					to: "contents"
				}}>
				Contents
			</Link>
			<Link className="navbar-tab" sx={{ borderBottom: selectedPage === PAGE.BENEFITS ? "3px solid #156873" : "none" }}
				{...{
					...linkProps,
					to: "benefits"
				}}>
				Benefits
			</Link>
			<div className="logoutbtnHeight">
				<LabelButton className="navbar-tab"
					label="logout"
					type="submit"
					showProgress={false}
					onClick={onLogOutClicked}
				/>
			</div>

		</Box>
	);

	const displayDesktop = () => {
		return (
			<Toolbar>
				<Box sx={{ flexGrow: 3, textAlign: "left" }}>
					<img src={Logo} className="navBarLogo" alt="ianacare logo" />
				</Box>
				{authLinks}
			</Toolbar>
		);
	};

	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar
				position="static"
				style={{ background: "transparent", boxShadow: "none", color: "#ddd" }}
			>
				<Container maxWidth="lg">
					{displayDesktop()}
				</Container>
			</AppBar>

			<LogoutConfirmationPopup openPopup={openPopup} setOpenPopup={setOpenPopup} showCancelButton={true} />
		</Box>
	);
}
