import { call, put } from "redux-saga/effects";

import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";
import { getBenefitHomeAllApi } from "../../../services/api/benefitHomeService";

//Action(s)
export const getBenefitHomeAll = (payload) => ({
	type: TYPES.GET_BENEFIT_HOME_ALL,
	payload: payload
});
const getBenefitHomeAllSuccess = (payload) => ({
	type: TYPES.GET_BENEFIT_HOME_ALL_SUCCESS,
	payload: payload
});
const getBenefitHomeAllError = (error) => ({
	type: TYPES.GET_BENEFIT_HOME_ALL_ERROR,
	payload: { error }
});
export const cacheBenefitHomeSearchedData = (payload) => ({
	type: TYPES.CACHE_BENEFIT_HOME_SEARCHED_DATA,
	payload: payload
});
export const resetBenefitHomeState = () => ({
	payload: null,
	type: TYPES.CLEAR_BENEFIT_HOME
});

//Saga(s)
export function* getBenefitHomeAllSaga({ payload }) {
	try {
		const response = yield call(getBenefitHomeAllApi, payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(getBenefitHomeAllSuccess(response.data));
		}
	} catch (error) {
		yield put(getBenefitHomeAllError(error));
	}
}
