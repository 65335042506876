export const ADD_EMPLOYER = "ADD_EMPLOYER";
export const ADD_EMPLOYER_SUCCESS = "ADD_EMPLOYER_SUCCESS";
export const ADD_EMPLOYER_ERROR = "ADD_EMPLOYER_ERROR";

export const GET_EMPLOYER = "GET_EMPLOYER";
export const GET_EMPLOYER_SUCCESS = "GET_EMPLOYER_SUCCESS";
export const GET_EMPLOYER_ERROR = "GET_EMPLOYER_ERROR";

export const GET_EMPLOYER_ALL = "GET_EMPLOYER_ALL";
export const GET_EMPLOYER_ALL_SUCCESS = "GET_EMPLOYER_ALL_SUCCESS";
export const GET_EMPLOYER_ALL_ERROR = "GET_EMPLOYER_ALL_ERROR";

export const GET_PAGINATED_EMPLOYERS = "GET_PAGINATED_EMPLOYERS";
export const GET_PAGINATED_EMPLOYERS_SUCCESS = "GET_PAGINATED_EMPLOYERS_SUCCESS";
export const GET_PAGINATED_EMPLOYERS_ERROR = "GET_PAGINATED_EMPLOYERS_ERROR";

export const UPDATE_EMPLOYER = "UPDATE_EMPLOYER";
export const UPDATE_EMPLOYER_SUCCESS = "UPDATE_EMPLOYER_SUCCESS";
export const UPDATE_EMPLOYER_ERROR = "UPDATE_EMPLOYER_ERROR";

export const CACHE_EMPLOYER_SEARCHED_DATA = "CACHE_EMPLOYER_SEARCHED_DATA";
export const CACHE_EMPLOYER_SEARCH_CLICKED = "CACHE_EMPLOYER_SEARCH_CLICKED";

export const SET_IS_EMPLOYER_CHANGED = "SET_IS_EMPLOYER_CHANGED";

export const CLEAR_EMPLOYER = "CLEAR_EMPLOYER";
