import { call, put } from "redux-saga/effects";

import { getResourcePlanAllApi } from "../../../services/api/employerService";
import { HTTP_CODE } from "../../../lib/enums/httpData";
import * as TYPES from "./types";

//Action(s)
export const getResourcePlanAll = (resourcePlan) => ({
	type: TYPES.GET_RESOURCE_PLAN_ALL,
	payload: resourcePlan
});
const getResourcePlansAllSuccess = (data) => ({
	type: TYPES.GET_RESOURCE_PLAN_ALL_SUCCESS,
	payload: data
});
const getResourcePlansAllError = (error) => ({
	type: TYPES.GET_RESOURCE_PLAN_ALL_ERROR,
	payload: error
});
export const cacheResourcePlanSearchedData = (payload) => ({
	type: TYPES.CACHE_RESOURCE_PLAN_SEARCHED_DATA,
	payload: payload
});
export const resetResourcePlanState = () => ({
	type: TYPES.CLEAR_RESOURCE_PLANS
});

//Saga(s)
export function* getResourcePlanAllSaga(resourcePlan) {
	try {
		const response = yield call(getResourcePlanAllApi, resourcePlan.payload);
		if (response.status === HTTP_CODE.OK) {
			yield put(getResourcePlansAllSuccess(response.data));
		}
	} catch (error) {
		yield put(getResourcePlansAllError(error.errorMessage));
	}
}

