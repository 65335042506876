import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@mui/material";

import SelectField from "../../common/SelectField";
import LabelButton from "../../common/Button/LabelButton";
import DataTable from "../../common/DataTable";
import * as properties from "../../../lib/properties/properties";
import { isEmpty } from "../../../lib/validator";
import { getBenefitItemAll, cacheBenefitItemSearchedData, resetBenefitItemState } from "../../../state/benefits/benefitItem/getBenefitItemAll";
import { getEmployerAll } from "../../../state/employers/employer/getEmployerAll";
import { NO_PAGE_LIMIT, NO_PAGE_COUNT, DEFAULT_PAGE_LIMIT } from "../../../lib/constants";
import { RESOURCE_TYPE } from "../../../lib/enums/resourceType";

import useBenefitItemAction from "./useBenefitItemAction";

const BenefitItem = () => {

	const stateBenefitItem = useSelector(state => state.benefitItem);

	const dispatch = useDispatch();

	//Custom hook for this form action
	const { stateEmployer, benefitItemSearchedEmployer, isValidData, onClickAddForm, onClickSearch, onChange } = useBenefitItemAction();

	const callback = useCallback((page) => {
		if (!isEmpty(page)) {
			getBenefitItemData(page, benefitItemSearchedEmployer.employerId);
		}
	}, [benefitItemSearchedEmployer]);

	const getEmployerData = async () => {
		const payload = { pagelimit: NO_PAGE_LIMIT, pagenumber: NO_PAGE_COUNT };
		dispatch(getEmployerAll(payload));
	};

	const getBenefitItemData = async (page, employerId) => {
		const payload = { employerId: employerId, pagelimit: DEFAULT_PAGE_LIMIT, pagenumber: page };
		dispatch(getBenefitItemAll(payload));
		//set the previous Search results
		dispatch(cacheBenefitItemSearchedData({ employerId: employerId, pagenumber: page }));
	};

	useEffect(() => {
		dispatch(resetBenefitItemState());
		stateEmployer.isEmployerChanged ? getEmployerData() : "";
		!isEmpty(stateBenefitItem?.search?.employerId) && !isEmpty(document.getElementById("employerId")?.value) ?
			getBenefitItemData(stateBenefitItem.search.pagenumber, stateBenefitItem.search.employerId) : null;
	}, [stateEmployer.eligibleEmployers]);

	//Benefit item table header items
	const headerTitle = ["Id", "Resource Plan", "Resource Type", "Title", "Action"];
	//Benefit item table body data
	const benfitItemTableData = stateBenefitItem.benefitItems && !isEmpty(benefitItemSearchedEmployer.employerId)
		&& !isEmpty(stateEmployer.eligibleEmployers) && !stateEmployer.loading && !isEmpty(document.getElementById("employerId")?.value)
		? stateBenefitItem.benefitItems.map((
			{
				id,
				employer,
				resourcePlan,
				resourceType,
				summaryTitle
			}) => ({
			value0: id,
			value1: employer,
			value2: resourcePlan?.employerResourcePlanName,
			value3: RESOURCE_TYPE[resourceType],
			value4: summaryTitle
		})) : [];

	return (
		<div className="tabSectionRight ian-position-relative">
			<div className="ian-centeredSection">
				<div className="ian-form-container ian-position-relative">
					<Grid container spacing={0} px={0} >
						<Grid item md={6} xs={12}>
							<SelectField
								label={properties.COMMON_EMPLOYER_SEARCH_LABEL}
								name="employerId"
								id="employerId"
								options={stateEmployer.eligibleEmployers}
								value={benefitItemSearchedEmployer.employerId}
								onChange={onChange}
								selectOption={properties.COMMON_SELECT_OPTION_EMPLOYER_LABEL}
							/>
						</Grid>
						<Grid item md={3} xs={12}>
							<div className="searchBtn-wthSelect">
								<LabelButton color="inherit" label="Search" type="submit" disabled={!isValidData() || isEmpty(document.getElementById("employerId")?.value)} showProgress={false} onClick={onClickSearch} />
							</div>
						</Grid>
						<Grid item md={3} xs={12}>
							<div className="add-button">
								<LabelButton color="inherit" label="Add New" type="submit" disabled={stateBenefitItem.loading} showProgress={false} onClick={onClickAddForm} />
							</div>
						</Grid>
					</Grid>

					<DataTable
						Data={benfitItemTableData}
						headerTitle={headerTitle}
						updateForm={"/benefits?tab=updateBenefitItem"}
						dataLoad={stateBenefitItem.loading}
						parentCallback={callback}
						pageData={stateBenefitItem.pageData}
						paginationActive={true}
						noDataLabel={properties.COMMON_NO_DATA_SELECT_AND_SEARCH_LABEL}
					/>
				</div>
			</div>
		</div>
	);
};

export default BenefitItem;
