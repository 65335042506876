import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as properties from "../../../../lib/properties/properties";
import * as validations from "../../../../lib/validator";
import { getValidationResultObject } from "../../../../lib/validator/util";
import { updateExpertVideo } from "../../../../state/expertContents/expertVideo/updateExpertVideo";

const useUpdateExpertVideoAction = () => {

	const stateEmployers = useSelector(state => state.employer.eligibleEmployers);
	const stateUpdateExpertVideoItem = useSelector(state => state.expertVideo.expertVideo);

	const [expertVideoFormData, setExpertVideoFormData] = useState(!validations.isEmpty(stateUpdateExpertVideoItem) ? stateUpdateExpertVideoItem : []);

	const dispatch = useDispatch();
	const fieldErrorObject = { fieldName: "", errorMessage: "" };

	useEffect(() => { setExpertVideoFormData(!validations.isEmpty(stateUpdateExpertVideoItem) ? stateUpdateExpertVideoItem : []); }, [stateUpdateExpertVideoItem]);

	//Update video
	const updateExpertVideoData = async (expertVideoAPIRequest) => {
		const payload = { data: expertVideoAPIRequest, videoId: stateUpdateExpertVideoItem.id };
		dispatch(updateExpertVideo(payload));
	};

	//This function checks required field validations.
	const validateExpertVideoFormData = () => {

		if (validations.isEmpty(expertVideoFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_INVALID, true);

		if (validations.isTitle(expertVideoFormData.title))
			return getValidationResultObject("title", properties.COMMON_TITLE_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(expertVideoFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_INVALID, true);

		if (validations.isDescription(expertVideoFormData.description))
			return getValidationResultObject("description", properties.COMMON_DESCRIPTION_MAX_CHARACTERS_REACHED_INVALID, true);

		if (validations.isEmpty(expertVideoFormData.url))
			return getValidationResultObject("url", properties.CONTENT_EXPE_VIDEO_URL_INVALID, true);

		if (validations.isIanacareUrl(expertVideoFormData.url))
			return getValidationResultObject("url", properties.CONTENT_EXPE_VIDEO_URL_INVALID, true);

		if (validations.isEmpty(expertVideoFormData.videoLength))
			return getValidationResultObject("videoLength", properties.CONTENT_EXPE_VIDEO_LENGTH_INVALID, true);

		if (validations.isVideoLength(expertVideoFormData.videoLength))
			return getValidationResultObject("videoLength", properties.CONTENT_EXPE_VIDEO_LENGTH_INVALID, true);

		if (validations.isEmpty(expertVideoFormData.previewImage))
			return getValidationResultObject("previewImage", properties.CONTENT_EXPE_PREVIEW_IMAGE_URL_INVALID, true);

		if (validations.isIanacareUrl(expertVideoFormData.previewImage))
			return getValidationResultObject("previewImage", properties.CONTENT_EXPE_PREVIEW_IMAGE_URL_INVALID, true);

		return { fieldErrorObject, validationsEnabled: false };
	};

	//This function calls when user entering data to input fields and store data in the state after validation.
	const onChange = (event) => {
		event.persist();
		const name = event.target.name;
		const val = event.target.value;
		setExpertVideoFormData({ ...expertVideoFormData, [name]: val });
	};

	//This function calls when user submit data.
	const onSubmit = async (event) => {
		if (event) event.preventDefault();
		if (validations.isEmpty(fieldErrorObject.errorMessage) && Object.keys(expertVideoFormData).length !== 0) {
			updateExpertVideoData(expertVideoFormData);
		} else {
			return;
		}
	};

	return { stateEmployers, expertVideoFormData, validateExpertVideoFormData, onChange, onSubmit };
};

export default useUpdateExpertVideoAction;
