import React from "react";
import { useSelector } from "react-redux";
import { Divider, Typography } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";

import TextField from "../../../common/TextField";
import AutoComplete from "../../../common/AutoCompleteField";
import * as properties from "../../../../lib/properties/properties";
import { getMaxNoOfItemsToShow, getSupportedUrlTooltip } from "../../../../lib/util/commonUtil";

const ExpertArticleSection = ({
	expertArticles,
	pillarData,
	addExpertArticleFormFields,
	removeExpertArticleFormFields,
	expertArticleFormOnChange
}) => {

	//Get expert article state
	const stateExpertArticles = useSelector(state => state.expertArticle.expertArticles);
	//set article data to Select components format
	const formExpertArticles = stateExpertArticles[0] ? stateExpertArticles.map((expertArticle) =>
		({
			label: expertArticle.id + " - " + expertArticle.title,
			value: expertArticle.id
		})
	) : [];

	return (
		<>
			<Divider orientation="horizontal">{properties.CONTENT_EXPE_ARTICLE_LABEL}</Divider>
			{expertArticles.map((element, index) => (
				<div key={index}>
					<Typography variant="h6" gutterBottom>
						{properties.CONTENT_EXPE_ARTICLE_SUB_TITLE} {index ? index + 1 : 1}
					</Typography>
					{index ? <RemoveCircleOutlineOutlinedIcon className="button-section-remove-form"
						onClick={() => removeExpertArticleFormFields(index)} /> : ""}
					<AutoComplete
						label={properties.CONTENT_EXPE_ARTICLE_ID_LABEL}
						placeholder={properties.PILLAR_SELECT_OPTION_EXPERT_ARTICLE_ID_LABEL}
						name="expertArticleId"
						id="expertArticleId"
						value={element.expertArticleId || null}
						options={pillarData?.employerId ? formExpertArticles : []}
						onChange={e => expertArticleFormOnChange(index, e)}
						isDisabled={pillarData?.employerId ? false : true}
					/>
					<TextField
						label={properties.COMMON_PREVIEW_IMAGE_LABEL}
						placeholder={properties.COMMON_PREVIEW_IMAGE_PLACEHOLDER}
						name="articlePreviewImage"
						id="articlePreviewImage"
						type="text"
						value={element.articlePreviewImage || ""}
						onChange={e => expertArticleFormOnChange(index, e)}
						toolTipText={getSupportedUrlTooltip()}
					/>
					<TextField
						label={properties.COMMON_PREVIEW_TITLE_LABEL}
						placeholder={properties.COMMON_PREVIEW_TITLE_PLACEHOLDER}
						name="previewTitle"
						id="previewTitle"
						type="text"
						value={element.previewTitle || ""}
						onChange={e => expertArticleFormOnChange(index, e)}
					/>
				</div>
			))}
			{expertArticles.length < getMaxNoOfItemsToShow() ?
				<div className="button-section">
					<AddCircleOutlineOutlinedIcon onClick={() => addExpertArticleFormFields()} />
				</div> : ""}
		</>
	);

};

export default ExpertArticleSection;
