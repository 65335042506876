import { ADD_NOTIFICATION_URL } from "./endPoints";
import networkClient from "./networkClient";
import { HTTP_METHOD } from "../../lib/enums/httpData";

export function addNotificationApi(data) {
	return networkClient().request({
		method: HTTP_METHOD.POST,
		url: ADD_NOTIFICATION_URL,
		data: data
	});
}

export function updateNotificationApi(data) {
	return networkClient().request({
		method: HTTP_METHOD.GET,
		url: ADD_NOTIFICATION_URL,
		data: data
	});
}



